
import { ETreasureType } from "../def/items/treasures";
import { IGameConfig, IGameConfigUser } from "./config";
import { EAdService } from '../def/app/ads';
import { ECodePushSource } from "../def/app/app";

export class AppConstants {

    public static appId: number = 3; // leplace world

    public static animationTime: number = 500;
    public static animationMode: string = "0.5s ease-in-out";
    // public static animationMode: string = "0.5s cubic-bezier(.1, .7, .1, 1)";
    public static animationTime2S: number = 250;
    public static animationMode2S: string = "0.25s ease-in-out";

    public static appStorageFolder: string = "leplace";

    // these items trigger register treasure
    public static collectibleItemsClient: number[] = [ETreasureType.treasure, ETreasureType.challenge,
    ETreasureType.storyLocation, ETreasureType.exploreObject, ETreasureType.findTarget];
    public static collectibleEventItemsClient: number[] = [ETreasureType.treasure, ETreasureType.exploreObject, ETreasureType.findTarget];
    public static collectibleItemsServer: number[] = [ETreasureType.treasure];
    public static collectibleItemsOnTap: number[] = [ETreasureType.treasure, ETreasureType.exploreObject];

    public static transparentMenus: boolean = false;

    /** disabled at the moment because of bugs related to bg mode and speechSynthesis API */
    public static enableSoundApi: boolean = false;

    public static enableSocialSharing: boolean = false;

    public static viewTitle = {
        signupTerms: "Terms and Conditions",
        signupTutorial: "Welcome"
    };

    public static gameConfigUser: IGameConfigUser = {
        useCustomEventLogger: 0,
        useRequestLogger: 0,
        useRemoteConsoleLogger: 0
    };

    public static gameConfig: IGameConfig = {
        coinValue: 10,
        defaultScanEnergy: 1,
        droneScanEnergy: 25,
        maxSpeedGPS: 30,
        placeContentScanEnergy: 10,
        placeScanRadius: 1000,
        placeScanRadiusCustomMap: 500,
        smartZoomOutRange: 1000,
        smartZoomInRange: 200,
        smartZoomOutDensity: 100,
        smartZoomInDensity: 0,
        mapZoomInLevel: 17,
        mapZoomOutLevel: 15,
        recordSamplingTime: 5000,

        maxPlaceMarkers: 10,
        locationAccuracyThreshold: 200,
        maxDistanceLocationMeters: 20000,
        collectDistance: 25,
        itemNotifyDistance: 50,
        itemEnableDistance: 200,
        ARCollectDistance: 15,
        reachedDistance: 50,
        revealDistance: 80,
        maxDistanceFromPath: null,
        maxDistanceFromPathNotify: 200,
        unlockIAPAlways: 0,
        scanPlacesMinDist: 250,
        filteringRequiredMinDist: 100,
        // km/h
        scanPlacesMaxSpeed: 30,
        scanPlacesMinTimeDelta: 60 * 1000,
        refreshARMinTimeDelta: 5 * 1000,
        scanPlacesMinTimeDeltaInit: 5 * 1000,
        scanPlacesRetryTimeDelta: 10 * 1000,
        refreshARMinDist: 10,
        defaultObjectAltitude: -200,
        openCounterUntilRate: 5,
        autoAds: 0,
        autoAdsIOS: 0,
        dumpDistanceTimeout: 600 * 1000,
        maxTreasurePlaces: 10,
        placePhotoCacheSize: 5,
        placePhotoCacheTime: 300,
        droneBatteryDurationNominal: 180,
        maxReviewAdCounter: 5,
        adServiceSelector: EAdService.adMobFree,
        useNpaAlways: 1,
        showConsentAlways: 1,
        customEventTracking: 0,
        customEventLogger: 0,
        sendConsoleLogsAlways: 0,
        debugIapEvents: 0,
        resumeAfterDays: 3,
        showPlaceAds: 1,
        photoTargetWidth: 1200,
        photoTargetQuality: 60,
        challengeCooldown: 600,
        directionsMaxDistance: 100000,
        codePushSource: ECodePushSource.default,
        maxPhotoDataSize: 1024,
        unlockCodeLength: 6,
        enableCoinsForRunActivity: 1,
        externalPaymentDeduction: 0.85
    };
}

export enum EAppConstants {
    exploreCircleRadiusDefault = 40,
    liveChatGroupId = 1,
    escapeMinTimeLimit = 20
}

export enum EDefaultDistanceSpecs {
    pickupObjectRelativeDistance = 25,
    
    placeMarkerRadius = 120,
    placeLockedMarkerRadius = 60,
    auxPlaceMarkerRadius = 60,
    plainItemRadius = 40,
    canvasItemRadius = 60,
    hiddenMarkerRadius = 40
}