import { ITreasureSpec } from "../places/leplace";
import { IGameItem } from "../items/game-item";

export interface ICustomParamCategory {
    id: number;
    code: number;
    name: string;
}

/**
 * custom params that are associated to a parent activity
 */
export interface ICustomParamForActivity {
    /**
     * the code may not be required if the API uses the tag instead
     */
    code: number;
    /**
     * the param category is related to the API that is required to validate the param
     */
    customParamCategoryCode: number;
    /**
     * the container category
     */
    customParamCategory: ICustomParamCategory;
    /**
     * category name added from the custom param category table join
     */
    categoryName: string;
    /**
     * associated map marker e.g. coin replacement
     */
    treasureSpec: ITreasureSpec;
    /**
     * associated items (unlockables)
     */
    items: IGameItem[];
    /**
     * the object tag e.g. dog, bicycle
     */
    tag: string;
    /**
     * a human friendly name
     */
    name: string;

    title?: string;
    description: string;
    /**
     * photo url to be shown in the app
     */
    photoUrl: string;
    /**
     * external link
     */
    wikiUrl: string;
    /**
     * aux, for view only
     */
    photoUrlMarker?: string;
    /**
     * can represent the timeout associated to the custom param/challenge
     */
    value: number;
    /**
     * can be used for showing the number of items collected
     */
    amount?: number;
    /**
     * check if the scope is story or challenge
     */
    scope?: number;

    mode?: number;
    /**
     * used for quest activities
     */
    dataString?: string

    hide?: boolean;
}

export enum ECustomParamMode {
    hidden = 0,
    disp = 1
}

export enum ECustomParamScope {
    story = 1,
    challenge = 2
}

export enum ECustomParamScope2 {
    treasures = 1,
    items = 2
}

export enum ECustomParam {
    noTimeLimit = 301,
    clearCollectedCoins = 302,
    photoFinish = 303
}

export enum ECustomParamCategoryCode {
    photoChallenge = 1,
    audioChallenge = 2,
    basicChallenge = 3,
    arPhotoChallenge = 4,
    quest = 23,
    videoGuide = 101
}

export interface IFixedCoin {
    locationIndex: number;
    locationId: number;
    customParamCode: number;
    draftId: number;
    lat: number;
    lng: number;
    tempUid?: string;
    index?: number;
    category?: number;
    tag?: string;
    customParamIndex?: number;
    /** plot fragment */
    description?: string;
    /** plot fragment title */
    title?: string
    /** plot fragment highlight */
    high?: boolean;
    /** special behavior (static, moving, etc.) */
    dynamics?: number;
    /** dynamics copy as defined in the db */
    dynamicsOrig?: number;
}

