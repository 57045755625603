import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import { Platform, MenuController, IonRouterOutlet } from '@ionic/angular';
import { Router, Route, ActivationStart, NavigationEnd, NavigationStart, RoutesRecognized, Params } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ELocalAppDataKeys, IAppFlagsElement } from './classes/def/app/storage-flags';
import { AuthRequestService } from './services/general/auth-request/auth-request';
import { IPlatformFlags, EPlatformDef } from './classes/def/app/platform';
import { SettingsManagerService } from './services/general/settings-manager';
import { BackgroundModeService } from './services/general/apis/background-mode';
import { UiExtensionService } from './services/general/ui/ui-extension';
import { AnalyticsService } from './services/general/apis/analytics';
import { ScriptLoaderService } from './services/general/script-loader';
import { LocationManagerService } from './services/map/location-manager';
import { BackButtonService } from './services/general/ui/back-button';
import { UserDataService } from './services/data/user';
import { KeyboardProvider } from './services/apis/keyboard';
import { LocalNotificationsService } from './services/general/apis/local-notifications';
import { NotificationsService } from './services/general/apis/notifications';
import { PopupFeaturesService } from './services/app/modules/minor/popup-features';
import { ResourceHandlerDataService } from './services/data/resource-handler';
import { SplitPaneService } from './services/general/ui/split-pane';
import { StorageOpsService } from './services/general/data/storage-ops';
import { SocialAuthWrapperService } from './services/general/auth-request/social-auth';
import { GeneralCache } from './classes/app/general-cache';
import { EOS } from './classes/def/app/app';
import { IGenericResponse, ICheckLoginResponse } from './classes/def/requests/general';
import { Messages } from './classes/def/app/messages';
import { ErrorMessage } from './classes/general/error-message';
import { ResourceManager } from './classes/general/resource-manager';
import { routes } from './app-routing.module';
import { ShadowDomService } from './services/general/ui/shadow-dom.service';
import { IMenuInterface, IPageInterface, EMenuCommand, ERouteDef } from './app-utils';
import { IDrawerOptions } from './components/generic/components/content-drawer/content-drawer.component';
import { EAppIcons, EAppIconsStandard } from './classes/def/app/icons';
import { NavParamsService } from './services/app/nav-params';
import { NavUtilsService } from './services/general/ui/nav-utils';
import { ResourcesCoreDataService } from './services/data/resources-core';
import { AppVersionService } from './services/general/app-version';
import { SoundEffectsService } from './services/general/apis/sound-effects';
import { IPopoverActions } from './classes/def/app/modal-interaction';
import { AccountManagerService } from './services/app/modules/account-manager';
import { BackgroundModeWatchService } from './services/general/apis/background-mode-watch';
import { BackgroundModeWatchControllerService } from './services/general/apis/background-mode-watch-controller';
import { ETrackedEvents } from './classes/app/analytics';
import { ApiDef } from './classes/app/api';
import { SleepUtils } from './services/utils/sleep-utils';
import { MarkerUtils } from './services/map/marker-utils';
import { WebviewUtilsService } from './services/app/utils/webview-utils';
import { MediaUtilsService } from './services/media/media-utils';
import { KeyHandlerService } from './services/general/ui/key-handler';
import { PromiseUtils } from './services/utils/promise-utils';
import { TestingManagerService } from './services/general/testing-manager';
import { MQTTService } from './services/telemetry/mqtt.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppDiagnosticService } from './services/general/app-diagnostic.service';
import { EAlertButtonCodes } from './classes/def/app/ui';
import { SwUpdate } from '@angular/service-worker';
import { WaitUtils } from './services/utils/wait-utils';
import { BrowserUtils } from './classes/utils/browser-utils';
import { OtherUtils } from './services/utils/other-utils';
import { CrashlyticsService } from './services/general/apis/crashlytics';
import { ImageLoaderTSService } from './services/media/image-loader-ts';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { StoryDataService } from './services/data/story';

declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('menuContainer', { read: ElementRef, static: false }) menuContainer: ElementRef;
  @ViewChild('menuContent', { read: ElementRef, static: false }) menuContent: ElementRef;
  @ViewChild(IonRouterOutlet, { read: IonRouterOutlet, static: false }) outlet: IonRouterOutlet;

  platformFlags: IPlatformFlags = {} as IPlatformFlags;

  // rootPage: any = LandingPage;

  platformType: number;
  authenticated: boolean = false;
  menuOpen: boolean = false;
  menuOpenState: number = 0;
  menuOpenDelay: boolean = false;
  timeoutOpen: any;
  focusObservableSub: Subscription;
  enableBlur: boolean = false;

  appInitComplete: boolean = false;

  /**
   * prevent calling init sequence before it's finished
   */
  appInitInProgress: boolean = false;

  drawerOptions: IDrawerOptions = {
    handleHeight: 50,
    thresholdFromBottom: 200,
    thresholdFromTop: 200,
    bounceBack: true
  };


  transparentBg: boolean = false;
  isWeb: boolean = false;
  webFrameEnabled: boolean = true;

  global = GeneralCache;

  appMenu: IMenuInterface[] = [
    {
      name: "",
      pages: [
        {
          title: 'Home',
          name: "Home",
          url: ERouteDef.home,
          icon: 'home',
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'Storyline',
          name: "Categories",
          url: ERouteDef.storyList,
          icon: 'bookmarks',
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'World Map',
          name: "Gmap",
          url: ERouteDef.gmap,
          icon: 'map',
          loginRequired: true,
          enableDrawer: false,
          transparent: true,
          fullView: true
        },
        {
          title: 'My Account',
          name: "Account",
          url: ERouteDef.account,
          loginRequired: true,
          icon: 'person',
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'My Inventory',
          name: "Inventory",
          url: ERouteDef.inventory,
          loginRequired: true,
          icon: EAppIcons.backpack,
          enableDrawer: true,
          customIcon: true,
          fullView: false
        },
        {
          title: 'Leaderboard',
          name: "Leaderboard",
          url: ERouteDef.leaderboard,
          loginRequired: true,
          icon: EAppIconsStandard.leaderboard,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'Newsfeed',
          name: "Newsfeed",
          url: ERouteDef.newsfeed,
          icon: 'mail',
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'Support',
          name: "Support",
          url: ERouteDef.supportHome,
          icon: 'chatbubbles',
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'Settings',
          name: "Settings",
          url: ERouteDef.settings,
          icon: 'settings',
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },
        // {
        //   title: 'Events',
        //   name: "Events",
        //   url: ERouteDef.eventsList,
        //   icon: 'calendar',
        //   loginRequired: true,
        //   enableDrawer: true
        // },
        // {
        //   title: 'Extras',
        //   name: "Extras",
        //   url: ERouteDef.extras,
        //   icon: 'link',
        //   loginRequired: true,
        //   enableDrawer: true
        // },
        {
          title: 'About',
          name: "About",
          url: ERouteDef.about,
          icon: 'information-circle',
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'Login',
          name: "Login",
          url: ERouteDef.login,
          icon: 'log-in',
          loginRequired: false,
          enableDrawer: true,
          fullView: false
        },
        {
          title: 'Signup',
          name: "Signup",
          url: ERouteDef.signup,
          icon: 'person-add',
          loginRequired: false,
          enableDrawer: true,
          fullView: false
        },

        {
          title: 'Logout',
          name: "Logout",
          cmd: EMenuCommand.logout,
          url: null,
          icon: EAppIconsStandard.exit,
          loginRequired: true,
          enableDrawer: true,
          fullView: false
        },

        {
          title: 'Landing',
          name: "Landing",
          url: ERouteDef.landing,
          icon: 'person-add',
          loginRequired: false,
          enableDrawer: true,
          enabled: false,
          fullView: false
        },
        {
          title: 'Tutorial',
          name: "Tutorial",
          url: ERouteDef.tutorial,
          icon: 'person-add',
          loginRequired: false,
          enableDrawer: true,
          enabled: false,
          fullView: false
        },

      ]
    }
  ];

  activationStarted: boolean = false;
  watchRouterLoaded: BehaviorSubject<boolean>;
  routerLoaded: boolean = false;
  initURL: string = null;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    console.log('popstate Event', event);
    if (this.platformFlags.WEB) {
      if (!this.backButton.handlePopStateEvent()) {
        this.uiext.showToastNoAction("Browser navigation not available/ please use the app buttons", true, 2000);
      }
      // event.returnValue = undefined;
      // event.preventDefault();
    }
  }

  @HostListener('window:statusTap', ['$event'])
  handleStatusBarTap(event: Event) {
    console.log("status bar tap detected: ", event);
    this.webviewUtils.resetViewframe(true, true);
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log("global key press detected: " + event.keyCode);
    this.keyHandler.registerKeyPress(event.keyCode);
  }

  /**
   * check browser refresh
   * save nav params to local storage
   */
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload: ", event);
    if (!GeneralCache.codePushReload && GeneralCache.isWeb) {
      // Do more processing...
      let flag: IAppFlagsElement = {
        flag: ELocalAppDataKeys.refreshTest,
        value: true
      };
      this.storageOps.setStorageFlagNoAction(flag);
      this.nps.snapshot();

      // mobile
      this.locationManager.setBackgroundGeolocation(false);
      this.localNotifications.clearLocal();
      this.notifications.clearOSNotifications();

      // event.returnValue = false;
      event.returnValue = undefined;
      // event.preventDefault();
    }
  }


  constructor(
    public router: Router,
    public authRequest: AuthRequestService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public settingsProvider: SettingsManagerService,
    public backgroundModeProvider: BackgroundModeService,
    public uiext: UiExtensionService,
    public analytics: AnalyticsService,
    public crashlytics: CrashlyticsService,
    public scriptLoader: ScriptLoaderService,
    public locationManager: LocationManagerService,
    public backButton: BackButtonService,
    public menuCtrl: MenuController,
    public userDataProvider: UserDataService,
    public keyboard: KeyboardProvider,
    public localNotifications: LocalNotificationsService,
    public notifications: NotificationsService,
    public appVersionService: AppVersionService,
    public popupFeatures: PopupFeaturesService,
    public resourceHandler: ResourceHandlerDataService,
    public splitPane: SplitPaneService,
    public storageOps: StorageOpsService,
    public social: SocialAuthWrapperService,
    public shadowDomService: ShadowDomService,
    public nps: NavParamsService,
    public navUtils: NavUtilsService,
    public resourcesCore: ResourcesCoreDataService,
    public soundEffects: SoundEffectsService,
    public accountManager: AccountManagerService,
    // required to init
    public bgModeWatch: BackgroundModeWatchService,
    // required to init
    public bgModeWatchController: BackgroundModeWatchControllerService,
    public webviewUtils: WebviewUtilsService,
    public mediaUtils: MediaUtilsService,
    public keyHandler: KeyHandlerService,
    public testingManager: TestingManagerService,
    public mqttService: MQTTService,
    public carouselConfig: NgbCarouselConfig,
    public appDiagnostics: AppDiagnosticService,
    public swUpdate: SwUpdate,
    public imageLoaderTs: ImageLoaderTSService,
    public storyData: StoryDataService
  ) {
    this.watchRouterLoaded = new BehaviorSubject<boolean>(null);
    this.configureCarousel();
    console.log("app constructor");
    console.log(this.router.url);
  }

  configureCarousel() {
    // customize default values of carousels used by this component tree
    this.carouselConfig.interval = 2000;
    this.carouselConfig.keyboard = false;
    this.carouselConfig.pauseOnHover = false;
  }

  ngOnInit() {
    this.navUtils.getTransparentBgObservable().subscribe((enabled: boolean) => {
      if (enabled != null) {
        if (!this.platformFlags.WEB) {
          this.transparentBg = enabled; // google maps native view
        }
      }
    }, (err: Error) => {
      console.error(err);
    });
    this.initializeApp();
  }

  watchRoute() {
    this.router.events.subscribe((event: any) => {

      if (event instanceof RoutesRecognized) {
        if (!this.initURL) {
          console.log("router event init: ", event.url);
          this.initURL = event.url;
        }
      }

      if (event instanceof NavigationStart) {
        console.log("navigation start");
        console.log(this.router.url);
      }

      if (event instanceof NavigationEnd) {
        console.log("navigation end");
        console.log(this.router.url);
        this.watchRouterLoaded.next(true);
      }

      if (event instanceof ActivationStart) {
        // if (this.activationStarted) {
        //   console.log("activation already started");
        //   this.outlet.deactivate();
        // }
        // this.activationStarted = true;
      }
    });
  }

  checkPlatform() {
    // default web
    this.platformType = EPlatformDef.WEB;

    console.log("platform check: ", this.plt.platforms());

    // cordova should be available on mobile
    // it is by default, but with code push the --prod flag must be used for building the release including cordova.js
    if (this.plt.is('cordova') || this.plt.is('capacitor')) {

      this.setPlatformMobile();

      // if (!document.URL.startsWith('file:///')) {
      //   console.log('you are on livereload mode');
      //   this.platformType = EPlatformDef.MOBILE_LIVERELOAD;
      // }

      if (this.plt.is("android")) {
        console.log("platform check: os android");
        GeneralCache.os = EOS.android;
        this.settingsProvider.setPlatform(EPlatformDef.ANDROID, false);
      } else if (this.plt.is("ios")) {
        console.log("platform check: os iOS");
        GeneralCache.os = EOS.ios;

        // if (window) {
        //   window.console.log = function () { };
        // }

        this.settingsProvider.setPlatform(EPlatformDef.IOS, false);
      } else if (this.plt.is("ipad")) {
        console.log("platform check: os iOS/iPad");
        GeneralCache.os = EOS.ios;
        this.settingsProvider.setPlatform(EPlatformDef.IOS, false);
      } else {
        // for some reason iPad is not detected properly
        console.log("platform check: unknown platform, using os iOS/iPad by default");
        GeneralCache.os = EOS.ios;
        this.settingsProvider.setPlatform(EPlatformDef.IOS, false)
        // console.log("platform check: unknown platform");
        // GeneralCache.os = EOS.browser;
        PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(Messages.msg.iPadDetected.after.msg, Messages.msg.iPadDetected.after.sub, null, false, 5000), true);
        // this.uiext.showAlertNoAction(Messages.msg.iPadDetected.after.msg, Messages.msg.iPadDetected.after.sub);
      }
    } else {
      // Disable the split plane in this page
      this.splitPane.setSplitPane(false);
      this.isWeb = true;
      GeneralCache.os = EOS.browser;
      GeneralCache.isIOSWebClient = BrowserUtils.checkIOSBrowser();
      GeneralCache.isPWA = BrowserUtils.checkPWA();
      console.log("web context | ios: " + GeneralCache.isIOSWebClient + " | pwa: " + GeneralCache.isPWA);
      if (!BrowserUtils.checkSupportedBrowser()) {
        this.uiext.showAlert("Browser warning", BrowserUtils.dispUnsupportedBrowserMessage(), 1, null, false).then((res: number) => {
          if (res === EAlertButtonCodes.ok) {
            OtherUtils.copyToClipboard(window.location.origin + "/#" + this.initURL);
            this.uiext.showToastNoAction("copied to clipboard", false);
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }
  }

  setPlatformMobile() {
    console.log("platform check: is mobile");
    this.platformType = EPlatformDef.MOBILE;
    if (navigator && navigator.userAgent != null) {
      if (navigator.userAgent.toLowerCase().indexOf('crosswalk') > -1) {
        console.log("crosswalk enabled");
      } else {
        console.log("crosswalk disabled");
      }
    } else {
      console.log("navigator not present");
    }
  }

  checkPlatformV2() {
    // default web
    this.platformType = EPlatformDef.WEB;

    if (this.plt.is("android")) {

      this.setPlatformMobile();

      console.log("platform check: os android");
      GeneralCache.os = EOS.android;
      this.settingsProvider.setPlatform(EPlatformDef.ANDROID, false);
    } else if (this.plt.is("ios")) {

      this.setPlatformMobile();

      console.log("platform check: os iOS");
      GeneralCache.os = EOS.ios;

      // if (window) {
      //   window.console.log = function () { };
      // }

      this.settingsProvider.setPlatform(EPlatformDef.IOS, false);
    } else {

      console.log("platform check: web");
      // web
      // Disable the split plane in this page
      this.splitPane.setSplitPane(false);

      // test
      // GeneralCache.os = EOS.ios;
    }
  }


  initializeApp() {
    this.webviewUtils.ready().then(async () => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need
      console.log("platform ready");

      this.analytics.initializeConsoleLogger();
      this.crashlytics.init();
      this.crashlytics.setEnabled().then(() => {
        PromiseUtils.wrapNoAction(this.crashlytics.didCrashOnPreviousExecution(), true);
        PromiseUtils.wrapNoAction(this.crashlytics.sendUnsentReports(), true);
      }).catch((err: Error) => {
        console.error(err);
      });

      let tboot = new Date();
      GeneralCache.tboot = tboot.getTime();
      GeneralCache.appFlags.sessionId = "" + GeneralCache.tboot;
      GeneralCache.appFlags.tboot = tboot.toISOString();

      this.shadowDomService.updateSidemenu(this.menuContainer);
      this.checkPlatform();

      PromiseUtils.wrapNoAction(this.appDiagnostics.getDeviceInfo(), true);

      this.imageLoaderTs.init();
      // this.platform_type = platforms.MOBILE; // test mobile
      this.settingsProvider.setPlatform(this.platformType, true);
      this.platformFlags = SettingsManagerService.settings.platformFlags;

      this.appVersionService.checkWebProdInit();

      if (this.platformFlags.ANDROID) {
        this.webviewUtils.setOverlayWebView(false);
      }
      // unfortunately this has no effect on Android
      // StatusBar.styleDefault();
      // this.statusBar.styleBlackOpaque();

      // this.statusBar.backgroundColorByName('black');
      PromiseUtils.wrapNoAction(StatusBar.setBackgroundColor({
        color: "#1e72a3"
      }), true);
      // this.statusBar.backgroundColorByHexString("#387ff5");
      // this.statusBar.hide();

      this.watchRoute();
      if (!this.platformFlags.WEB) {
        this.routerLoaded = true;
      }
      this.routerLoaded = true; // method disabled / not needed / not working

      await SleepUtils.sleep(1000);
      // the timeout should be as defined in config.xml
      await PromiseUtils.wrapResolve(SplashScreen.hide(), true);

      // await SleepUtils.sleep(10000);

      console.log("app ready");

      if (this.platformFlags.ANDROID) {
        setTimeout(() => {
          this.webviewUtils.setOverlayWebViewDefault();
        }, 5000);
      } else {
        this.webviewUtils.setOverlayWebViewDefault();
      }

      this.keyboard.standardKeyboardEventInit();
      this.subscribeToFocusObservable();

      console.log("running mobile OS code: " + GeneralCache.os);
      console.log("platform flags: ", this.platformFlags);

      // load settings
      this.initLocalProviders();
      this.initMobileApis();
      this.handleLoginEvents();
      this.checkActivePageFullView();
      if (this.platformFlags.WEB) {
        this.initServiceWorker();
      }
      // this.initViewFrame();
      await PromiseUtils.wrapResolve(this.appVersionService.getAppVersion(), true);
      this.checkLogin();
    });
  }

  initServiceWorker() {
    // Listen for available updates
    this.swUpdate.available.subscribe(event => {
      console.log("service worker update is available: ", event);
      if (confirm('A new version is available. Load it?')) {
        window.location.reload();
      }
    });
  }

  initViewFrame() {
    if (!this.isWeb) {
      return;
    }
    try {
      // requires user action
      this.uiext.showAlert("UI", "Enter full screen (recommended)", 2, null, true).then((res: number) => {
        if (res === EAlertButtonCodes.ok) {
          var elem: any = document.documentElement;
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  checkActivePageFullView() {
    for (let page of this.appMenu[0].pages) {
      if (this.isActive(page)) {
        console.log("isActive page init: ", page);
        // check full view options
        this.checkFullView(page);
      }
    }
  }

  /**
  * main loader
  * preload resources from server
  * handle user auth
  * retry enabled
  */
  async checkLogin() {
    if (this.appInitInProgress) {
      return;
    }

    this.resourcesCore.triggerApiKeyLoadingRequired();

    this.appInitInProgress = true;
    await this.uiext.showLoadingV2Queue("Login");
    console.log("check login");
    // this.liveupdate.checkUpdate();
    // check local auth
    this.authRequest.getAuthHeaders().then(() => {
      this.checkLoginCore();
    }).catch(async (err: Error) => {
      console.error(err);
      await this.uiext.dismissLoadingV2WithSkip(false);
      this.appInitInProgress = false;
      this.authRequest.setLoggedInFlag(false);
    });
  }

  checkLoginCore() {
    // check server auth
    this.authRequest.checkLoginServer().then(async (response: IGenericResponse) => {
      console.log("check login server");
      // show popup if something is wrong with the user account
      // e.g. the email is not validated
      let respdata: ICheckLoginResponse = response.data;

      if (!respdata.valid) {
        this.popupFeatures.checkLoginCode(respdata.respCode, respdata.message);
      }

      // test
      // respdata.versionOk = false;

      await this.uiext.dismissLoadingV2WithSkip(true);

      if (respdata.versionOk === false) {
        // this is fallback for when the app is way too old and will no longer start because some breaking changes
        // show warning and "convince" the user to update
        // proceed to load resources anyway
        // console.log("app too old");
        await PromiseUtils.wrapResolve(this.popupFeatures.userPromptUpdateApp(null, true), true);
      }
      this.initResourcesMain(respdata);
    }).catch(async (err: Error) => {
      // error
      console.log("check login server error: ", err);
      this.authRequest.setLoggedInFlag(false);
      await this.uiext.dismissLoadingV2WithSkip(false);
      this.appInitInProgress = false;
      // maybe there is no internet connection, try again
      this.retryOrExitApp(err, () => {
        this.checkLogin();
      });
    });
  }

  /**
   * init all resources
   * @param respdata 
   */
  async initResourcesMain(respdata: ICheckLoginResponse) {
    console.log("loading resources");
    await this.uiext.showLoadingV2Queue("Loading resources");
    let promiseResources: Promise<boolean>[] = [];
    promiseResources.push((new Promise((resolve, reject) => {
      this.resourcesCore.loadApiKeys().then(() => {
        ApiDef.apiKeysLoaded = true;
        console.log("resources loaded (1)");
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    })));

    promiseResources.push((new Promise((resolve, reject) => {
      this.resourceHandler.getAllResources(false).then(() => {
        console.log("resources loaded (2)");
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    })));

    promiseResources.push((new Promise((resolve) => {
      this.soundEffects.preload().then(() => {
        console.log("resources loaded (3)");
        resolve(true);
      }).catch((err) => {
        console.error(err);
        resolve(false);
      });
    })));

    Promise.all(promiseResources).then(async () => {
      console.log("init resources main complete");
      this.resourceHandler.setupServiceUrl().then(() => {
        console.log("setup service url complete");
      });

      this.mqttService.createConnection();

      this.analytics.checkTelemetryWizard();
      this.analytics.startWatchTelemetryCommands();

      // load google maps w/ api key spec
      this.scriptLoader.loadGoogleMaps(GeneralCache.useWebGL && (this.platformFlags.WEB || (!this.platformFlags.WEB && GeneralCache.useWebGLMobile))).then(() => {
        this.settingsProvider.setMapReadyWatch();
      });
      // authorized
      // check if the user can be also a tester (tester flag in db)
      await this.uiext.dismissLoadingV2WithSkip(false);
      console.log("app init complete");
      this.appInitComplete = true;
      this.appInitInProgress = false;
      this.authRequest.setLoggedInFlag(true);
      this.userDataProvider.afterSetLoggedInFlag(true, respdata);
      this.mediaUtils.initConfig();
      this.testingManager.checkTestFlightDistribution();
      PromiseUtils.wrapNoAction(this.storyData.restoreProgressFromCache(), true);
    }).catch(async (err: Error) => {
      console.error(err);
      await this.uiext.dismissLoadingV2WithSkip(false);
      this.analytics.dispatchError(err, "main");
      this.appInitInProgress = false;
      this.retryOrExitApp(err, () => {
        // this.initResourcesMain(respdata);
        this.checkLogin();
      });
    });
  }


  /**
   * retry callback
   * exit app otherwise 
   * @param err 
   * @param onRetry 
   */
  retryOrExitApp(err: Error, onRetry: () => any) {
    // timeout just in case, make sure the alert shows up
    console.log("retry or exit app");
    setTimeout(() => {

      let actions: IPopoverActions = {
        retry: {
          name: "Retry loading",
          code: 1,
          enabled: true
        },
        exit: {
          name: "Exit app",
          code: 2,
          enabled: true
        },
        reset: {
          name: "Reset account",
          code: 3,
          enabled: true
        }
      };

      this.uiext.showCustomAlert(Messages.msg.resourceInitFailed.after.msg, ErrorMessage.parseAddBefore(err, Messages.msg.resourceInitFailed.after.sub), actions, false).then((res: number) => {
        switch (res) {
          case 1:
            onRetry();
            break;
          case 2:
            if (GeneralCache.os === EOS.ios) {
              this.uiext.showAlertNoAction(Messages.msg.info.after.msg, "Please use the home button to exit");
            } else {
              this.backButton.exitApp();
            }
            break;
          case 3:
            this.accountManager.logoutPrompt().then(() => {
              this.uiext.showAlert(Messages.msg.loginAgain.after.msg, Messages.msg.loginAgain.after.sub, 1, null).then(() => {
                onRetry();
              }).catch((err: Error) => {
                console.error(err);
                onRetry();
              });
            });
            break;
          default:
            // should not reach here (dismiss is not allowed to prevent unhandled states)
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "main");
      });

    }, 500);
  }

  subscribeToFocusObservable() {
    this.focusObservableSub = this.uiext.getFocusObservable().subscribe((focus: boolean) => {

      if (focus != null) {
        this.setClass(focus);
      }
    }, (err: Error) => {
      console.error(err);
    });
  }


  initMobileApis() {
    if (!this.platformFlags.WEB) {
      // hardcoded settings
      // lock screen orientation
      PromiseUtils.wrapNoAction(ScreenOrientation.lock({
        orientation: "portrait"
      }), true);
      this.analytics.setEnabled(true);

      // google analytics sending start message by default
      this.analytics.init().then(() => {
        this.analytics.sendEvent(ETrackedEvents.start, "Start", "Hello", 1, true);
      }).catch((err: Error) => {
        console.error(err);
      });

      if (cordova && cordova.InAppBrowser) {
        window.open = cordova.InAppBrowser.open;
      }
    }

    // wait until settings loaded
    this.settingsProvider.getSettingsLoaded(false).then((data: boolean) => {
      // general setup
      if (data) {
        if (!this.platformFlags.WEB) {
          // sync with geolocation mode settings
          this.locationManager.setGeolocationMode(SettingsManagerService.settings.app.settings.locationMode.value);
          this.backgroundModeProvider.init();
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  initLocalProviders() {
    this.settingsProvider.init(this.platformFlags.IOS);
    this.social.setPlatform(this.platformFlags);
    MarkerUtils.setPlatform(this.platformFlags);
    this.notifications.clearOSNotifications();
  }

  /**
   * watch login events
   */
  handleLoginEvents() {
    this.authRequest.checkAuthentication().subscribe((loggedin: boolean) => {
      console.log("login event: ", loggedin);
      if (loggedin !== null) {
        this.authenticated = loggedin;
        this.handleLoginTransition(loggedin);
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  /**
   * handle login transition
   * @param loggedin
   */
  handleLoginTransition(loggedin: boolean) {

    console.log("login transition detected: ", loggedin);
    console.log("init flags: ", this.appInitComplete, this.appInitInProgress);

    if (loggedin === true) {
      // logged in
      // go to home page only if user was on login page
      // else it should stay on the current page i.e. when page refresh
      if (this.appInitComplete) {
        this.handleLoginTrue();
      } else {
        // load resources first
        // reload init chain
        this.checkLogin();
      }
    } else if (loggedin === false) {
      // not logged in
      this.handleLoginFalse();
    }
  }

  /**
   * gets current active page based on url
   * partial url check (without params)
   */
  private getActiveRouteUrl() {
    let url: string = this.router.url;
    let activeRoute: Route = this.getActiveRouteUrlCore();
    console.log("get active route @" + url + ": ", activeRoute);
    return activeRoute ? activeRoute.path : null;
  }

  /**
  * gets current active page based on url
  * partial url check (without params)
  */
  private getActiveRouteUrlCore(): Route {
    let url: string = this.router.url;
    url = url.substring(1);
    let activeRoute: Route = null;
    for (let i = 0; i < routes.length; i++) {
      let r: Route = routes[i];
      // console.log("check active route: ", r.path);
      // if ((url.indexOf(r.path) !== -1) || (r.path.indexOf(url) !== -1)) {
      if (url.startsWith(r.path)) {
        activeRoute = r;
        break;
      }
    }
    return activeRoute;
  }

  private waitRouterLoaded() {
    return new Promise((resolve) => {
      WaitUtils.waitFlagResolve(this.routerLoaded, this.watchRouterLoaded, [true], null).then((ok: boolean) => {
        if (ok != null) {
          resolve(true);
        }
      });
    });
  }

  private handleLoginTrue() {
    this.waitRouterLoaded().then(async () => {
      let activeRoute: string = this.getActiveRouteUrl();
      console.log("active route: " + activeRoute);
      let redirect: boolean = false;
      let tutorialSeen: boolean = await this.storageOps.checkStorageFlagResolve({
        flag: ELocalAppDataKeys.tutorialSeenLocal,
        value: false
      }, true);
      console.log("tutorial seen: ", tutorialSeen);
      if (tutorialSeen) {
        // tutorial seen, proceed to home page
        if (this.platformFlags.WEB) {
          if (activeRoute != null) {
            console.log("is active route " + activeRoute);
            if (activeRoute === ERouteDef.login || activeRoute === ERouteDef.signup || activeRoute === ERouteDef.landing) {
              redirect = true;
            } else {
              // stay on current page
            }
          } else {
            redirect = true;
          }
        } else {
          redirect = true;
        }
      } else {
        // redirect tutorial page
        console.log("tutorial not seen");
        redirect = true;
      }

      if (redirect) {
        if ((this.initURL != null) && (this.initURL !== "/") && (this.initURL !== ("/" + ERouteDef.login))) {
          // navigate to typed-in URL (restore navigation after login)
          console.log("restore requested URL: ", this.initURL);
          // Step 1: Extract query parameters using JavaScript
          let redirectURL: string = "";
          let queryString = "";
          let queryParams: Params = {};

          if (this.initURL && (this.initURL.indexOf("?") !== -1)) {
            redirectURL = this.initURL.split("?")[0];
            queryString = this.initURL.split("?")[1];
            if (queryString) {
              const paramPairs = queryString.split('&');
              paramPairs.forEach(pair => {
                const [key, value] = pair.split('=');
                queryParams[key] = decodeURIComponent(value);
              });
            }
          } else {
            redirectURL = this.initURL;
          }
          console.log("redirect URL: ", redirectURL);
          console.log("query params: ", queryParams);

          // don't redirect on next refresh (skip tutorial)
          this.storageOps.setStorageFlagNoAction({
            flag: ELocalAppDataKeys.tutorialSeenLocal,
            value: true
          });

          this.router.navigate([redirectURL], { replaceUrl: true, queryParams: queryParams }).then(() => {
            this.setMenuOpenFlag(false);
            // never ever do this: this.rootPage = page; !! this would load the page twice and mess up the initialization logic
          }).catch((err: Error) => {
            console.error(err);
          });
        } else {
          // default init
          if (tutorialSeen) {
            this.setRootPage(ERouteDef.home);
          } else {
            this.setRootPage(ERouteDef.tutorial);
          }
        }
      } else {

      }
    });
  }

  private handleLoginFalse() {
    this.waitRouterLoaded().then(() => {
      let activeRoute: string = this.getActiveRouteUrl();

      // request reload all resources 
      // for e.g. switching user accounts
      this.resourceHandler.clearAllResources();
      this.appInitComplete = false;

      if (this.platformFlags.WEB) {
        if (activeRoute) {
          if (activeRoute !== ERouteDef.signup) {
            this.setRootPage(ERouteDef.login);
          }
        } else {
          this.setRootPage(ERouteDef.login);
        }
      } else {
        this.setRootPage(ERouteDef.login);
      }
    });
  }

  /**
  * set menu open flag
  * add delay for animation
  * (improve performance by not overlapping with existing ionic animations)
  */
  setMenuOpenFlag(open: boolean) {
    this.menuOpen = open;
    this.timeoutOpen = ResourceManager.clearTimeout(this.timeoutOpen);
    this.timeoutOpen = setTimeout(() => {
      this.menuOpenDelay = open;
      this.setClass(open);
    }, 200);
  }

  closeDrawerMenu() {
    // if (this.contentDrawer) {
    //   this.contentDrawer.close();
    // }
  }



  isActive(_page: IPageInterface) {
    return false;
  }


  /**
   * check special commands that may be attributed to a menu item
   * @param cmd 
   */
  checkMenuCmd(cmd: number) {
    let withCmd: boolean = true;
    switch (cmd) {
      case EMenuCommand.logout:
        this.closeMenu();
        this.accountManager.logoutPromptNoAction();
        break;
      case EMenuCommand.exit:
        this.backButton.exitApp();
        break;
      default:
        withCmd = false;
        break;
    }
    return withCmd;
  }

  closeMenu() {
    this.menuCtrl.close().then(() => {
      console.log("menu closed on request");
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  setClass(_open: boolean) {
    if (!this.enableBlur) {
      return;
    }

    // if (open) {
    //   this.renderer.removeClass(this.nav._elementRef.nativeElement, "custom-backdrop-none");
    //   this.renderer.addClass(this.nav._elementRef.nativeElement, "custom-backdrop");
    // } else {
    //   this.renderer.removeClass(this.nav._elementRef.nativeElement, "custom-backdrop");
    //   this.renderer.addClass(this.nav._elementRef.nativeElement, "custom-backdrop-none");
    // }
  }

  menuOpened() {
    if (!this.menuOpen) {
      console.log("menu opened");
      this.setMenuOpenFlag(true);
      this.backButton.push(() => {
        this.closeMenu();
      });
    }
  }


  checkFullView(page: IPageInterface) {
    if (page.fullView) {
      this.webFrameEnabled = false;
    } else {
      this.webFrameEnabled = true;
    }
  }


  /**
   * open page
   * set root page
   * set open view
   * @param page 
   */
  openPage(page: IPageInterface) {
    console.log("openPage: " + page.name + " @" + page.url);
    this.checkFullView(page);
    if (!this.checkMenuCmd(page.cmd)) {
      this.closeMenu();
      this.setRootPageCore(page.url).then(() => {
        this.navUtils.setOpenView(page);
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }


  private setRootPage(url: string) {
    this.setRootPageCore(url).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  private setRootPageCore(url: string) {
    let promise = new Promise((resolve, reject) => {
      this.closeDrawerMenu();
      if (url == null) {
        reject(new Error("undefined page url"));
        return;
      }

      this.backButton.reset();

      // clear nav params
      this.nps.clearAll();

      this.router.navigate([url], { replaceUrl: true }).then(() => {
        this.setMenuOpenFlag(false);
        // never ever do this: this.rootPage = page; !! this would load the page twice and mess up the initialization logic
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  /**
   * override default menu closed
   * skip back button pop as it's replaced by the view component on init
   */
  openView(page: IPageInterface) {
    // this.menuCtrl.isOpen().then((isOpen: boolean) => {
    //   // wait for animation to complete (prevent assert errors)
    //   if (isOpen) {
    //     // perform the requested page navigation
    //     this.navUtils.setOpenView(page);
    //     if (this.menuOpen) {
    //       console.log("menu closed on select item");
    //       this.setMenuOpenFlag(false);
    //     }
    //   }
    // });

    // perform the requested page navigation
    this.navUtils.setOpenView(page);
    if (this.menuOpen) {
      console.log("menu closed on select item");
      this.setMenuOpenFlag(false);
    }
  }

  menuClosed() {
    if (this.menuOpen) {
      console.log("menu closed");
      this.backButton.pop();
      this.setMenuOpenFlag(false);
    }
  }


  openTutorial() {
    this.setRootPage(ERouteDef.tutorial);
  }
}
