import { Injectable } from "@angular/core";
import { ILeplaceObjectContainer, ILeplaceObject, IPrepareCoinSpecs, ILeplaceObjectCallbackData, EPrepareCoinCircle } from "../../../../classes/def/core/objects";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { IWaypointCoordsMulti, IPlaceMarkerContent, IMarkerDetailsOpenContext, IMarkerDetailsAppContext } from "../../../../classes/def/map/map-data";
import { EMarkerLayers } from "../../../../classes/def/map/marker-layers";
import { MarkerHandlerService } from "../../../map/markers";
import { GeoObjectsService } from "../geo-objects";
import { ITreasureSpec } from "../../../../classes/def/places/leplace";
import { ETreasureType } from "../../../../classes/def/items/treasures";
import { EMarkerIcons } from "../../../../classes/def/app/icons";
import { EMarkerTypes, EMarkerPriority, EMapShapes, EMarkerRenderPriority, MarkerPriority } from "../../../../classes/def/map/markers";
import { ItemScannerUtils } from "../item-scanner-utils";
import { GeometryUtils } from "../../../utils/geometry-utils";
import { IActivity } from "../../../../classes/def/core/activity";
import { GameUtils } from "../../../../classes/utils/game-utils";
import { LocationMonitorService } from "../../../map/location-monitor";
import { AppConstants, EDefaultDistanceSpecs } from "../../../../classes/app/constants";
import { IExploreActivityInit, EExploreCoinAction, ICoinSpecsMpSyncData, IExploreCoinGen, EExploreContext, EExploreObjectDynamics, IExploreCollectibleParams, EExploreCoinScope } from 'src/app/classes/def/activity/explore';
import { EGeoObjectsProviderCode } from 'src/app/classes/def/places/geo-objects';
import { ArrayUtils } from 'src/app/services/utils/array-utils';
import { IFixedCoin } from "src/app/classes/def/core/custom-param";
import { PromiseUtils } from "src/app/services/utils/promise-utils";
import { DeepCopy } from "src/app/classes/general/deep-copy";
import { MarkerUtilsService } from "src/app/services/map/marker-utils-provider";
import { ILatLng } from "src/app/classes/def/map/coords";
import { MarkerUtils } from "src/app/services/map/marker-utils";
import { EColorsNames } from "src/app/classes/def/app/theme";
import { StringUtils } from "../../utils/string-utils";
import { EMessageTrim } from "src/app/classes/utils/message-utils";
import { INearbyContentMagnetElem, ENearbyContentType } from "src/app/classes/def/map/gmap-utils";
import { UiExtensionService } from "src/app/services/general/ui/ui-extension";
import { Messages } from "src/app/classes/def/app/messages";


export interface IExploreCheckCollectReturn {
    minDistanceToAnyObject: number,
    maxDistanceToAnyObject: number
}

export interface ILeplaceObjectContainerContext {
    [key: string]: ILeplaceObjectContainer[]
}

export interface ILeplaceExploreContextStats {
    collectDistance: number;
    collectDistanceOverride: number;
    collectedCoins: number;
    collectedCoinsValue: number;
    collectedCoinsList: ILeplaceObject[];
    targetCoins: number;

    coinSpecsArray: ILeplaceObject[];
    coinSpecsDefault: ILeplaceObject;
    coinIndexMintCrt: number;
    coinIndexPrepareCrt: number;

    fixedCoins: IFixedCoin[];
}

export interface ILeplaceExploreContextWrapper {
    [key: string]: ILeplaceExploreContextStats
}


@Injectable({
    providedIn: 'root'
})
export class ExploreActivityUtilsService {

    coinObjects = {} as ILeplaceObjectContainerContext;
    coinObjectsInRange = {} as ILeplaceObjectContainerContext;

    currentContext: string = EExploreContext.explore;

    observable = {
        coins: null,
        canClearMap: null
    };

    subscription = {
        canClearMap: null
    };

    exploreStats = {} as ILeplaceExploreContextWrapper;

    moduleName: string = "EXPLORE UTILS > ";
    coinRadius: number = 80;

    headingZone: boolean = false;
    pendingMarkers: number = 0;
    autoCollect: boolean = true;
    autoCollectOverride: boolean = false;
    enableAutoCollectOverride: boolean = false;
    autoCollectGen: boolean = true;
    collectLock: boolean = false;

    keepCollectedCoins: boolean = true;
    coinLock: boolean = false;

    constructor(
        private markerHandler: MarkerHandlerService,
        private markerUtils: MarkerUtilsService,
        private locationMonitor: LocationMonitorService,
        private geoObjects: GeoObjectsService,
        private uiext: UiExtensionService
    ) {
        console.log("explore activity service created");
        this.observable = ResourceManager.initBsubObj(this.observable);
        this.setContext(EExploreContext.explore);
        this.initCoinSpecs();
    }

    setContext(context: string) {
        this.coinObjects[context] = [];
        this.coinObjectsInRange[context] = [];
        this.exploreStats[context] = {
            collectDistance: AppConstants.gameConfig.collectDistance,
            collectDistanceOverride: null,
            collectedCoins: 0,
            collectedCoinsValue: 0,
            collectedCoinsList: [],
            targetCoins: null,
            /**
             * there may be multiple coin types with specified or equal distribution
             */
            coinSpecsArray: [],
            coinSpecsDefault: null,
            coinIndexMintCrt: 0,
            coinIndexPrepareCrt: 0,
            fixedCoins: []
        };
        this.currentContext = context;
    }

    getExploreStats() {
        return this.exploreStats[this.currentContext];
    }

    setKeepCollected(enable: boolean) {
        this.keepCollectedCoins = enable;
    }

    setAutoCollect(enabled: boolean) {
        console.log("explore utils > set auto collect: ", enabled);
        this.autoCollect = enabled;
        this.autoCollectGen = enabled;
    }

    setCollectLock(locked: boolean) {
        this.collectLock = locked;
    }

    setAutoCollectOverride(enabled: boolean) {
        console.log("explore utils > set auto collect override: ", enabled);
        this.autoCollect = enabled;
        this.autoCollectOverride = enabled;
        this.enableAutoCollectOverride = true;
    }

    resetAutoCollectOverride() {
        console.log("explore utils > reset auto collect");
        this.autoCollect = this.autoCollectGen;
        this.enableAutoCollectOverride = false;
    }

    markerCallback = (a: IPlaceMarkerContent, b: IMarkerDetailsOpenContext) => {
        console.log("define marker callback: ", a, b);
    }

    /**
     * set main entry point for marker callback (linked to map)
     * @param mc 
     */
    setMarkerCallback(mc: (data: IPlaceMarkerContent, context: IMarkerDetailsOpenContext) => any) {
        this.markerCallback = mc;
    }

    resetCoinSpecs() {
        this.exploreStats[this.currentContext].targetCoins = null;
    }

    /**
     * set default coin specs
     */
    initCoinSpecs() {
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        cs.coinSpecsDefault = ItemScannerUtils.getLocalObjectSpecs(ETreasureType.exploreObject);
        cs.coinSpecsDefault.marker = EMarkerIcons.coin;
        cs.coinSpecsArray = [Object.assign({}, cs.coinSpecsDefault)];
    }

    setCollectRadiusOverride(radius: number) {
        this.exploreStats[this.currentContext].collectDistanceOverride = radius;
    }

    getCollectRadiusOverride() {
        return this.exploreStats[this.currentContext].collectDistanceOverride;
    }

    getCollectRadiusCheckOverride() {
        let es: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        if (es.collectDistanceOverride != null) {
            return es.collectDistanceOverride;
        }
        return es.collectDistance;
    }

    /**
     * reset coin collect counter
     * @param collectDistance 
     */
    initSession(collectDistance: number) {
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        // reset coin collect counter
        cs.collectedCoins = 0;
        cs.collectedCoinsValue = 0;
        cs.collectedCoinsList = [];
        this.collectLock = false;
        if (collectDistance != null) {
            cs.collectDistance = collectDistance;
        }
        this.observable.canClearMap.next(null);
    }

    register(c: IExploreCoinGen) {
        // console.log("register explore coin event: ", c);
        this.observable.coins.next(c);
    }

    getWatchCoins() {
        return this.observable.coins;
    }

    /** 
     * set custom coin specs
     * @param specsArray 
     */
    changeCoinSpecs(specsArray: ITreasureSpec[], activity: IActivity, saveDefaults: boolean) {
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        console.log(this.moduleName + "set coin specs: ", specsArray);
        if (specsArray && specsArray.length) {
            cs.coinSpecsArray = [];
            for (let i = 0; i < specsArray.length; i++) {
                let spec: ITreasureSpec = specsArray[i];
                let objectSpecs: ILeplaceObject = ItemScannerUtils.getCrateObjectSpecsFromTreasureSpecs(spec);
                objectSpecs.customParamCode = spec.customParamCode;
                objectSpecs.customParamValue = spec.customParamValue;
                objectSpecs.customParamReference = (spec.customParamReference != null) ? DeepCopy.deepcopy(spec.customParamReference) : null;
                objectSpecs.callbackData = {
                    title: spec.dispName,
                    heading: null,
                    description: spec.description,
                    contextDescription: activity != null ? activity.objectDescription : "",
                    photoUrl: spec.photoUrl,
                    large: false
                };
                objectSpecs.callback = (data: IPlaceMarkerContent) => {
                    this.markerCallback(data, objectSpecs.callbackData); // default callback
                };
                cs.coinSpecsArray.push(objectSpecs);
            }
            if (saveDefaults) {
                let spec: ILeplaceObject = ItemScannerUtils.getCrateObjectSpecsFromTreasureSpecs(specsArray[0]);
                cs.coinSpecsDefault = Object.assign({}, spec);
            }
            console.log("coin specs array: ", cs.coinSpecsArray);
        }
    }

    /**
     * reset defaults
     */
    useDefaultCoinSpecs() {
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        console.log(this.moduleName + "set default coin specs: ", cs.coinSpecsDefault);
        if (cs.coinSpecsDefault) {
            let objectSpecs: ILeplaceObject = Object.assign({}, cs.coinSpecsDefault);
            objectSpecs.callbackData = {
                title: "Coin",
                heading: null,
                description: "Virtual coin",
                contextDescription: "Collect virtual coins",
                large: false,
            };
            objectSpecs.callback = (data: IPlaceMarkerContent) => {
                this.markerCallback(data, objectSpecs.callbackData);
            };
            cs.coinSpecsArray = [objectSpecs];
        }
    }

    /**
     * update coin specs from activity
     * @param activity 
     * @param markerCallback 
     */
    setCoinSpecsForActivity(activity: IActivity) {
        let treasureSpecs: ITreasureSpec[] = GameUtils.getCoinSpecs(activity.customParams);
        console.log("treasure specs: ", treasureSpecs);
        if (treasureSpecs && (treasureSpecs.length > 0)) {
            this.changeCoinSpecs(treasureSpecs, activity, false);
        } else {
            this.useDefaultCoinSpecs();
        }
    }

    updateCoinPosition(c: ILeplaceObjectContainer, newLocation: ILatLng) {
        if (this.coinLock) {
            console.warn("coin lock");
            return;
        }
        c.location = newLocation;
        c.placeMarker.location = newLocation;
        c.placeMarker.updateTrigger = true;
        if (c.placeMarkerCircle) {
            c.placeMarkerCircle.location = newLocation;
            c.placeMarkerCircle.updateTrigger = true;
        }
        this.geoObjects.updateMapFirstObject(c);
    }

    getCoinObjects() {
        return this.coinObjects[this.currentContext];
    }

    /**
     * collect item from AR view
     * @param object 
     */
    async collectFromAR(object: ILeplaceObjectContainer) {
        let coin: ILeplaceObjectContainer = null;
        let index: number = 0;
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        console.log("explore collect from AR: ", object, this.coinObjects[this.currentContext]);
        for (let i = 0; i < this.coinObjects[this.currentContext].length; i++) {
            if (this.coinObjects[this.currentContext][i].object.uid === object.object.uid) {
                coin = this.coinObjects[this.currentContext][i];
                index = i;
                break;
            }
        }
        if (coin != null) {
            let collected: boolean = await this.collectCoinWrapper(coin, index, true);
            console.log("collected: ", collected);
            if (collected) {
                let action: number = this.getCoinCollectActionType(object, EExploreCoinAction.collect);
                if (this.hasPickupOrContainerObjects() && !this.hasPickupObjectsNotUsed()) {
                    // check if there is no pickup or container object that was not collected, and allow collecting them directly in this case
                    action = EExploreCoinAction.collect;
                }
                this.register({
                    index: null,
                    value: cs.collectedCoinsValue,
                    amount: cs.collectedCoins,
                    action: action
                });
            }
        }
    }

    /**
     * check if params are not defined from init
     * update based on params generated at runtime (e.g. number of coins in explore-x, based on number of directions found)
     * @param params 
     * @param ncoins 
     */
    checkUpdateParams(params: IExploreActivityInit, ncoins: number) {
        if (!params.coinCap) {
            params.coinCap = ncoins;
            params.minCollectedCoins = 1;
        }
    }


    /**
     * the distance is random within bounds specified by the radius param
     * @param center 
     * @param radius 
     * @param waypointsMultipleDirections 
     */
    getRandomPointOnDirections(center: ILatLng, radius: number, minRadius: number, waypointsMultipleDirections: IWaypointCoordsMulti): ILatLng {
        let placeDistance: number = Math.random() * radius;
        return this.getPointOnRandomDirection(center, waypointsMultipleDirections, placeDistance, radius, minRadius);
    }

    /**
     * the waypoint array index given by coin index and coin cap
     * @param center 
     * @param radius 
     * @param waypointsMultipleDirections 
     * @param coinCap 
     * @param coinIndexPosition 
     */
    getCheckpointOnDirections(center: ILatLng, radius: number, minRadius: number, waypointsMultipleDirections: IWaypointCoordsMulti, coinCap: number, coinIndexPosition: number): ILatLng {
        if (!coinCap) {
            return null;
        }

        // divide total distance by number of coins
        // multiply by coin index to get place distance of current coin
        let placeDistance: number = (radius / coinCap) * (coinIndexPosition + 1);
        return this.getPointOnRandomDirection(center, waypointsMultipleDirections, placeDistance, radius, minRadius);
    }


    /**
     * get a random point along a specified trajectory specified by multiple waypoint arrays 
     * the waypoint array index is random 
     * try until a suitable direction is found that fits the distance and min distance from center
     * @param center 
     * @param waypointsMultipleDirections 
     * @param placeDistance 
     * @param radius 
     */
    getPointOnRandomDirection(center: ILatLng, waypointsMultipleDirections: IWaypointCoordsMulti, placeDistance: number, radius: number, minRadius: number): ILatLng {
        let minDistance: number = minRadius != null ? minRadius : 2 * this.exploreStats[this.currentContext].collectDistance;

        // don't place object within collect distance
        if (placeDistance < minDistance) {
            placeDistance = minDistance;
        }

        let directionCount: number = waypointsMultipleDirections.waypointArray.length;
        // shuffle index
        let directionIndex: number[] = ArrayUtils.shuffleArray(Array.from(Array(directionCount).keys()));
        let point: ILatLng = null;

        for (let i = 0; i < directionCount; i++) {
            // select random direction from multiple directions array
            let randomDirectionIndex: number = directionIndex[i];
            console.log("selected direction index: ", randomDirectionIndex);

            // select waypoints from direction
            let selectedWaypoints: ILatLng[] = waypointsMultipleDirections.waypointArray[randomDirectionIndex].waypoints;
            point = this.getPointOnDirection(center, selectedWaypoints, placeDistance, true);

            if (point != null) {
                break;
            }
        }

        if (!point) {
            // last resort solution, place within radius from user (not on directions though)
            point = GeometryUtils.getRandomPointInRadius(center, minDistance, radius);
        }

        return point;
    }


    /**
     * get point on direction at target distance from center
     * returns null if point could not be assigned on direction
     * @param center 
     * @param waypoints 
     * @param targetDistance 
     */
    getPointOnDirection(center: ILatLng, waypoints: ILatLng[], targetDistance: number, checkHeading: boolean): ILatLng {
        let randomPoint: ILatLng = Object.assign({}, center);
        let waypointCount: number = waypoints.length;
        let sumDistance: number = 0;
        let placeDistanceOnSegmentAcc: number = targetDistance;
        let fits: boolean = false;

        for (let i = 0; i < waypointCount - 1; i++) {
            let validSegment: boolean = checkHeading ? this.checkSegmentOnHeading(center, waypoints[i], waypoints[i + 1]) : true;

            if (validSegment) {

                let currentSegmentDistance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(waypoints[i], waypoints[i + 1], 0);
                sumDistance += currentSegmentDistance;

                // check if required distance is found on the current segment
                // i.e. check if the point should be on the current segment (because the next segment has a larger total distance than the required distance)
                if (targetDistance < sumDistance) {
                    // get remaining distance on current segment (like division remainder)
                    placeDistanceOnSegmentAcc -= (sumDistance - currentSegmentDistance);

                    // console.log("segment (" + i + ", " + (i + 1) + "), length: " + currentSegmentDistance);
                    let deltaLat: number = waypoints[i + 1].lat - waypoints[i].lat;
                    let deltaLng: number = waypoints[i + 1].lng - waypoints[i].lng;
                    // get point on current segment starting from point A coordinates
                    // add delta lat, lng (B-A) multiplied by the ratio between the resulting distance on the segment and the actual segment length
                    randomPoint.lat = waypoints[i].lat + deltaLat * placeDistanceOnSegmentAcc / currentSegmentDistance;
                    randomPoint.lng = waypoints[i].lng + deltaLng * placeDistanceOnSegmentAcc / currentSegmentDistance;
                    fits = true;
                    break;
                }
                // else go to the next segment for selected direction
            }
        }
        return fits ? randomPoint : null;
    }


    /**
     * place item at the end of the path on each direction
     * @param waypointsMultipleDirections 
     */
    placeCheckpointsOnFinishLine(waypointsMultipleDirections: IWaypointCoordsMulti): ILatLng[] {
        let directionCount: number = waypointsMultipleDirections.waypointArray.length;
        let checkpointList: ILatLng[] = [];
        for (let i = 0; i < directionCount; i++) {
            let selectedWaypoints: ILatLng[] = waypointsMultipleDirections.waypointArray[i].waypoints;
            let checkpoint: ILatLng = Object.assign({}, selectedWaypoints[selectedWaypoints.length - 1]);
            checkpointList.push(checkpoint);
        }
        return checkpointList;
    }


    /**
     * check if the segment is within the heading zone
     * if the heading zone is enabled within the provider
     * @param center 
     * @param point1 
     * @param point2 
     */
    checkSegmentOnHeading(center: ILatLng, point1: ILatLng, point2: ILatLng): boolean {
        let validSegment: boolean = true;
        if (this.headingZone) {
            // if heading zone is enabled, filter the segments that are within the heading zone (90 deg max)
            let heading1: number = GeometryUtils.toDeg(GeometryUtils.computeHeading(center, point1));
            let heading2: number = GeometryUtils.toDeg(GeometryUtils.computeHeading(center, point2));
            let userHeading: number = this.locationMonitor.getAverageHeading().crt;
            // allow only the half that is within the user heading
            let maxDiff: number = 90;
            // userHeading = 90;
            // maxDiff = 10;            
            if (GeometryUtils.getAngularDiff(userHeading, heading1) > maxDiff) {
                validSegment = false;
            }
            if (GeometryUtils.getAngularDiff(userHeading, heading2) > maxDiff) {
                validSegment = false;
            }
            console.log("segment > user heading: " + userHeading + ", heading1: " + heading1 + ", heading2: " + heading2);
        }
        return validSegment;
    }

    /**
     * place coin at the specified location on the map
     * @param coinPos 
     */
    mintCoinCore(
        coinPos: ILatLng,
        specIndex: number,
        customParamCode: number,
        fixedCoinIndex: number,
        locked: boolean,
        show: boolean,
        pc: IPrepareCoinSpecs,
        collectibleParams: IExploreCollectibleParams): Promise<boolean> {

        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
            if (!(cs.coinSpecsArray && (cs.coinSpecsArray.length > 0))) {
                reject(new Error("undefined coin specs"));
                return;
            }

            let objectSpecs: ILeplaceObject = null;
            // round robin approach
            let coinSpecId: number = cs.coinIndexMintCrt % cs.coinSpecsArray.length;
            if (specIndex != null && specIndex < cs.coinSpecsArray.length) {
                coinSpecId = specIndex;
            }
            // objectSpecs = Object.assign({}, cs.coinSpecsArray[coinSpecId]);
            objectSpecs = DeepCopy.deepcopy(cs.coinSpecsArray[coinSpecId]);
            if (customParamCode != null) {
                objectSpecs = DeepCopy.deepcopy(cs.coinSpecsArray.find(cs => cs.customParamCode === customParamCode));
                // objectSpecs = Object.assign({}, cs.coinSpecsArray.find(cs => cs.customParamCode === customParamCode));
            }

            objectSpecs.id = cs.coinIndexMintCrt;
            cs.coinIndexMintCrt += 1;
            objectSpecs.uid = "LOCAL/" + objectSpecs.genericCode + "/" + objectSpecs.id;
            console.log("place coin: " + objectSpecs.uid);

            let fc: IFixedCoin = (cs.fixedCoins && (fixedCoinIndex < cs.fixedCoins.length)) ? cs.fixedCoins[fixedCoinIndex] : null;
            fc.dynamicsOrig = fc.dynamics;

            let myObject: ILeplaceObjectContainer = {
                location: coinPos,
                object: objectSpecs,
                providerCode: EGeoObjectsProviderCode.explore,
                treasure: null,
                dynamic: {
                    distance: null
                },
                minimapLocked: true,
                fixedCoin: fc
            };

            // console.log("place object: ", myObject);
            // extract custom callback data
            // console.log("fixed coin: ", fc, cs.fixedCoins, fixedCoinIndex);
            let cd: ILeplaceObjectCallbackData = objectSpecs.callbackData;
            // console.log("original callback data: ", cd);

            let label: string = null;
            let addLabel: string = objectSpecs.name; // tag
            let circleColor: string = EColorsNames.blue;

            switch (pc.scope) {
                case EExploreCoinScope.collect:
                    label = "<collect>";
                    circleColor = EColorsNames.yellow;
                    break;
                case EExploreCoinScope.target:
                    label = "<target>";
                    circleColor = EColorsNames.green;
                    break;
                case EExploreCoinScope.follow:
                    label = "<follow>";
                    circleColor = EColorsNames.green;
                    break;
                case EExploreCoinScope.escape:
                    label = "<escape>";
                    circleColor = EColorsNames.red;
                    break;
                case EExploreCoinScope.reach:
                    label = "<target>";
                    circleColor = EColorsNames.blue;
                    break;
                case EExploreCoinScope.pickUp:
                    label = "<pickup>";
                    circleColor = EColorsNames.green;
                    break;
                case EExploreCoinScope.container:
                    label = "<pickup>";
                    circleColor = EColorsNames.green;
                    break;
                default:
                    label = "";
                    break;
            }

            if (cd != null) {
                if (fc != null) {
                    if (fc.description != null) {
                        cd.description = fc.description;
                    }
                    if (fc.title != null) {
                        cd.title = fc.title;
                        addLabel = StringUtils.trimName(fc.title, EMessageTrim.markerCaptionCanvas - 1); // override default tag
                    }
                    if (fc.high) {
                        addLabel += "*";
                    }
                    cd.high = fc.high;
                    cd.collectibleParams = collectibleParams;
                }
            }

            let callback = (data: IPlaceMarkerContent) => {
                if (cd != null) {
                    return this.markerCallback(data, cd);
                } else {
                    return objectSpecs.callback;
                }
            };

            let placeMarkerContent: IPlaceMarkerContent = {
                location: coinPos,
                initLocation: coinPos,
                currentLocationCopy: new ILatLng(coinPos.lat, coinPos.lng),
                title: "",
                tempTitle: "",
                tempIcon: objectSpecs.marker,
                icon: objectSpecs.marker,
                data: null,
                radius: this.coinRadius,
                // mode: EMarkerTypes.plain,
                mode: EMarkerTypes.canvasPlainCenter,
                zindex: MarkerPriority.getMarkerPriority(EMarkerPriority.coin),
                priority: EMarkerRenderPriority.coin,
                visible: true,
                layer: EMarkerLayers.COINS,
                shape: EMapShapes.marker,
                callback: callback,
                dragCallback: null,
                locked: locked,
                label: label,
                addLabel: addLabel,
                animate: true,
                uid: objectSpecs.uid,
                drag: false
            };

            myObject.placeMarker = placeMarkerContent;
            myObject.placeMarkerCircle = MarkerUtils.createCircleMarkerCore(coinPos, "item", circleColor, 20, EMarkerLayers.COINS_CIRCLES);
            myObject.placeMarkerCircle.uid = objectSpecs.uid + "/circle";

            this.coinObjects[this.currentContext].push(myObject);
            this.geoObjects.addMapFirstObject(myObject);

            if (show) {
                try {
                    // add marker and show on map
                    this.observable.canClearMap.next(false);
                    this.pendingMarkers += 1;
                    await this.markerHandler.insertArrayMarker(placeMarkerContent, true);
                    //  await this.markerHandler.insertArrayMarker(placeMarkerContentCircle, true);
                    this.pendingMarkers -= 1;
                    if (this.pendingMarkers <= 0) {
                        this.observable.canClearMap.next(true);
                    }
                    resolve(true);
                } catch (err) {
                    console.error(err);
                    this.pendingMarkers -= 1;
                    if (this.pendingMarkers <= 0) {
                        this.observable.canClearMap.next(true);
                    }
                    reject(err);
                }
            } else {
                resolve(true);
            }
        });
        return promise;
    }


    mintPreparedCoins() {
        return new Promise<boolean>(async (resolve, reject) => {
            console.log("mint prepared coins");
            let placeMarkerContents: IPlaceMarkerContent[] = [];
            let placeMarkerCircleContents: IPlaceMarkerContent[] = [];
            for (let co of this.coinObjects[this.currentContext]) {
                placeMarkerContents.push(co.placeMarker);
                placeMarkerCircleContents.push(co.placeMarkerCircle);
            }
            // add marker and show on map
            this.observable.canClearMap.next(false);
            try {
                await this.markerHandler.syncMarkerArray(EMarkerLayers.COINS, placeMarkerContents);
                await this.markerHandler.syncMarkerArray(EMarkerLayers.COINS_CIRCLES, placeMarkerCircleContents);
                this.observable.canClearMap.next(true);
                resolve(true);
            } catch (err) {
                console.error(err);
                this.observable.canClearMap.next(true);
                reject(err);
            }
        });
    }


    /**
     * prepare all coins prior to showing them on the map
     * useful for mp synced coin specs
     * @param center 
     * @param coinCap 
     * @param minRadius 
     * @param maxRadius 
     */
    prepareCoinsRandom(center: ILatLng, coinCap: number, minRadius: number, maxRadius: number, coinScope: number): IPrepareCoinSpecs[] {
        if (!minRadius) {
            minRadius = 0;
        }

        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        if (!(cs.coinSpecsArray && (cs.coinSpecsArray.length > 0))) {
            return null;
        }
        let coinSpecs: IPrepareCoinSpecs[] = [];

        for (let i = 0; i < coinCap; i++) {
            let coinPos: ILatLng;
            if (this.headingZone) {
                coinPos = GeometryUtils.getRandomPointInRadiusWHeading(center, minRadius, maxRadius, this.locationMonitor.getAverageHeading());
            } else {
                coinPos = GeometryUtils.getRandomPointInRadius(center, minRadius, maxRadius);
            }

            // round robin approach
            let coinSpecId: number = cs.coinIndexPrepareCrt % cs.coinSpecsArray.length;

            coinSpecs.push({
                position: coinPos,
                specIndex: coinSpecId,
                scope: coinScope
            });

            cs.coinIndexPrepareCrt += 1;
        }
        cs.targetCoins = coinCap;
        return coinSpecs;
    }

    /**
     * prepare coins with fixed positions
     * @param fixedCoins 
     * @returns 
     */
    prepareCoinsFixed(fixedCoins: IFixedCoin[], coinScopeDefault: number) {
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        if (!(cs.coinSpecsArray && (cs.coinSpecsArray.length > 0))) {
            return null;
        }
        cs.fixedCoins = fixedCoins;
        // console.log("coin specs: ", cs.coinSpecsArray);
        // console.log("fixed coins: ", fixedCoins);
        let coinSpecs: IPrepareCoinSpecs[] = [];
        for (let i = 0; i < fixedCoins.length; i++) {
            let fc: IFixedCoin = fixedCoins[i];
            if (fc != null) {
                let customParamIndex: number = cs.coinSpecsArray.findIndex(cs => (cs != null && cs.customParamCode != null) ? cs.customParamCode === fc.customParamCode : false);
                if (customParamIndex !== -1) {
                    coinSpecs.push(this.prepareCoinCore(fc.lat, fc.lng, fc.dynamics, customParamIndex, i, coinScopeDefault));
                    // console.log("prepare custom coin fixed coin: ", fc, ", index: ", customParamIndex, cs.coinSpecsArray[customParamIndex])
                }
            }
        }
        cs.targetCoins = coinSpecs.length;
        // console.log("prepare coins fixed: ", coinSpecs);
        return coinSpecs;
    }

    prepareCoinCore(lat: number, lng: number, dynamics: number, cparamIndex: number, fcIndex: number, coinScopeDefault: number) {
        let circle: number = EPrepareCoinCircle.none;
        let action: number = dynamics;
        let scope: number = null;

        switch (action) {
            case EExploreObjectDynamics.static:
                scope = EExploreCoinScope.collect;
                circle = EPrepareCoinCircle.collect;
                break;
            case EExploreObjectDynamics.staticTarget:
                scope = EExploreCoinScope.target;
                circle = EPrepareCoinCircle.collect;
                break;
            case EExploreObjectDynamics.moveTowardsUser:
                scope = EExploreCoinScope.escape;
                circle = EPrepareCoinCircle.avoid;
                break;
            case EExploreObjectDynamics.moveAwayFromUser:
                scope = EExploreCoinScope.follow;
                circle = EPrepareCoinCircle.follow;
                break;
            case EExploreObjectDynamics.pickUp:
                scope = EExploreCoinScope.pickUp;
                circle = EPrepareCoinCircle.follow;
                break;
            case EExploreObjectDynamics.container:
                scope = EExploreCoinScope.container;
                circle = EPrepareCoinCircle.follow;
                break;
            default:
                scope = EExploreCoinScope.collect;
                circle = EPrepareCoinCircle.collect;
                break;
        }
        if (scope == null) {
            scope = coinScopeDefault;
        }
        let pcs: IPrepareCoinSpecs = {
            position: new ILatLng(lat, lng),
            specIndex: cparamIndex,
            fixedCoinIndex: fcIndex,
            scope: scope, // for label purpose
            // label: this.getCoinCollectActionTypeCore(fc, null) === EExploreCoinAction.collect ? "<collect>" : coinLabelDefault,
            circle: circle
        };
        return pcs;
    }

    /**
     * generates all coins with random time delay and random position
     * with predefined directions
     * @param initialLocation 
     * @param radius 
     * @param waypointsMultipleDirections 
     * @param coinGenerateTime 
     * @param coinCap 
     * @param delayArray 
     * @param delayAddMs 
     */
    prepareCoinsWithDirectionsMultiple(center: ILatLng, coinCap: number, minRadius: number, radius: number, waypointsMultipleDirections: IWaypointCoordsMulti, coinScope: number): IPrepareCoinSpecs[] {
        let coinSpecs: IPrepareCoinSpecs[] = [];
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        if (!coinCap) {
            // walkTarget/runTarget
            let coinPosList: ILatLng[] = this.placeCheckpointsOnFinishLine(waypointsMultipleDirections);
            for (let i = 0; i < coinPosList.length; i++) {
                let coinPos: ILatLng = coinPosList[i];
                // round robin approach
                let coinSpecId: number = cs.coinIndexPrepareCrt % cs.coinSpecsArray.length;
                coinSpecs.push({
                    position: coinPos,
                    specIndex: coinSpecId
                });
                cs.coinIndexPrepareCrt += 1;
            }
        } else {
            let coinIndexPosition: number = null;
            for (let i = 0; i < coinCap; i++) {
                let coinPos: ILatLng;
                try {
                    if (coinCap && coinIndexPosition) {
                        coinPos = this.getCheckpointOnDirections(center, radius, minRadius, waypointsMultipleDirections, coinCap, coinIndexPosition);
                    } else {
                        coinPos = this.getRandomPointOnDirections(center, radius, minRadius, waypointsMultipleDirections);
                    }
                } catch (err) {
                    console.error(err);
                }
                // round robin approach
                let coinSpecId: number = cs.coinIndexPrepareCrt % cs.coinSpecsArray.length;
                coinSpecs.push(this.prepareCoinCore(coinPos.lat, coinPos.lng, null, coinSpecId, null, coinScope));
                cs.coinIndexPrepareCrt += 1;
            }
        }
        cs.targetCoins = coinCap;
        return coinSpecs;
    }


    /**
     * generates all coins with random time delay and random position
     * with predefined directions
     * @param initialLocation 
     * @param radius max radius/distance to target
     * @param waypoints 
     * @param coinGenerateTime 
     * @param coinCap 
     * @param delayArray 
     * @param delayAddMs 
     */
    prepareCoinsOnWaypoints(center: ILatLng, coinCap: number, radius: number, waypoints: ILatLng[], coinScope: number): IPrepareCoinSpecs[] {
        let coinSpecs: IPrepareCoinSpecs[] = [];
        let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
        for (let i = 0; i < coinCap; i++) {
            let coinPos: ILatLng;
            try {
                coinPos = this.getCheckpointOnWaypoints(center, radius, waypoints, coinCap, i);
            } catch (err) {
                console.error(err);
            }
            // round robin approach
            let coinSpecId: number = cs.coinIndexPrepareCrt % cs.coinSpecsArray.length;
            coinSpecs.push(this.prepareCoinCore(coinPos.lat, coinPos.lng, null, coinSpecId, null, coinScope));
            cs.coinIndexPrepareCrt += 1;
        }
        cs.targetCoins = coinCap;
        return coinSpecs;
    }

    /**
    * the waypoint array index given by coin index and coin cap
    * @param center 
    * @param radius max radius/distance to target
    * @param waypoints 
    * @param coinCap 
    * @param coinIndexPosition 
    */
    getCheckpointOnWaypoints(center: ILatLng, radius: number, waypoints: ILatLng[], coinCap: number, coinIndexPosition: number) {
        if (!coinCap) {
            return null;
        }
        // divide total distance by number of coins
        // multiply by coin index to get place distance of current coin
        let placeDistance: number = (radius / coinCap) * (coinIndexPosition + 1);
        return this.getPointOnDirection(center, waypoints, placeDistance, false);
    }


    /**
     * collect coin and gray out instead of removing from the map
     * @param object
     * @param removeFromARBuffer 
     * @returns 
     */
    collectCoinGrayOut(object: ILeplaceObjectContainer, removeFromARBuffer: boolean): Promise<boolean> {
        return new Promise(async (resolve) => {
            let layer: string = EMarkerLayers.COINS;
            let mk: IPlaceMarkerContent = this.markerHandler.getMarkerByUid(layer, object.object.uid);
            console.log("collect coin gray out: ", mk);
            if (!mk) {
                console.warn("marker not found");
                resolve(false);
                return;
            }
            if (object.collected) {
                console.warn("already collected");
                resolve(false);
                return;
            }
            object.collected = true;
            if (removeFromARBuffer) {
                mk.requiresRefresh = true;
                mk.locked = true;
                mk.animate = false;
                mk.label = "(collected)";
                mk.title = "(collected)";
                PromiseUtils.wrapNoAction(this.markerHandler.updateArrayMarkerCore(layer, mk), true);
            }

            this.setAppContext(mk, {
                inRange: true,
                collected: true,
                disabled: null
            });
            if (mk.appContext != null && (!mk.appContext.disabled || mk.appContext.collectedNow)) {
                mk.appContext.collectedNow = false;
                await this.applyCollectedCoin(object, mk);
            }
            resolve(true);
        });
    }

    async applyCollectedCoinNow(object: ILeplaceObjectContainer) {
        let layer: string = EMarkerLayers.COINS;
        let mk: IPlaceMarkerContent = this.markerHandler.getMarkerByUid(layer, object.object.uid);
        if (mk != null && mk.appContext != null) {
            mk.appContext.collectedNow = true;
            if (!mk.appContext.disabled || mk.appContext.collectedNow) {
                await this.applyCollectedCoin(object, mk);
            }
        }
    }

    /**
     * set picked up coin state and set highlight
     * @param object 
     * @param removeFromARBuffer 
     * @returns 
     */
    setCoinPickedUpState(object: ILeplaceObjectContainer, pickUp: boolean): Promise<boolean> {
        return new Promise(async (resolve) => {
            this.coinLock = true;
            let layer: string = EMarkerLayers.COINS;
            let mk: IPlaceMarkerContent = this.markerHandler.getMarkerByUid(layer, object.object.uid);
            console.log("pick up coin highlight: ", mk);
            if (!mk) {
                console.warn("marker not found");
                resolve(false);
                return;
            }
            if (object.collected) {
                console.warn("already collected");
                resolve(false);
                return;
            }
            object.pickedUp = pickUp;
            mk.requiresRefresh = true;
            mk.locked = false;
            mk.animate = false;
            mk.label = pickUp ? "(picked up)" : "(dropped)";
            mk.title = pickUp ? "(picked up)" : "(dropped)";
            await PromiseUtils.wrapResolve(this.markerHandler.updateArrayMarkerCore(layer, mk), true);
            this.setAppContext(mk, {
                inRange: true,
                collected: false,
                disabled: null
            });
            this.coinLock = false;
            resolve(true);
        });
    }

    /**
     * interact with coin
     * collect or pick up
     * @param object 
     * @param index 
     * @param removeFromARBuffer 
     * @returns 
     */
    collectCoinWrapper(object: ILeplaceObjectContainer, index: number, removeFromARBuffer: boolean): Promise<boolean> {
        switch (object?.fixedCoin?.dynamics) {
            case EExploreObjectDynamics.pickUp:
            case EExploreObjectDynamics.container:
                return this.pickUpCoin(object);
            default:
                return this.collectCoin(object, index, removeFromARBuffer);
        }
    }

    checkCollectAvailable(object: ILeplaceObjectContainer) {
        if (object.collected) {
            console.warn("already collected");
            return false;
        }
        if (object.pickedUp) {
            console.warn("already picked up");
            return false;
        }
    }

    /**
     * remove coin after user collect
     * to be called by user (coin magnet) directly
     * @param index 
     */
    collectCoin(object: ILeplaceObjectContainer, index: number, removeFromARBuffer: boolean): Promise<boolean> {
        return new Promise(async (resolve) => {
            let layer: string = EMarkerLayers.COINS;

            // check if there is a pickup or container object that was not collected, and prevent the player from directly collecting them in this case
            if (this.hasPickupObjectsNotUsed()) {
                this.uiext.showAlertNoAction(Messages.msg.coinUnavailableToCollect.after.msg, Messages.msg.coinUnavailableToCollect.after.sub, false);
                resolve(false);
                return;
            }

            if (this.keepCollectedCoins) {
                let res: boolean = await this.collectCoinGrayOut(object, removeFromARBuffer);
                resolve(res);
                return;
            }
            if (object.collected) {
                console.warn("already collected");
                resolve(false);
                return;
            }
            object.collected = true;
            let mk: IPlaceMarkerContent = this.markerHandler.getMarkerByUid(layer, object.object.uid);
            console.log("collect coin: ", mk);
            if (!mk) {
                console.warn("marker not found");
                resolve(false);
                return;
            }
            this.markerHandler.clearMarkerByUid(layer, object.object.uid);
            this.coinObjects[this.currentContext].splice(index, 1);
            if (removeFromARBuffer) {
                this.geoObjects.removeMapFirstObjectByUid(object.object.uid, true);
            }
            await this.applyCollectedCoin(object, mk);
            resolve(true);
        });
    }

    pickUpCoin(object: ILeplaceObjectContainer): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (object.collected) {
                console.warn("already collected");
                resolve(false);
                return;
            }
            if (object.pickedUp) {
                console.warn("already picked up");
                resolve(false);
                return;
            }
            let res: boolean = await this.setCoinPickedUpState(object, true);
            this.updateCoinPosition(object, GeometryUtils.getPointOnHeading(object.location, EDefaultDistanceSpecs.pickupObjectRelativeDistance, 0));
            this.register({
                index: null,
                value: 1,
                amount: 1,
                action: EExploreCoinAction.pickup,
                list: [{ elem: object, type: ENearbyContentType.coin }]
            });
            resolve(res);
        });
    }

    dropOffCoin(object: ILeplaceObjectContainer, loc: ILatLng): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (object.collected) {
                console.warn("already collected");
                resolve(false);
                return;
            }
            let overlapsWithTarget: boolean = false;
            // check dropoff overlaps with target
            let coins: ILeplaceObjectContainer[] = this.getCoinObjects();
            console.log("check overlaps with: ", coins);
            console.log("dynamics: ", coins.map(c => c?.fixedCoin?.dynamics));
            console.log("dynamics orig: ", coins.map(c => c?.fixedCoin?.dynamicsOrig));
            coins = coins.filter(c => c != null);

            let canDropOff: boolean = true;

            for (let i = 0; i < coins.length; i++) {
                let c: ILeplaceObjectContainer = coins[i];
                if (c.object.uid === object.object.uid) {
                    console.log("skip same object: ", c);
                    continue;
                }
                let collectRadius: number = this.getCollectRadiusCheckOverride();
                let overlappingObjects: boolean = this.checkOverlap(c, object, collectRadius);
                console.log("overlapping objects: ", overlappingObjects);
                let distanceToObject: number = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, c.location, Number.MAX_VALUE);
                if (distanceToObject < collectRadius) {
                    console.log("distance to target reached");
                    if (!overlappingObjects) {
                        // move object to target overlap
                        this.updateCoinPosition(object, GeometryUtils.getPointOnHeading(c.location, collectRadius / 2, 0));
                        console.log("move picked up object to target");
                        overlappingObjects = this.checkOverlap(c, object, collectRadius);
                    }
                }
                if (overlappingObjects) {
                    console.log("picked up object overlaps with target");
                    console.log("object dynamics: ", object.fixedCoin?.dynamics);
                    console.log("target dynamics: ", c.fixedCoin?.dynamics);
                    switch (object.fixedCoin?.dynamics) {
                        case EExploreObjectDynamics.pickUp:
                            // drop off at any target (i.e. static target)
                            if (c.fixedCoin?.dynamics === EExploreObjectDynamics.staticTarget) {
                                overlapsWithTarget = true;
                                await this.collectCoinGrayOut(c, true); // collect target
                                await this.collectCoinGrayOut(object, true); // collect container/dropoff
                                object.pickedUp = false;
                                c.fixedCoin != null ? c.fixedCoin.dynamics = EExploreObjectDynamics.static : null;
                                object.fixedCoin != null ? object.fixedCoin.dynamics = EExploreObjectDynamics.static : null;
                                await this.applyCollectedCoinNow(c);
                                await this.applyCollectedCoinNow(object);
                                let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
                                this.register({
                                    index: null,
                                    value: cs.collectedCoinsValue,
                                    amount: cs.collectedCoins,
                                    action: EExploreCoinAction.collect
                                });
                            }
                            break;
                        case EExploreObjectDynamics.container:
                            // collect any collectible item (not pickup/container)
                            if (!this.isPickUpTypeDynamics(c.fixedCoin?.dynamics)) {
                                canDropOff = false;
                                overlapsWithTarget = true;
                                await this.collectCoinGrayOut(c, true); // collect target
                                c.fixedCoin != null ? c.fixedCoin.dynamics = EExploreObjectDynamics.static : null;
                                await this.applyCollectedCoinNow(c);
                                // check all targets collected
                                let allTargetsCollected: boolean = true;
                                for (let c2 of coins) {
                                    if (c2.object.uid === object.object.uid) {
                                        continue;
                                    }
                                    if (!c2.collected && !this.isPickUpTypeDynamics(c2.fixedCoin?.dynamics)) {
                                        allTargetsCollected = false;
                                        break;
                                    }
                                }
                                if (allTargetsCollected) {
                                    // if all targets have been collected, collect the container item
                                    await this.collectCoinGrayOut(object, true); // collect container/dropoff
                                    object.pickedUp = false;
                                    object.fixedCoin != null ? object.fixedCoin.dynamics = EExploreObjectDynamics.static : null;
                                    await this.applyCollectedCoinNow(object);
                                    canDropOff = true;
                                }

                                let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
                                this.register({
                                    index: null,
                                    value: cs.collectedCoinsValue,
                                    amount: cs.collectedCoins,
                                    action: EExploreCoinAction.collect
                                });
                            }
                            break;
                        default:
                            break;
                    }

                }
            }
            let res: boolean = false;
            if (canDropOff) {
                this.register({
                    index: null,
                    value: 1,
                    amount: 1,
                    action: EExploreCoinAction.dropOff,
                    list: [{ elem: object, type: ENearbyContentType.coin }]
                });
                if (overlapsWithTarget) {
                    res = true;
                } else {
                    res = await this.setCoinPickedUpState(object, false);
                    this.updateCoinPosition(object, GeometryUtils.getPointOnHeading(object.location, -EDefaultDistanceSpecs.pickupObjectRelativeDistance, 0));
                }
            }
            resolve(res);
        });
    }

    /**
     * register collected coin stats w/ apply
     * @param object 
     * @param mk 
     * @returns 
     */
    applyCollectedCoin(object: ILeplaceObjectContainer, mk: IPlaceMarkerContent) {
        return new Promise(async (resolve) => {
            let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
            cs.collectedCoins += 1;
            let coinValue: number = object.object.customParamValue != null ? object.object.customParamValue : AppConstants.gameConfig.coinValue;
            console.log("collected coin value: ", coinValue);
            cs.collectedCoinsValue += coinValue;
            cs.collectedCoinsList.push(object.object);
            // handle object data
            console.log("collected object: ", object);
            let high: boolean = false;
            if (object.fixedCoin) {
                if (object.fixedCoin.high) {
                    console.log("collected coin w/ highlight");
                    high = true;
                }
            }
            if (high) {
                if (mk.appContext) {
                    mk.appContext.collected = true;
                    mk.appContext.collectedNow = true;
                    mk.appContext.disabled = true; // already collected before this was called
                }
                await this.markerUtils.showContextMarkerModal(mk, object.object.callbackData);
            }
            resolve(true);
        });
    }

    getCoinObjectsInRange() {
        return this.coinObjectsInRange[this.currentContext];
    }

    setAppContext(mk: IPlaceMarkerContent, appContext: IMarkerDetailsAppContext) {
        if (!mk.appContext) {
            mk.appContext = {
                collected: appContext.collected,
                inRange: appContext.inRange,
                disabled: appContext.disabled,
                mode: appContext.mode
            };
        } else {
            mk.appContext.inRange = appContext.inRange != null ? appContext.inRange : mk.appContext.inRange;
            mk.appContext.collected = appContext.collected != null ? appContext.collected : mk.appContext.collected;
            mk.appContext.mode = appContext.mode != null ? appContext.mode : mk.appContext.mode;
        }
    }

    checkOverlap(coin: ILeplaceObjectContainer, coin2: ILeplaceObjectContainer, collectDistance: number) {
        console.log("check overlap: ", coin, coin2);
        if (coin.collected || coin2.collected) {
            return false;
        }
        let distanceToObject: number = GeometryUtils.getDistanceBetweenEarthCoordinates(coin.location, coin2.location, Number.MAX_VALUE);
        if (distanceToObject < collectDistance) {
            console.log("overlap");
            return true;
        }
        return false;
    }

    /**
    * check coins collected/got caught 
    * core function
    * @param params 
    */
    checkCollect(collectDistance: number, coinCap: number, evadeRadius: number, currentLocation: ILatLng, generateComplete: boolean): Promise<IExploreCheckCollectReturn> {
        return new Promise(async (resolve) => {
            let res: IExploreCheckCollectReturn = {
                minDistanceToAnyObject: null,
                maxDistanceToAnyObject: null
            };

            let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];

            if (!currentLocation) {
                resolve(res);
                return;
            }

            if (this.collectLock) {
                resolve(res);
                return;
            }

            let evaded: boolean = true;
            res.minDistanceToAnyObject = Number.MAX_VALUE;
            res.maxDistanceToAnyObject = 0;

            if (this.coinObjects[this.currentContext].length === 0) {
                res.minDistanceToAnyObject = null;
                res.maxDistanceToAnyObject = null;
            }

            this.coinObjectsInRange[this.currentContext] = this.coinObjects[this.currentContext].filter(coin => {
                if (coin.collected) {
                    return false;
                }
                let distanceToObject: number = GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, coin.location, Number.MAX_VALUE);

                if (distanceToObject < res.minDistanceToAnyObject) {
                    res.minDistanceToAnyObject = distanceToObject;
                }
                if (distanceToObject > res.maxDistanceToAnyObject) {
                    res.maxDistanceToAnyObject = distanceToObject;
                }

                // check evade distance
                if (evadeRadius) {
                    if (distanceToObject < evadeRadius) {
                        evaded = false;
                    }
                } else {
                    evaded = false;
                }

                if (distanceToObject < collectDistance) {
                    return true;
                }
                return false;
            });

            let coinsInRange: ILeplaceObjectContainer[] = this.coinObjectsInRange[this.currentContext];

            // compute in range flag
            for (let coin of this.coinObjects[this.currentContext]) {
                this.setAppContext(coin.placeMarker, {
                    inRange: false,
                    mode: this.getCoinCollectActionType(coin, evadeRadius != null ? EExploreCoinAction.overlap : EExploreCoinAction.collect)
                });
            }

            for (let coin of coinsInRange) {
                this.setAppContext(coin.placeMarker, {
                    inRange: true,
                    mode: this.getCoinCollectActionType(coin, evadeRadius != null ? EExploreCoinAction.overlap : EExploreCoinAction.collect)
                });
            }

            let checkCoinsInRange = async (autoCollect: boolean) => {
                for (let i = 0; i < coinsInRange.length; i++) {
                    let cir: ILeplaceObjectContainer = coinsInRange[i];
                    // check collect distance (implicit)
                    let action: number = this.getCoinCollectActionType(cir, evadeRadius != null ? EExploreCoinAction.overlap : EExploreCoinAction.collect);
                    let apply: boolean = false;
                    switch (action) {
                        case EExploreCoinAction.pickup:
                            if (autoCollect) {
                                apply = await this.pickUpCoin(cir);
                            }
                            break;
                        default:
                            if (autoCollect) {
                                apply = await this.collectCoin(cir, i, true);
                            }
                            break;
                    }
                    console.log("collect coin action: " + action + " apply: " + apply);
                    if (apply) {
                        this.register({
                            index: null,
                            value: cs.collectedCoinsValue,
                            amount: cs.collectedCoins,
                            action: action
                        });
                    }
                }
            };

            if (coinsInRange.length > 0) {
                if (this.autoCollect || (this.enableAutoCollectOverride && this.autoCollectOverride)) {
                    for (let i = 0; i < coinsInRange.length; i++) {
                        checkCoinsInRange(true);
                        if (cs.collectedCoins >= coinCap) {
                            // this.observables.next(-1); // finish activity, collected all coins
                            break;
                        }
                    }
                } else {
                    checkCoinsInRange(false);
                    // check collect objects (different from pickup)
                    let objectsCollect = this.getCoinObjectsInRange().filter(coin => !this.isPickUpTypeDynamics(coin?.fixedCoin?.dynamics)).map(coinObject => {
                        let obj: INearbyContentMagnetElem = {
                            elem: coinObject,
                            type: ENearbyContentType.coin
                        };
                        return obj;
                    });
                    // check pickup objects
                    let objectsPickup = this.getCoinObjectsInRange().filter(coin => this.isPickUpTypeDynamics(coin?.fixedCoin?.dynamics)).map(coinObject => {
                        let obj: INearbyContentMagnetElem = {
                            elem: coinObject,
                            type: ENearbyContentType.coin
                        };
                        return obj;
                    });
                    // register objects
                    if (objectsCollect.length > 0) {
                        this.register({
                            index: null,
                            value: cs.collectedCoinsValue,
                            amount: cs.collectedCoins,
                            action: EExploreCoinAction.collectAvailable,
                            list: objectsCollect
                        });
                    }
                    if (objectsPickup.length > 0) {
                        this.register({
                            index: null,
                            value: cs.collectedCoinsValue,
                            amount: cs.collectedCoins,
                            action: EExploreCoinAction.pickupAvailable,
                            list: objectsPickup
                        });
                    }
                }
            } else {
                this.register({
                    index: null,
                    value: cs.collectedCoinsValue,
                    amount: cs.collectedCoins,
                    action: EExploreCoinAction.collectNotAvailable
                });
            }

            if (evaded && generateComplete) {
                // check min time elapsed
                this.register({
                    index: null,
                    amount: null,
                    action: EExploreCoinAction.evadeComplete
                });
            }

            resolve(res);
        });
    }

    isPickUpTypeDynamics(dynamics: number) {
        return [EExploreObjectDynamics.pickUp, EExploreObjectDynamics.container].indexOf(dynamics) !== -1;
    }

    /**
     * check if user has picked up objects
     * @param loc 
     * @returns 
     */
    hasPickedUpObjects() {
        let has: boolean = false;
        let coins: ILeplaceObjectContainer[] = this.getCoinObjects();
        for (let i = 0; i < coins.length; i++) {
            let c: ILeplaceObjectContainer = coins[i];
            if (c.pickedUp) {
                has = true;
                break;
            }
        }
        console.log("has picked up objects: ", has);
        return has;
    }

    /**
     * check if there are any pickup type objects that were not collected/used
     * @returns 
     */
    hasPickupObjectsNotUsed() {
        let has: boolean = false;
        let coins: ILeplaceObjectContainer[] = this.getCoinObjects();
        for (let i = 0; i < coins.length; i++) {
            let c: ILeplaceObjectContainer = coins[i];
            // check original dynamics
            if (this.isPickUpTypeDynamics(c?.fixedCoin?.dynamics) && !c.collected) {
                has = true;
                break;
            }
        }
        console.log("has pickup objects not used: ", has);
        return has;
    }

    /**
     * check if there are any pickup/container objects in the challenge
     * @returns 
     */
    hasPickupOrContainerObjects() {
        let has: boolean = false;
        let coins: ILeplaceObjectContainer[] = this.getCoinObjects();
        for (let i = 0; i < coins.length; i++) {
            let c: ILeplaceObjectContainer = coins[i];
            if (this.isPickUpTypeDynamics(c?.fixedCoin?.dynamics)) {
                has = true;
                break;
            }
        }
        console.log("has pickup or container objects: ", has);
        return has;
    }

    /**
     * check static target object 
     */
    checkStaticTarget(): ILatLng {
        if (this.coinObjects[this.currentContext].length === 0) {
            return null;
        }
        // check static target
        for (let co of this.coinObjects[this.currentContext]) {
            if (co.fixedCoin?.dynamics === EExploreObjectDynamics.staticTarget) {
                return new ILatLng(co.fixedCoin.lat, co.fixedCoin.lng);
            }
        }
        // default to first static object
        for (let co of this.coinObjects[this.currentContext]) {
            if ((co.fixedCoin?.dynamics === EExploreObjectDynamics.static) || (co.fixedCoin?.dynamics == null)) {
                return new ILatLng(co.fixedCoin.lat, co.fixedCoin.lng);
            }
        }
        return null;
    }

    getCoinCollectActionType(cir: ILeplaceObjectContainer, defaultAction: number) {
        let action: number = EExploreCoinAction.collect; // default for explore challenge       
        if (!cir) {
            return action;
        }
        return this.getCoinCollectActionTypeCore(cir.fixedCoin, defaultAction);
    }

    getCoinCollectActionTypeCore(fc: IFixedCoin, defaultAction: number) {
        let action: number = EExploreCoinAction.collect; // default for explore challenge
        if (fc != null) {
            // fixed coins (may be moving or fixed)
            if (fc.dynamics == null) {
                action = EExploreCoinAction.collect; // reached fixed target
            } else {
                switch (fc.dynamics) {
                    case EExploreObjectDynamics.static:
                        action = EExploreCoinAction.collect; // reached target
                        break;
                    case EExploreObjectDynamics.staticTarget:
                        action = EExploreCoinAction.dropOff; // reached target / drop off zone
                        break;
                    case EExploreObjectDynamics.moveTowardsUser:
                        action = EExploreCoinAction.overlap; // got caught by moving object
                        break;
                    case EExploreObjectDynamics.moveAwayFromUser:
                        action = EExploreCoinAction.collect; // reached moving target
                        break;
                    case EExploreObjectDynamics.pickUp:
                        action = EExploreCoinAction.pickup; // pick up target object to move somewhere else
                        break;
                    case EExploreObjectDynamics.container:
                        action = EExploreCoinAction.pickup; // also pick up target object to collect other items with
                        break;
                    default:
                        break;
                }
            }
        } else {
            // random coins (moving subjects by default)
            if (defaultAction != null) {
                action = defaultAction;
            }
        }
        return action;
    }

    getCoinMoveType(cir: ILeplaceObjectContainer, defaultMode: number) {
        let action: number = defaultMode; // default mode
        if (cir.fixedCoin != null) {
            // fixed coins (may be moving or fixed)
            if (cir.fixedCoin.dynamics == null) {
                action = EExploreObjectDynamics.static; // reached fixed target
            } else {
                action = cir.fixedCoin.dynamics;
            }
        } else {
            // random coins (moving subjects by default)
            action = defaultMode // default mode
        }
        return action;
    }


    getPreparedSyncCoins(syncData: ICoinSpecsMpSyncData) {
        let preparedCoinSpecs: IPrepareCoinSpecs[] = [];
        if (!syncData) {
            return preparedCoinSpecs;
        }
        if ((syncData.coins != null) && (syncData.coins.length > 0)) {
            // directly prepared
            preparedCoinSpecs = syncData.coins;
        } else {
            if ((syncData.fixedCoins != null) && (syncData.fixedCoins.length > 0)) {
                // from fixed coins
                preparedCoinSpecs = syncData.fixedCoins.map(fc => {
                    let pc: IPrepareCoinSpecs = {
                        position: new ILatLng(fc.lat, fc.lng),
                        specIndex: null,
                        customParamCode: fc.customParamCode
                    };
                    return pc;
                });
            }
        }
        console.log("sync data: ", syncData);
        console.log("prepared coins: ", preparedCoinSpecs);
        return preparedCoinSpecs;
    }


    /**
     * clear coins on the map and AR (via geo objects)
     */
    async clearCoins(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            let cs: ILeplaceExploreContextStats = this.exploreStats[this.currentContext];
            for (let key of Object.keys(this.coinObjects)) {
                this.coinObjects[key] = [];
                this.coinObjectsInRange[key] = [];
            }
            await this.markerHandler.clearMarkersResolve(EMarkerLayers.COINS);
            this.markerHandler.clearMarkerLayer(EMarkerLayers.COINS);
            await this.markerHandler.clearMarkersResolve(EMarkerLayers.COINS_CIRCLES);
            this.markerHandler.clearMarkerLayer(EMarkerLayers.COINS_CIRCLES);

            if (cs.coinSpecsArray && (cs.coinSpecsArray.length > 0)) {
                for (let i = 0; i < cs.coinSpecsArray.length; i++) {
                    this.geoObjects.removeMapFirstObjectsByType(cs.coinSpecsArray[i].genericCode);
                }
            }
            cs.coinIndexMintCrt = 0;
            cs.coinIndexPrepareCrt = 0;
            cs.fixedCoins = [];
            resolve(true);
        });
        return promise;
    }


    async checkClearMap(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            this.subscription.canClearMap = ResourceManager.clearSub(this.subscription.canClearMap);
            // wait until the current markers have been placed
            // so that there will be no outstanding markers after cleanup
            if (this.pendingMarkers > 0) {
                this.subscription.canClearMap = this.observable.canClearMap.subscribe(async (canClear: boolean) => {
                    console.log("can clear: " + canClear);
                    if (canClear) {
                        resolve(true);
                    }
                }, (err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                console.log("can clear/no pending markers");
                resolve(true);
            }
        });
        return promise;
    }

}


