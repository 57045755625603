import { IGameItem } from '../items/game-item';
import { IUserLocationData, IWaypointCoordsMulti } from '../map/map-data';
import { IPrepareCoinSpecs } from '../core/objects';

import { IFixedCoin } from '../core/custom-param';
import { ILatLng } from '../map/coords';
import { INearbyContentMagnetElem } from '../map/gmap-utils';


export enum EExploreModes {
    free = 0,
    guided = 1,
    fixed = 2
}

export interface IFindActivityInit {
    timeLimit: number;
    startTime: number;
}

export interface IExploreItemParams {
    coinCap: number,
    collectDistance: number,
    fixedCoinCap?: boolean
}

export interface IExploreActivityInit extends IExploreItemParams {
    timeLimit: number;
    startTime: number;
    // null coin cap - target object at the end of the line
    coinRadius: number;
    exploreMode: number;
    useCheckpoints: boolean;
    activeInventoryItems: IGameItem[];
    currentLocation: ILatLng;
    placeLocation?: ILatLng;
    minCollectedCoins: number;
    // run-x
    singleTarget: boolean;
    // used for dynamic objects e.g. starting point for evade activity
    minRadius: number;
    // used for dynamic objects e.g. speed of objects moving on the map
    targetSpeed: number;
    objectDynamics: number;
    // distance for which the activity is completed (evade complete, relative to all subjects) or failed (subject escaped, relative to any subject)
    evadeRadius: number;
    // aux params
    directionsMode?: number;
    randomCoins: boolean;
    // check if activity can be completed after collecting required coins
    isMainObjective: boolean;
    // coin specs pre-synced from MP
    syncData: ICoinSpecsMpSyncData;
    // enable publishing sync data to mp
    publishSyncData: boolean;
    collectGauge: boolean;
    timeGauge: boolean;
    evadeGauge: boolean;
    fixedCoins?: IFixedCoin[]
    fixedCoinCap: boolean;
    coinScope?: number;
    extras?: IExploreCollectibleParams;
}

export interface IExploreCollectibleParams {
    mode: number;
    lang: string;
    voice: string;
}

export interface IExploreFindActivityInit extends IExploreItemParams {
    initialLocation: ILatLng;
    // coinCap: number;
    circleRadius: number;
    target: ILatLng;
    currentLocation: IUserLocationData;
    activeInventoryItems: IGameItem[];
    circleOffsetCenter: ILatLng;
    // coin specs pre-synced from MP
    syncData: ICoinSpecsMpSyncData;
    // enable publishing sync data to mp
    publishSyncData: boolean;
}

export interface ICoinSpecsMpSyncData {
    coins: IPrepareCoinSpecs[],
    fixedCoins?: IFixedCoin[],
    waypoints: IWaypointCoordsMulti
}

export interface IExploreActivityParams2 {
    delayArray: number[];
    delayAdd: number;
    coinGenerateTime: number;
}

export enum EExploreObjectDynamics {
    static = 0,
    moveTowardsUser = 1,
    moveAwayFromUser = 2,
    moveOnDirections = 3,
    staticTarget = 10,
    pickUp = 11,
    container = 12
}

export interface IExploreCoinGen {
    index: number;
    amount: number;
    value?: number;
    target?: number;
    action: number;
    list?: INearbyContentMagnetElem[];
}

export enum EExploreCoinAction {
    create = 1,
    update = 2,
    collect = 3,
    exception = 4,
    generateComplete = 5,
    evadeComplete = 6,
    finished = 7,
    collectAvailable = 8,
    collectNotAvailable = 9,
    overlap = 10,
    escapeMinTimeLimit = 11,
    pickup = 12,
    dropOff = 13,
    pickupAvailable = 14,
    pickupNotAvailable = 15
}

export enum EExploreCoinScope {
    collect = 1,
    escape = 2,
    follow = 3,
    reach = 4,
    pickUp = 5,
    container = 6,
    target = 7
}

export enum EExploreContext {
    explore = "EXPLORE",
    nav = "NAV"
}

export interface IExploreActivityStatus {
    distanceTravelled: number;
    lastPosition: ILatLng;
    generateComplete: boolean;
    chartInitLocation: ILatLng;
    /** check evade distance relative to this calculated value */
    minDistanceToAnyObjectInit: number;
    minDistanceToAnyObject: number;
    maxDistanceToAnyObject: number;
    /** check evade distance relative to min distance init and coinRadius */
    evadeRadiusComputed: number;
}

