import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { EUnchartedMode, IAppLocation } from 'src/app/classes/def/places/app-location';
import { IActivity, EActivityCodes, IDanceActivityDef, IDecibelActivityDef, ECheckActivityResult, IActivityIcon, IActivityIcons } from 'src/app/classes/def/core/activity';
import { EImgIcons, EAppIcons, EAppIconsStandard, IIconSpec, EPhotos } from 'src/app/classes/def/app/icons';
import { ILocationContainer, ILocationItemsDef, IBackendLocation, IUserStoryLocation } from 'src/app/classes/def/places/backend-location';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IActivityProviderContainer, IActivityValidateStatus, IActivityButtonsEnable, IActivityButtonsDtreeFlags, EScanCodeMode } from 'src/app/classes/def/core/activity-manager';
import { IDynamicParamActivityParam, IActivityParamsView, IActivityQuestSpecs, IActivityQuestResponse, IActivityPhotoSpecs } from 'src/app/classes/def/nav-params/activity-details';
import { IBusinessFeaturesEnabled } from 'src/app/classes/def/business/business';
import { BehaviorSubject } from 'rxjs';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IGmapNavParams, IGmapDetailReturnParams, EGmapDetailReturnCode, IGmapEntryNavParams, EGmapMode, IGmapActivityStartOptions } from 'src/app/classes/def/nav-params/gmap';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { Util } from 'src/app/classes/general/util';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';
import { ECustomParamScope } from 'src/app/classes/def/core/custom-param';
import { Messages } from 'src/app/classes/def/app/messages';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAlertButtonCodes, IButtonOptions } from 'src/app/classes/def/app/ui';
import { EMessageTrim, EMessageTrimLines, IShareMsgParams } from 'src/app/classes/utils/message-utils';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { StoryDataService } from 'src/app/services/data/story';
import { TimeoutService } from 'src/app/services/app/modules/timeout';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { ActivityService, EExploreMoveStat } from 'src/app/services/app/modules/activity';
import { ChallengeEntryService } from 'src/app/services/app/modules/challenge-entry';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { EStoryMode, IStory, ITreasureStoryLocation } from 'src/app/classes/def/core/story';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { ISelectPhotoOptions } from 'src/app/services/location/location-utils-def';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { Router } from '@angular/router';
import { EQuestAction, IQuestAction } from 'src/app/classes/def/activity/quest';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPhotoResultResponse } from 'src/app/classes/def/media/processing';
import { OtherUtils } from 'src/app/services/utils/other-utils';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { ActivitiesDataService, EDroneMode, IActivityTutorialContainer } from 'src/app/services/data/activities';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { GameStatsService } from 'src/app/services/app/utils/game-stats';
import { NavigationHandlerService } from 'src/app/services/map/navigation';
import { ILocationInfoText, LocationDispUtils } from 'src/app/services/location/location-disp-utils';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { IMediaUploadContext } from 'src/app/services/media/media-template';
import { GameStatsUtils } from 'src/app/services/app/utils/game-stats-utils';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { IPopupSkipResult, PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { ActivityFinishedViewComponent } from 'src/app/modals/app/modals/activity-finished/activity-finished.component';
import { EPhotoCardMode, EPhotoValidationStatus, IMultiPhotoUploadSpec } from 'src/app/classes/def/story/progress';
import { IPhotoCardItem } from 'src/app/classes/def/items/photos';
import { PhotosService } from 'src/app/services/media/photos';
import { IPhotoActivityGridStats } from 'src/app/services/app/modules/activities/photo';
import { EAudioGuideStatus } from 'src/app/components/app/components/audio-guide/audio-guide.component';
import { EAudioGuide } from 'src/app/classes/def/core/interactive-features';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-gmap-detail',
  templateUrl: './gmap-detail.page.html',
  styleUrls: ['./gmap-detail.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GmapDetailPage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollContent', { read: ElementRef, static: false }) scrollContent: ElementRef<Element>;
  locationData: IAppLocation = null;
  hasParams: boolean = false;

  activity: IActivity;
  activityNav: IActivity;
  activityFinish: IActivity;

  locationDataLoaded: boolean = false;
  locationURL: string = "";
  tripadvisorURL: string = "";
  debugString: string = "";
  params: IGmapNavParams;

  imgIcons = EImgIcons;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  photoUrl: string = "";
  photoUrlLoaded: string = "";
  photoRefUrl: string = "";

  // use place photo url instead of template
  photoUrlMode: boolean = true;
  useDefaultPhoto: boolean = false;

  // story
  storyName: string = "";
  storyId: number;
  storyLevel: number;
  hiddenPlace: boolean = false;
  loc: ILocationContainer = null;
  testCounter: number = 1;
  /**
   * the current activity is selected and started
   */
  inProgress: boolean = false;
  isNotChecked: boolean = true;

  loading: boolean = false;
  loading2: boolean = false;

  timeouts = {
    validateExpired: null,
    ui: null,
    autoReload: null
  };

  // general
  title: string = null;
  descriptionHeader: string = "";
  description: string = "";
  descriptionFooter: string = "";
  descriptionFooterTop: string = "";
  descriptionFooterBottom: string = "";
  descriptionFooterCompact: string = "";
  descriptionPrestart: string = "<start now>";
  descriptionExpand: string = "<show more>";

  enablePrestart: boolean = false; // start before reaching the location
  isRestart: boolean = false; // restart previous location
  isOverrideLocationStart: boolean = false;

  descriptionRetry: string = "<p>You may try again</p>";
  descriptionView: string = "";
  descriptionPlain: string = "";
  locationViewName: string = "";

  activityDescription: string = "";
  activityFinishDescription: string = "";
  activityNavDescription: string = "";

  hasActivityFinish: boolean = false;
  hasActivityNav: boolean = false;

  dispActivityFinish: boolean = false;
  dispActivityNav: boolean = false;

  theme: string = "theme-light theme-light-bg";
  debugMode: boolean = false;
  locationName: string = "";
  currentLocationItems: ILocationItemsDef = null;
  platform: IPlatformFlags = {} as IPlatformFlags;
  subscription = {
    monitor: null
  };

  bloc: IBackendLocation = null;

  // activity data
  activityContainer: IActivityProviderContainer;
  activityNavContainer: IActivityProviderContainer;
  storyMode: boolean = false;
  isPreloadStory: boolean = false;
  paramSlides: IDynamicParamActivityParam[] = [];
  hasParamSlides: boolean = false;
  bfEnabled: IBusinessFeaturesEnabled;
  updateCharts: BehaviorSubject<boolean>;
  showExtra: boolean = false;
  showExtraEnabled: boolean = false;
  photoReady: boolean = false;

  showMapButton: boolean = false;
  withDismiss: boolean = false;
  centerDescription: boolean = false;
  // descriptionExpanded: boolean = false;
  descriptionExpanded: boolean = true;
  canExpand: boolean = false;
  withExpand: boolean = false;

  pageOne: boolean = true;
  descriptionParams: IActivityParamsView = null;
  questData: IActivityQuestSpecs = null;
  questResponse: IActivityQuestResponse = null;
  showQuestData: boolean = false;

  showTestPreview: boolean = false;

  np: INavParams = null;
  treasure: ILeplaceTreasure = null;
  vs: IViewSpecs;
  headerClass: string = "";

  shake: boolean = false;
  checkLoading: boolean = false;
  withStartOption: boolean = false;
  startReady: boolean = false;
  withSkipEnabled: boolean = false;

  // media uploads
  hasPhotoUpload: boolean = false;
  hasVideoUpload: boolean = false;
  hasAudioUpload: boolean = false;
  videoUploadUrl: string = null;
  audioUploadUrl: string = null;

  mediaRecordState: boolean = false;
  mediaRecordControl: boolean = false;
  mediaRecordStateLocked: boolean = false;

  mediaUploadContext: IMediaUploadContext;
  mediaCardLabel: string = "";

  // photo upload / check
  isPhotoCard: boolean = true;
  photoUploadUrl: string = null;
  photoFinishUploadUrl: string = null;
  withUpload: boolean = false;

  photoCardUrl: string = null;
  multiPhotoCards: IPhotoCardItem[] = [];

  showPhotoUploadMode: boolean = true;

  switchPhotoCardLabel: string = "";
  showTutorialLabel: string = "<view tutorial>";
  photoCardLabel: string = "";

  showPhotoCard: boolean = false;
  showMultiPhotoCard: boolean = false;
  updatePhotoGrid: boolean = false;

  photoCardSelected: number = EPhotoCardMode.reference;

  navBarSlidesPerView: number = 2;
  navBarPhotoCardItems: INavBarItem[] = [{
    name: "reference",
    value: EPhotoCardMode.reference
  }, {
    name: "upload",
    value: EPhotoCardMode.uploaded
  }];

  // validate
  validateDisp: string = null;
  validateState: number = EPhotoValidationStatus.pending;
  validateStatus: boolean = false;
  validateRegistered: boolean = false;
  manualValidation: boolean = false;
  qrValidated: boolean = false;

  // youtube video
  videoUrl: string = "";

  // buttons
  buttons: IButtonOptions[] = null;
  withCustomButtons: boolean = false;
  questFocused: boolean = false;
  inputFocused: boolean = false;

  // params
  defaultXp: number = 0;
  levelDisp: string = "";

  distanceToCheckpoint: number = 0;

  icons: IActivityIcons = {
    activity: {
      icon: "",
      custom: false
    },
    activityFinish: {
      icon: "",
      custom: false
    },
    activityNav: {
      icon: "",
      custom: false
    }
  };

  // nav data
  enableNavInfo: boolean = false;
  withNavInfo: boolean = false;
  isNavigating: boolean = false;
  isNavPreview: boolean = false;
  autostart: boolean = false;

  isRetry: boolean = false;

  disableAddons: boolean = true;

  story: IStory;

  validationInProgress: boolean = false;
  buttonActive: boolean = false;
  withAudioGuide: boolean = false;
  audioGuideAutoplay: boolean = false;

  constructor(
    public router: Router,
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public backButton: BackButtonService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public analytics: AnalyticsService,
    public storyDataProvider: StoryDataService,
    public timeoutMonitor: TimeoutService,
    public photoViewer: PhotoViewService,
    public businessFeaturesProvider: BusinessFeaturesService,
    public activityProvider: ActivityService,
    public challengeEntry: ChallengeEntryService,
    public locationUtilsWizard: LocationUtilsWizard,
    public sanitizer: DomSanitizer,
    public nps: NavParamsService,
    public resources: ResourcesCoreDataService,
    public activityDataService: ActivitiesDataService,
    public gameStatsProvider: GameStatsService,
    public navigationHandler: NavigationHandlerService,
    public tutorials: TutorialsService,
    public popupFeatures: PopupFeaturesService,
    public photoProvider: PhotosService,
    public events: Events
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  ngOnInit() {
    this.analytics.trackView("gmap-detail");

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        this.debugMode = AppSettings.testerMode;
        this.init1();
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.platform = SettingsManagerService.settings.platformFlags;
      }
    }, (err: Error) => {
      console.error(err);
    });

    this.webView.ready().then(() => {
      this.backButton.pushOrReplace(() => {
        this.onFinishedClick(false);
      }, this.vs);
    });

    this.events.subscribe(EModalEvents.dismissCheckpointPreviewMode, (_data: any) => {
      console.log("dismiss event");
      this.onFinishedClick(false);
    });
  }

  init1() {
    this.activityContainer = this.activityProvider.getInitContainer();
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.gmapDetail, null, this.np);
      console.log("nav params: ", npInfo.params);
      this.hasParams = npInfo.hasParams;
      if (this.hasParams) {
        let np: INavParams = npInfo.params;
        let params: IGmapNavParams = np.params;
        this.params = params;
        this.vs = np.view;

        console.log("gmap detail params: ", params);
        this.locationData = (params.location != null) ? DeepCopy.deepcopy(params.location) : null;
        console.log("location data: ", params.location);
        console.log("location data copy: ", this.locationData);
        this.checkInProgress();

        if (params.story != null) {
          this.storyMode = true;
          let index: number = this.locationData.loc.index;
          if (index != null) {
            index += 1;
          }
          let indexString: string = index != null ? "" + index : "";
          let bloc: IBackendLocation = this.locationData.loc.merged;
          this.bloc = bloc;
          let showHeading: boolean = bloc.heading && !LocationDispUtils.checkHiddenCheckpointName(this.locationData.loc, this.inProgress);
          this.title = "(" + indexString + ") " + (bloc.activity ? bloc.activity.title + " - " : "") + (showHeading ? bloc.heading : "Checkpoint");

          this.story = params.story;
          this.storyName = params.story.name;
          this.storyId = params.story.id;
          this.storyLevel = params.story.level;
          this.activityContainer.validate.photoValidated = params.photoValidated;
          this.isPreloadStory = this.story.mode === EStoryMode.preload;
          this.checkAudioGuide();
        } else {
          this.storyMode = false;
          this.title = this.challengeEntry.getChallengeName(params.treasure);
          this.storyName = null;
          this.storyId = null;
          this.activityContainer.validate.photoValidated = false;
        }

        if (params.treasure) {
          this.treasure = params.treasure;
        }

        this.showMapButton = params.fromGmap;

        if (params.interactions != null) {
          this.withDismiss = params.interactions.withDismiss;
          if (params.interactions.withStartOption) {
            this.withStartOption = true;
          }
          if (this.params.interactions.startReady) {
            this.startReady = this.params.interactions.startReady;
          }
          if (params.interactions.withCustomButtons) {
            this.withCustomButtons = true;
            this.buttons = params.interactions.buttons;
          }
        }


        if (this.storyMode) {
          if (!this.inProgress && !this.startReady) {
            // default for preview mode
            this.descriptionFooterCompact = !params.fromGmap ? Messages.toast.isPreviewFromStoryline : Messages.toast.isPreview;
            this.enablePrestart = params.fromGmap;
            this.isOverrideLocationStart = true;
          }
          if (!this.inProgress && params.fromGmap && this.bloc != null) {
            // override for special status (done, skipped, etc.)
            let showFooter: boolean = false;
            if (this.bloc.done) {
              this.descriptionFooterCompact = params.isPreloadStory ? Messages.toast.isDoneFromGmapNonlinear : Messages.toast.isDoneFromGmapLinear;
              this.enablePrestart = true;
              this.isRestart = true;
              showFooter = true;
            } else {
              switch (this.bloc.doneStatus) {
                case ECheckActivityResult.skipped:
                  this.descriptionFooterCompact = params.isPreloadStory ? Messages.toast.isSkippedFromGmapNonlinear : Messages.toast.isSkippedFromGmapLinear;
                  this.enablePrestart = true;
                  this.isRestart = true;
                  showFooter = true;
                  break;
                default:
                  break;
              }
            }
            if (params.isPreloadStory && showFooter) {
              if ((this.buttons != null) && (this.buttons.length === 1)) {
                this.buttons[0].name = "Retry";
                this.buttons[0].class = "action-button-fill button-color-alternate";
              }
              this.isRetry = true;
              if (!this.inProgress && !this.startReady) {
                this.descriptionFooterCompact += Messages.toast.tooFarAway;
              }
            }
          }

          if (params.startOptions) {
            let so: IGmapActivityStartOptions = params.startOptions;
            if (so.isNavPreview) {
              this.isNavPreview = true;
              this.enablePrestart = false;
              this.descriptionFooterCompact = "<p>" + Messages.toast.proceedToTheNextLocation + "</p><p>" + Messages.toast.beAware + "</p>";
              if (this.withAudioGuide) {
                this.checkAudioGuideAutostart();
              }
            }
            if (so.autostart) {
              this.autostart = true;
              if (this.withAudioGuide) {
                this.checkAudioGuideAutostart();
              }
              if (this.withAudioGuide && this.audioGuideAutoplay) {
                // defer autostart until after audio guide is finished
              } else {
                this.initAutostart();
              }
            }
          }
        }

        this.init2();
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkAudioGuide() {
    // if audio guide is enabled for story then it automatically overrides the location-based audio guide
    // if audio guide is not enabled for story but the location-based audio guide is enabled
    this.withAudioGuide = (this.story.audioGuide != null && this.story.audioGuide !== 0) || (this.bloc.audioGuide != null && this.bloc.audioGuide !== 0);
    this.audioGuideAutoplay = this.inProgress && ((this.story.audioGuide === EAudioGuide.ttsAuto) || (this.bloc.audioGuide === EAudioGuide.ttsAuto));
  }

  checkAudioGuideAutostart() {
    this.audioGuideAutoplay = (this.story.audioGuide === EAudioGuide.ttsAuto) || (this.bloc.audioGuide === EAudioGuide.ttsAuto);
  }

  initAutostart() {
    // let sub: string = this.isNavPreview ? "Proceeding to checkpoint" : "Starting challenge";
    let sub: string = "Close this pop-up to stay on the page";
    this.uiext.showRewardPopupQueue("AutoPlay", sub, null, false, 5000, false).then((start: boolean) => {
      console.log("autostart result: ", start);
      if (start) {
        this.timeouts.ui = setTimeout(() => {
          this.onFinishedClick(true);
        }, 500);
      }
    });
  }

  checkInProgress() {
    this.inProgress = this.locationData.inProgress === true ? true : false;
    if (this.inProgress) {
      this.loading2 = true;
    }
    console.log("check in progress: ", this.inProgress);
  }

  init2() {
    if (!this.locationData.loc) {
      return;
    }

    let blocation: ILocationContainer = this.locationData.loc;
    this.checkInProgress();

    this.withNavInfo = this.enableNavInfo && !this.inProgress && this.locationData.storyLocationId != null;
    this.isNavigating = this.locationData.isNavigating;

    if (this.withNavInfo && (this.locationData.navSpec != null)) {
      this.activityNavContainer = this.activityProvider.getInitContainer();
      this.activityNavContainer = this.navigationHandler.getProgressNav(this.activityNavContainer, this.locationData.navSpec.from, this.locationData.navSpec.to, true);
      console.log("activity nav container: ", this.activityNavContainer);
    }

    blocation = LocationUtils.checkFormatActivity(blocation);
    this.activity = blocation.merged.activity;
    this.activityNav = blocation.merged.activityNav;
    this.activityFinish = blocation.merged.activityFinish;

    if (this.activityFinish != null) {
      this.hasActivityFinish = true;
      this.dispActivityFinish = this.activityFinish.code != null;
    }

    if (this.activityNav != null) {
      this.hasActivityNav = true;
      this.dispActivityNav = this.activityNav.code != null;
    }

    this.locationName = blocation.merged.name;
    // this.locationDataJSON = JSON.stringify(this.locationData, null, 4);
    this.hiddenPlace = LocationDispUtils.checkHiddenPlace(blocation, this.inProgress);
    console.log("hidden place: ", this.hiddenPlace);
    this.checkDetailsForFixedLocation();
    let description: string = this.loadDescription(blocation, this.treasure);
    console.log("gmap detail location: ", blocation);

    // story location as treasures show content photo by default
    this.photoUrlMode = !(this.hiddenPlace || SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value) && !this.treasure;

    if (!this.showMapButton) {
      // override settings, use only default (challenge) photos if loaded from e.g. story-home
      this.photoUrlMode = false;
      this.useDefaultPhoto = true;
    }

    let opts: ISelectPhotoOptions = {
      hidden: !this.photoUrlMode ? true : null
    };

    LocationUtils.selectPlaceDispPhoto(blocation, this.treasure, opts);

    if (!opts.hidden) {
      this.locationUtilsWizard.runThroughCache(blocation.dispPhoto.photoUrl).then((extracted: string) => {
        // this.photoUrl = this.sanitizer.bypassSecurityTrustUrl(extracted) as any;
        // sanitizer doesn't work for background images
        this.photoUrl = extracted;
        blocation.dispPhoto.photoUrl = this.photoUrl;
      });
    } else {
      this.photoUrl = blocation.dispPhoto.photoUrl;
    }

    this.checkValidateFlag(blocation);

    // if (this.manualValidation) {
    //   this.checkValidateServer(() => {
    //     this.checkValidateLoop();
    //   });
    // }

    this.generateLink();
    this.getActivityIconWrapper();

    let locInfo: ILocationInfoText = LocationDispUtils.getLocationInfoText(blocation.merged, this.hiddenPlace);
    console.log("loc info: ", locInfo);

    if (blocation.merged.hiddenPreview && !this.inProgress) {
      description = "<p>Preview not available for this checkpoint.</p><p>Proceed to the designated place and start the challenge to reveal the instructions.</p>";
    }

    if (this.isNavigating || this.isNavPreview) {
      if (blocation.merged.navDescription) {
        description = blocation.merged.navDescription;
      }
    }

    this.description = description;

    this.locationViewName = locInfo.locationDispName;
    let activityTitle: string = this.activity.title;
    if ([EActivityCodes.photo, EActivityCodes.snapshot].indexOf(this.activity.code) !== -1) {
      if ((this.bloc.manualValidation === 0) || (this.bloc.manualValidation == null)) {
        activityTitle += " (AI check)";
      }
    }
    this.activityDescription = StringUtils.textToHTML(activityTitle + " - " + StringUtils.textFromHTML(this.activity.description));

    if (this.hasActivityFinish) {
      let activityFinishDescriptionPlainText: string = "(2nd) " + this.activityFinish.title + " - " + StringUtils.textFromHTML(this.activityFinish.description);
      if (blocation.merged.rewardXpAux) {
        activityFinishDescriptionPlainText += " (" + blocation.merged.rewardXpAux + " XP)";
      }
      this.activityFinishDescription = StringUtils.textToHTML(activityFinishDescriptionPlainText);
    }

    if (this.hasActivityNav) {
      let activityNavDescriptionPlainText: string = "(nav) " + this.activityNav.title;
      this.activityNavDescription = StringUtils.textToHTML(activityNavDescriptionPlainText);
    }

    if (this.storyId != null && this.activity) {
      this.descriptionFooterTop = "";
      this.descriptionFooterBottom = this.activityDescription;
      this.descriptionFooter = "<p>--- " + this.locationViewName + " ---</p>" + "<p>" + locInfo.footerText + "</p>" + this.activityDescription;
      this.descriptionHeader = "<p>@" + this.locationViewName + "</p>" + this.activityDescription;
    }

    this.withExpand = this.enablePrestart || this.isNavPreview;
    this.canExpand = this.expandDescription(!(this.enablePrestart || this.isNavPreview));
    this.loc = blocation;
    this.updateCharts = this.activityProvider.watchChartUpdate();
    this.loadActivityProvider(!this.inProgress);
    this.photoReady = true;

    this.showQuestData = this.inProgress;
    if (SettingsManagerService.settings.app.settings.showQuestPreview.value) {
      console.log("test preview (quest)");
      this.showQuestData = true;
    }
    if (SettingsManagerService.settings.app.settings.showQuestPreview.value) {
      console.log("test preview (global)");
      this.showTestPreview = true;
    }

    // extract params, e.g. custom params, quest data
    this.extractParams();
    // check media activity reference/upload card
    this.initMediaUploadCard();
    // check photo card
    if (this.showPhotoCard) {
      // check photo activity reference/upload card
      this.initPhotoCard();
      this.setPhotoCard();
    }

    this.checkActivityScore();
  }

  checkActivityScore() {
    if (this.activity && this.activity.params) {
      let level: number = this.activity.level;
      if (!level) {
        level = this.storyLevel;
      }
      let defaultXp = GameStatsUtils.getActivityFinishedWeightAdjusted(this.activity, this.loc.merged, null, this.story != null ? this.story.xpScaleFactor : null);
      this.defaultXp = defaultXp;
      this.levelDisp = "Level " + level + " (" + this.defaultXp + "+ XP)";
    }
  }

  checkValidateFlag(blocation: ILocationContainer) {
    this.manualValidation = (blocation.merged.manualValidation != null) && (blocation.merged.manualValidation !== 0);
    console.log("manual validation: ", this.manualValidation);
    console.log("bloc: ", blocation.merged);
    this.loadPhotoCardData(blocation.merged.uploadedPhotoUrl, blocation.merged.uploadedPhotoFinishUrl);
    // this.checkValidateFlagCore(blocation.merged.validated);
  }


  /**
   * WARNING: this has some bugs, showing current activity as completed if next one is completed
   * @param validated 
   */
  checkValidateFlagCore(validated: number) {
    this.validateRegistered = false;
    switch (validated) {
      case EPhotoValidationStatus.accepted:
        this.validateDisp = "<p>Photo upload validated</p><p>Your score has been updated</p>";
        this.validateState = EPhotoValidationStatus.accepted;
        this.validateStatus = true;
        this.validateRegistered = true;
        this.activityProvider.validatePhotoUploadAccepted(true, !this.inProgress); // already accepted
        break;
      case EPhotoValidationStatus.rejected:
        this.validateDisp = "<p>Photo upload not validated</p><p>You may reupload and check again later</p>";
        this.validateState = EPhotoValidationStatus.rejected;
        this.validateStatus = false;
        this.validateRegistered = true;
        this.activityProvider.validatePhotoUploadAccepted(false, !this.inProgress);
        break;
      case null:
      default:
        this.validateDisp = "<p>Pending validation</p><p>Please check again later or contact the organizer</p>";
        this.validateState = EPhotoValidationStatus.pending;
        this.validateStatus = false;
        this.validateRegistered = false;
        this.activityProvider.validatePhotoUploadAccepted(false, !this.inProgress);
        break;
    }
    this.setPhotoCard();
  }

  loadPhotoCardData(photoUpload: string, photoFinishUpload: string) {
    this.photoUploadUrl = photoUpload;
    this.photoFinishUploadUrl = photoFinishUpload;
    console.log("photo uploads - upload: " + (this.photoUploadUrl != null) + ", finish upload: " + (this.photoFinishUploadUrl != null));
    if (this.photoFinishUploadUrl != null) {
      if (!this.photoUploadUrl) {
        let item = this.navBarPhotoCardItems.find(e => e.value === EPhotoCardMode.uploaded);
        item.value = EPhotoCardMode.finishUploaded;
        item.name = "upload (2)";
      } else {
        this.navBarSlidesPerView = 3;
        let item = this.navBarPhotoCardItems.find(e => e.value === EPhotoCardMode.uploaded);
        item.name = "upload (1)";
        if (this.navBarPhotoCardItems.find(e => e.value === EPhotoCardMode.finishUploaded) == null) {
          this.navBarPhotoCardItems.push({
            name: "upload (2)",
            value: EPhotoCardMode.finishUploaded
          });
        }
      }
    }
  }

  checkValidateLoop() {
    this.timeouts.autoReload = setTimeout(() => {
      this.checkValidateServer(() => {
        this.checkValidateLoop();
      });
    }, 60000);
  }

  checkValidateServer(callback: () => any) {
    let bloc: IBackendLocation = this.locationData.loc.merged;
    this.storyDataProvider.checkValidationStatus(bloc.id).then((status: IUserStoryLocation) => {
      if (status != null) {
        this.loadPhotoCardData(status.photoUrl, status.photoFinishUrl);
        this.checkValidateFlagCore(status.validated);
      }
      if (callback != null) {
        callback();
      }
    }).catch((err: Error) => {
      console.error(err);
      if (callback != null) {
        callback();
      }
    });
  }

  loadDescription(blocation: ILocationContainer, treasure: ILeplaceTreasure) {
    let description: string = "";

    if (treasure) {
      let storyLocation: ITreasureStoryLocation = treasure.storyLocation;
      if (storyLocation && storyLocation.shortDescription) {
        description = storyLocation.shortDescription;
        return description;
      }
    }

    if (blocation.merged.shortDescription) {
      description = blocation.merged.shortDescription;
      // this.centerDescription = GeneralUtils.getParCount(this.description) < 3;
      // this.centerDescription = true;
      return description;
    }

    if (blocation.merged.description) {
      description = blocation.merged.description;
      return description;
    }

    if (this.activity && this.activity.description) {
      description = this.activity.description;
      // this.centerDescription = true;
      return description;
    }

    return description;
  }


  switchPhoto() {
    if (this.useDefaultPhoto) {
      return;
    }
    if (this.photoUrlMode) {
      this.photoUrlMode = false;
      LocationUtils.selectPlaceDispPhoto(this.loc, this.treasure, {
        hidden: true
      });
      this.photoUrl = this.loc.dispPhoto.photoUrl;
    } else {
      this.photoUrlMode = true;
      LocationUtils.selectPlaceDispPhoto(this.loc, this.treasure, {
        hidden: this.hiddenPlace
      });
      if (!this.hiddenPlace) {
        this.locationUtilsWizard.runThroughCache(this.loc.dispPhoto.photoUrl).then((extracted: string) => {
          // this.photoUrl = this.sanitizer.bypassSecurityTrustUrl(extracted) as any;
          // sanitizer doesn't work for background images
          this.photoUrl = extracted;
          this.loc.dispPhoto.photoUrl = this.photoUrl;
        });
      } else {
        this.photoUrl = this.loc.dispPhoto.photoUrl;
      }
    }
    // console.log(this.loc);
  }

  onImageLoaded(event) {
    let loadOk: boolean = Util.checkImageLoadedEvent(event);
    console.log("on image loaded: ", loadOk);
    if (loadOk) {
      if (this.loc && this.loc.dispPhoto && this.loc.dispPhoto.photoUrl) {
        this.photoUrlLoaded = this.loc.dispPhoto.photoUrl;
      }
    } else {
      // load generic photo (e.g. activity photo) as a fallback
      this.photoUrlMode = false;
      LocationUtils.selectPlaceDispPhoto(this.loc, this.treasure, {
        hidden: true
      });
    }
  }

  /**
   * load activity provider
   * use preview mode or running mode
   * 
   * @param preview ~ not started
   */
  loadActivityProvider(preview: boolean) {
    // load the activity in the proper container (preview/running) and with init only specification
    // the activity is previously loaded in the gmap with full specifications
    this.activityProvider.loadActivity(this.activity, this.description, this.loc, !preview);
    this.activityProvider.setPhotoValidated(this.activityContainer.validate.photoValidated, preview);
    this.activityProvider.setPhotoGridItemFilled(this.activityContainer.validate.photoGridItemAdded, preview);
    console.log("activity container loaded: ", this.activityContainer);
    this.checkActivity(this.activity);
    if (this.storyMode) {
      this.checkItems();
    }
    this.checkCustomParams();
    this.checkBusinessFeatures();
  }


  checkCustomParams() {
    this.paramSlides = ActivityUtils.getCustomParamSlides(this.activity, this.storyMode ? ECustomParamScope.story : ECustomParamScope.challenge);
    if (this.paramSlides.length > 0) {
      this.hasParamSlides = true;
    }
  }


  /**
   * check items that should be unlocked upon activity complete
   */
  checkItems() {
    this.storyDataProvider.getStoryLocationDetailsWithCache(this.storyId, this.locationData.loc.merged.id).then((locationDetails: ILocationItemsDef) => {
      console.log(locationDetails);
      this.currentLocationItems = locationDetails;
      this.locationDataLoaded = true;
      if (locationDetails && locationDetails.items && locationDetails.items.length > 0) {

        let descInit: boolean = false;
        for (let i = 0; i < locationDetails.items.length; i++) {
          if (locationDetails.items[i].item && locationDetails.items[i].item.name) {
            if (!descInit) {
              descInit = true;
              this.description += "<p>Unlocks: ";
            }
            this.description += locationDetails.items[i].item.name;
            if (i < locationDetails.items.length - 1) {
              this.description += ", ";
            }
          }
        }
        if (descInit) {
          this.description += "</p>";
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.locationDataLoaded = true;
    });
  }

  /**
   * check if location has details from places service
   * if not, then get details from places service
   */
  checkDetailsForFixedLocation() {
    let googleIdFromBackend = this.locationData.loc.merged.googleId;
    if (googleIdFromBackend !== null && googleIdFromBackend !== "") {
      let details: IBackendLocation = this.locationData.loc.merged;
      if (details) {
        if (Object.keys(details).length > 0) {
          // empty details
          // console.log("empty details for fixed location");
          Util.generateMapsLink(googleIdFromBackend);
        }
      }
    }
  }

  /**
   * generate link to open with google maps
   */
  generateLink() {
    // https://developers.google.com/maps/documentation/urls/guide
    if ((this.locationData.loc.merged.noLink !== EUnchartedMode.uncharted) && this.locationData.loc.merged.googleId) {
      this.locationURL = Util.generateMapsLink(this.locationData.loc.merged.googleId, this.locationData.loc.merged.name);
      // console.log("location url: " + this.locationURL);
    }
  }

  getActivityIconWrapper() {
    this.getActivityIcon(this.activity, this.icons.activity);
    this.getActivityIcon(this.activityFinish, this.icons.activityFinish);
    this.getActivityIcon(this.activityNav, this.icons.activityNav);
  }

  getActivityIcon(activity: IActivity, activityIcon: IActivityIcon) {
    if (activity) {
      let icon: IIconSpec = {
        icon: "compass",
        custom: false
      };
      icon = ActivityUtils.getIcon(activity.name);
      if (!icon.icon) {
        if (activity.icon) {
          icon.icon = activity.icon;
        }
      }
      if (icon) {
        Object.assign(activityIcon, icon);
      }
    }
  }

  openURL(url: string) {
    if (!this.hiddenPlace) {
      Util.openURLAdaptive(url);
    } else {
      this.uiext.showAlertNoAction(Messages.msg.hiddenPlace.after.msg, Messages.msg.hiddenPlace.after.sub);
    }
  }

  /**
   * deinit all resources for activities
   */
  deinitActivity() {
    console.log("gmap detail deinit activity");
    this.activityProvider.deinitActivity(!this.inProgress);
  }

  deinitActivityMonitor() {
    console.log("gmap detail deinit activity monitor");
    this.activityProvider.deinitActivityMonitor(!this.inProgress);
  }

  /**
   * check activity
   */
  checkActivity(activity: IActivity) {
    if (activity) {
      this.activityProvider.checkActivity(activity, !this.inProgress, this.inProgress);
      this.getActivityStatus(!this.inProgress);
      if (this.inProgress) {
        this.showExtraEnabled = ActivityUtils.checkMultiDisp(this.activityContainer);
      }
      if (this.inProgress) {
        if (!this.subscription.monitor) {
          this.subscription.monitor = this.activityProvider.watchStatus().subscribe((status: IActivityValidateStatus) => {
            if (status) {
              console.log("activity status updated: ", status);
              this.getActivityStatus(!this.inProgress);
            }
          }, (err: Error) => {
            console.error(err);
          });
        }
      } else {
        if (this.withNavInfo && this.isNavigating) {
          if (!this.subscription.monitor) {
            this.subscription.monitor = this.navigationHandler.getNavUpdateObservable().subscribe(() => {
              this.navigationHandler.getProgressNav(this.activityNavContainer, this.locationData.navSpec.from, this.locationData.navSpec.to, false);
            }, (err: Error) => {
              console.error(err);
            });
          }
        }
      }
    }
  }

  switchExtras() {
    this.showExtra = !this.showExtra;
  }

  getActivityStatus(preview: boolean) {
    this.activityContainer = this.activityProvider.getActivityContainer(preview);
    if (this.activityContainer.validate && (this.activityContainer.validate.done || this.activityContainer.validate.failed)) {
      this.loading2 = false;
    }
    if (this.activityContainer.validate && this.activityContainer.validate.canSwitchMapView) {
      // this.activityProvider.checkReturnCondition(this.activityContainer);
    }
    if (this.activityContainer.validate.failed) {
      // dismiss
      this.dismissFailed();
      return;
    }
    this.activityContainer.validate.qrValidated = this.qrValidated;
    // checkFlagsDtreeOutput
    let decisions: IActivityButtonsDtreeFlags = {
      isStoryPreview: !this.showMapButton,
      isInProgress: this.inProgress,
      isBriefing: this.isNavPreview,
      // isPhotoChallenge: this.showPhotoCard,
      isPhotoChallenge: this.activityContainer.unlock.photo,
      // isPhotoTaken: this.hasPhotoUpload,
      isActivityComplete: this.activityContainer.validate.done,
      isPhotoTaken: this.activityContainer.validate.photoValidationFailed || this.activityContainer.validate.photoGridItemAdded || this.activityContainer.validate.photoValidated || this.activityContainer.validate.done,
      // isQRScan: this.bfEnabled.qr,
      isQRScan: this.activityContainer.unlock.scan,
      isQRScanRequired: this.activityContainer.unlock.scan && this.activity.scan === EScanCodeMode.required,
      isQRValid: this.qrValidated,
      isReady: this.startReady,
      // canReturnToMap: this.activityContainer.validate.canSwitchMapView,
      canReturnToMap: this.activityContainer.unlock.map,
      canRetry: this.activityContainer.unlock.retry,
      isQuestValidated: this.activityContainer.validate.questValidated,
      isPhotoGrid: this.activityContainer.unlock.photo && this.showMultiPhotoCard,
      isPhotoGridFilled: this.activityContainer.validate.photoValidationFailed || this.activityContainer.validate.photoValidated || this.activityContainer.validate.done
    };
    this.activityProvider.checkFlagsDtreeOutput(preview, decisions);
    let enable: IActivityButtonsEnable = this.activityContainer.enable;

    if (this.activityContainer.optional && this.activityContainer.optional.skip) {
      let withSkip: boolean = true;
      if (enable != null) {
        for (let key of Object.keys(enable)) {
          // if (enable[key] && ([StringUtils.getPropToStringGenerator()((o: IActivityButtonsEnable) => { o.check })].indexOf(key) === -1)) {
          // && (["check"].indexOf(key) === -1)
          if (enable[key]) {
            withSkip = false;
            break;
          }
        }
      }
      this.withSkipEnabled = withSkip;
    }
    if (this.activityContainer.validate.questValidated) {
      this.activityProvider.confirmValidateQuest(); // will stop quest activity processing
      // disable check button
    }
    // this.showDebugDisplay();
  }

  showDebugDisplay() {
    console.log("gmap detail activity container: ", this.activityContainer);
  }

  uploadNewPhoto() {
    if (!(this.inProgress || this.showTestPreview)) {
      this.uiext.showAlertNoAction(Messages.msg.updatePhotoNotAllowed.before.msg, Messages.msg.updatePhotoNotAllowed.before.sub);
      return;
    }
    if (this.validationInProgress) {
      return;
    }
    this.validationInProgress = true;
    this.uiext.showAlert(Messages.msg.updatePhoto.before.msg, Messages.msg.updatePhoto.before.sub, 3, ["dismiss", "pick photo", "take photo"], true).then((res: number) => {
      if (res === EAlertButtonCodes.ok) {
        this.validatePhoto(true, true);
      }
      if (res === EAlertButtonCodes.aux1) {
        this.validatePhoto(true, false);
      }
      this.validationInProgress = false;
    }).catch((err: Error) => {
      console.error(err);
      this.validationInProgress = false;
    });
  }

  async uploadMultiPhoto(item: IPhotoCardItem) {
    console.log("upload multi photo registered for index: ", item.index);
    // console.log("upload multi photo registered: ", item);
    // this.updatePhotoGrid = !this.updatePhotoGrid;
    // console.log("multi photo cards: ", this.multiPhotoCards);
    if (this.validationInProgress) {
      return;
    }
    this.validationInProgress = true;
    try {
      await this.uiext.showLoadingV2Queue("Uploading..");
      // upload photo grid item
      let res: IPhotoResultResponse = await this.photoProvider.mediaUploadPhotoGridItemActivity(this.storyId, this.bloc.id, item.index, item.upload);
      console.log("photo uploaded: ", res);
      await this.validatePhotoGrid();
      await this.uiext.dismissLoadingV2();
      this.validationInProgress = false;
    } catch (err) {
      this.validationInProgress = false;
      console.error(err);
    }
  }

  validatePhotoGrid() {
    return new Promise((resolve) => {
      let photoUploads: IMultiPhotoUploadSpec[] = this.multiPhotoCards.map(mpc => {
        let mpu: IMultiPhotoUploadSpec = {
          index: mpc.index,
          data: mpc.upload,
          ts: mpc.ts
        };
        return mpu;
      });
      this.activityProvider.validatePhotoGrid(!this.inProgress, photoUploads).then((res: IPhotoActivityGridStats) => {
        console.log("validate photo grid returned: ", res);
        resolve(true);
      }).catch((err: Error) => {
        console.warn("photo validate error on gmap detail");
        console.error(err);
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
        resolve(false);
      });
    });
  }

  validatePhoto(isRetry: boolean, uploadFromGallery: boolean) {
    this.validationInProgress = true;
    this.activityProvider.validatePhoto(isRetry, !this.inProgress, uploadFromGallery).then((res: IPhotoResultResponse) => {
      if (res && res.processedImageData) {
        this.photoUploadUrl = res.processedImageData;
        this.withUpload = true;
        this.initPhotoCard();
        this.setPhotoCard();
      }
      this.validationInProgress = false;
    }).catch((err: Error) => {
      console.warn("photo validate error on gmap detail");
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      this.validationInProgress = false;
    });
  }

  validateMedia() {
    this.activityProvider.validateMedia(!this.inProgress);
  }

  onMediaUpload() {
    this.validateMedia();
  }

  toggleRecording() {
    this.mediaRecordControl = !this.mediaRecordState;
    console.log("toggle record from: " + this.mediaRecordState + " to: " + this.mediaRecordControl);
  }

  onMediaRecord(event: boolean) {
    if (event == null) {
      this.mediaRecordStateLocked = true;
      return;
    }
    this.mediaRecordStateLocked = false;
    this.mediaRecordState = event;
    console.log("media record state changed: " + this.mediaRecordState);
  }

  ngOnDestroy() {
    // console.log("gmap-detail will leave");
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    this.subscription = ResourceManager.clearSubObj(this.subscription);
    this.backButton.checkPop(this.vs);
    this.events.unsubscribe(EModalEvents.dismissCheckpointPreviewMode);
  }

  closeModal(data: any) {
    console.log("will now return from gmap detail: ", data);
    this.deinitActivityMonitor();
    this.timeouts.ui = setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  skipWizard() {
    let returnParams: IGmapDetailReturnParams = {
      code: EGmapDetailReturnCode.nop
    };
    this.popupFeatures.showSkipModal(this.isPreloadStory, this.inProgress).then((res: IPopupSkipResult) => {
      if (res && res.skip) {
        // skip location
        this.deinitActivity();
        returnParams.code = EGmapDetailReturnCode.skip;
        this.closeModal(returnParams);
      } else {
        // cancel and continue location activity
      }
    });
  }

  dismissFailed() {
    this.deinitActivity();
    let returnParams: IGmapDetailReturnParams = {
      code: EGmapDetailReturnCode.failed
    };
    this.closeModal(returnParams);
  }

  onMaybeFinishedClick(proceed: boolean) {
    if (!this.activityContainer.validate.done) {
      PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.finishBeforeComplete.before.msg, Messages.msg.finishBeforeComplete.before.sub, 2, null, true), true).then((res: number) => {
        if (res === EAlertButtonCodes.ok) {
          this.activityProvider.validatePhotoGridPartial(!this.inProgress);
          this.onFinishedClick(proceed);
        }
      });
    } else {
      this.onFinishedClick(proceed);
    }
  }

  prestart() {
    this.popupFeatures.showPrestartModal().then((res: IPopupSkipResult) => {
      // console.log("prestart res: ", res);
      if (res.skip) {
        let startOptions: IGmapActivityStartOptions = {
          prestart: true,
          isRestart: this.isRestart,
          isOverrideLocationStart: this.isOverrideLocationStart
        };
        this.onFinishedClickCore(true, startOptions);
      }
    });
  }

  onFinishedClick(proceed: boolean) {
    this.onFinishedClickCore(proceed, null);
  }

  /**
   * main method for return/continue type interaction
   */
  onFinishedClickCore(proceed: boolean, startOptions: IGmapActivityStartOptions) {
    if (!this.pageOne) {
      console.log("on finished click, switch page");
      this.pageOne = true;
      return;
    }

    if (proceed) {
      this.activityProvider.checkConfirmValidateQuestComplete();
    }

    if (this.hasParams) {
      // view was opened when reached location
      console.log("on finished click, activity started: ", this.inProgress);
      console.log("status: ", this.activityContainer);

      let returnParams: IGmapDetailReturnParams = {
        code: EGmapDetailReturnCode.nop
      };

      if (startOptions != null) {
        returnParams.startOptionsSelected = startOptions;
      }

      if (this.inProgress) {
        this.getActivityStatus(!this.inProgress);
        console.log("can switch map view: ", this.activityContainer.validate.canSwitchMapView);
        // if can switch map view, the finish popup is shown in Gmap!
        if (!this.activityContainer.validate.canSwitchMapView) {
          // location requires validation
          if (this.activityContainer.validate.enable) {
            // location done
            if (this.activityContainer.validate.done) {
              this.deinitActivity();
              returnParams.code = EGmapDetailReturnCode.done;
              this.closeModal(returnParams);
            } else {
              // location not done
              if (this.activityContainer.validate.failed) {
                // already failed, return to map
                this.deinitActivity();
                returnParams.code = EGmapDetailReturnCode.failed;
                this.closeModal(returnParams);
              } else {
                // skip location
                this.skipWizard();
              }
            }
          } else {
            // location does not require validation
            this.deinitActivity();
            returnParams.code = EGmapDetailReturnCode.done;
            this.closeModal(returnParams);
          }
        } else {
          // keep activity watch in background
          // this.deinitActivity();

          // true for the initial validation e.g. photo
          if (this.activityContainer.validate.done) {
            returnParams.code = EGmapDetailReturnCode.nop;
            this.closeModal(returnParams);
          } else {
            returnParams.code = EGmapDetailReturnCode.nop;
            this.closeModal(returnParams);
          }
        }
      } else {
        // view was opened for inspection
        if (this.withDismiss) {
          // view was opened for inspection and may start the activity or return to caller
          returnParams.code = proceed ? EGmapDetailReturnCode.proceed : EGmapDetailReturnCode.dismiss;
          this.closeModal(returnParams);
        } else {
          // view was opened for inspection and may start the activity or return to caller
          returnParams.code = proceed ? EGmapDetailReturnCode.proceed : EGmapDetailReturnCode.nop;
          this.closeModal(returnParams);
        }
      }
    } else {
      let params: IGmapEntryNavParams = {
        mode: EGmapMode.worldMap
      };

      let navParams: INavParams = {
        view: null,
        params: params
      };

      this.nps.set(ENavParamsResources.gmap, navParams);
      this.router.navigate([ERouteDef.gmap], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  customButtonTap(button: IButtonOptions) {
    if (button.callback) {
      button.callback();
    } else {
      let returnParams: IGmapDetailReturnParams = {
        code: button.code
      };
      this.closeModal(returnParams);
    }
  }

  /**
   * check if business is registered
   * and enable qr code and sales buttons
   */
  checkBusinessFeatures() {
    this.bfEnabled = this.businessFeaturesProvider.loadBusinessFeatures(this.locationData.loc, this.storyMode);
  }

  /**
   * view available sales for this place
   */
  viewSales() {
    this.businessFeaturesProvider.viewSalesBLocation(this.locationData.loc).then(() => {
      this.bfEnabled = this.businessFeaturesProvider.getCurrentBusinessFeatures();
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * scan qr code for locations that require this feature
   * or validate custom story location qr code if specified
   */
  scanBarcodeOnClick() {
    this.activityProvider.scanBarcodeWizardNoAction(this.activity, this.locationData.loc, true, true, () => {
      this.qrValidated = true;
      console.log("activity status updated via QR scan: validated");
      this.getActivityStatus(!this.inProgress);
    }, () => {
      this.qrValidated = false;
      console.log("activity status updated via QR scan: validation failed");
      this.getActivityStatus(!this.inProgress);
    }, false, this.activity.scan);
  }

  extractParams() {
    this.descriptionParams = this.activityProvider.getCustomActivityParams(this.activityContainer.activity,
      this.activityContainer.blocation, this.storyMode ? ECustomParamScope.story : ECustomParamScope.challenge, this.story);

    if (this.activity.questData) {
      this.questData = this.activity.questData;
    } else {
      this.questData = this.descriptionParams.questData;
    }

    if (this.descriptionParams.photoData) {
      this.photoRefUrl = this.descriptionParams.photoData.ref;
      if (!this.photoRefUrl) {
        this.photoRefUrl = EPhotos.imageNotAvailable;
      }
      this.showPhotoCard = true; // show on started or preview
      if (this.isNavPreview) {
        this.showPhotoCard = false; // don't show in nav preview in order to not confuse the user
      }
    }

    if (this.descriptionParams.multiPhotoData && this.descriptionParams.multiPhotoData.length > 0) {
      this.multiPhotoCards = this.descriptionParams.multiPhotoData.map((mpd: IActivityPhotoSpecs, index: number, _array: any[]) => {
        // check progress data
        let uploadedPhotoUrl: string = null;
        if (this.bloc.uploads && (this.bloc.uploads.length > 0)) {
          let upload = this.bloc.uploads.find(up => up.index === index);
          if (upload != null) {
            uploadedPhotoUrl = upload.url;
          }
        }
        // format photo card grid
        let pci: IPhotoCardItem = {
          ref: mpd.ref != null ? mpd.ref : EPhotos.imageNotAvailable,
          upload: uploadedPhotoUrl,
          description: mpd.d,
          title: mpd.title,
          index: index,
          updated: false
        };
        return pci;
      });
      this.showPhotoCard = false;

      // don't show photo grid if challenge is in preview
      if (ActivityUtils.isPhotoTypeActivity(this.loc) && this.inProgress) {
        console.log("show photo grid: ", true);
        this.showMultiPhotoCard = true; // show on started or preview
      } else {
        console.log("show photo grid: ", false);
        if (this.isNavPreview) {
          this.showMultiPhotoCard = false; // don't show in nav preview in order to not confuse the user
        } else {
          this.showMultiPhotoCard = true; // show uploaded photos for review
        }
      }
    }

    if (this.descriptionParams.videoGuideSpecs) {
      if (this.descriptionParams.videoGuideSpecs.before && !this.inProgress) {
        // check video before (when in preview mode)
        this.videoUrl = this.descriptionParams.videoGuideSpecs.before.url;
      }
      if (this.descriptionParams.videoGuideSpecs.target && this.inProgress) {
        // check video during (when in live mode)
        this.videoUrl = this.descriptionParams.videoGuideSpecs.target.url;
      }
    }
    // extract nav params
    if (this.activityNav != null) {
      let descriptionNavParams: IActivityParamsView = this.activityProvider.getCustomActivityParams(this.activityNav,
        this.activityContainer.blocation, this.storyMode ? ECustomParamScope.story : ECustomParamScope.challenge, this.story);
      if (descriptionNavParams.customParams != null && descriptionNavParams.customParams.length > 0) {
        this.descriptionParams.customParamsNav = descriptionNavParams.customParams;
      }
    }
    console.log("extract params: ", this.descriptionParams);
  }

  /**
   * submit response for quest activity
   * @param data 
   */
  submitResponse(data: IActivityQuestResponse) {
    this.questResponse = data;
  }

  /**
   * submit response for validations (quest activity)
   */
  async checkInput() {
    console.log("check input: ", this.questResponse);
    if (!this.inProgress && !this.showTestPreview) {
      this.uiext.showAlertNoAction(Messages.msg.questPreviewMode.after.msg, Messages.msg.questPreviewMode.after.sub);
      return;
    }

    if (this.activityContainer.validate.questValidated) {
      this.uiext.showAlertNoAction(Messages.msg.questAlreadyValidated.after.msg, Messages.msg.questAlreadyValidated.after.sub);
      return;
    }

    try {
      this.checkLoading = true;
      let validate: boolean = await this.activityProvider.validateQuest(this.questResponse, this.locationData.location, this.showTestPreview, this.questData);
      this.checkLoading = false;
      if (!validate) {
        this.shake = !this.shake;
      } else {
        // set input response cache
        if (this.questData.free) {
          this.storyDataProvider.updateQuestInputCache("" + this.questResponse.a);
        }
        // unlock proceed
        this.activityContainer.validate.questValidated = true;
        this.isNotChecked = false;
      }
      await this.activityProvider.showQuestValidateFeedback(validate);
    } catch (err) {
      console.error(err);
      this.analytics.dispatchError(err, "gmap-detail");
    }
  }

  checkQuestComplete() {
    return this.activityContainer && this.activityContainer.validate && this.activityContainer.validate.questValidated;
  }

  questAction(event: number) {
    let qa: IQuestAction = this.activityProvider.checkQuestAction(event, true);
    switch (qa.action) {
      case EQuestAction.requireClue:
        this.uiext.showAlertNoAction("Clue", qa.data);
        break;
      case EQuestAction.requireMapClue:
        let returnParams: IGmapDetailReturnParams = {
          code: EGmapDetailReturnCode.showMapClue,
          data: qa.data
        };
        this.closeModal(returnParams);
        break;
    }
  }

  questFocus(focused: boolean) {
    console.log("quest focused: ", focused);
    // this.questFocused = state;
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }

  /**
   * view tutorial 
   * showing custom params view if existing
   */
  async viewTutorial() {
    if (this.activity != null) {
      let tutorials: IActivityTutorialContainer = await PromiseUtils.wrapResolve(this.activityDataService.getActivityTutorials(this.activity.code), true);
      this.descriptionParams.tutorial = tutorials.spec.description;
    }
    this.pageOne = false;
    // this.activityProvider.viewTutorial(this.activityContainer.activity, this.activityContainer.blocation, this.storyMode ? ECustomParamScope.story : ECustomParamScope.challenge)
  }

  viewPhoto() {
    // console.log("view photo");
    if (!this.photoUrlLoaded) {
      this.uiext.showAlertNoAction(Messages.msg.photoNotAvailableGen.after.msg, Messages.msg.photoNotAvailableGen.after.sub);
      return;
    }

    let locationName: string = this.hiddenPlace ? "Hidden Place" : this.locationName;
    if (this.loc && this.loc.dispPhoto && this.loc.dispPhoto.photoUrl) {
      this.photoUrlLoaded = this.loc.dispPhoto.photoUrl;
    }

    locationName = StringUtils.trimName(locationName, EMessageTrim.placeNameCard);
    this.photoViewer.viewPhoto(this.photoUrlLoaded, locationName, {
      share: false,
      customModal: true,
      isDataUrl: false,
      changePhotoCallback: null
    });
  }

  selectPhotoCardFromTab(navItem: INavBarItem) {
    if (!navItem) {
      return;
    }
    this.photoCardSelected = navItem.value;
    this.checkPhotoUpload();
    this.setPhotoCard();
  }

  initMediaUploadCard() {
    this.mediaUploadContext = {
      storyId: this.storyId,
      storyLocationId: this.locationData.storyLocationId,
      file: "recording"
    };

    switch (this.activity.code) {
      case EActivityCodes.audio:
        this.hasAudioUpload = true;
        this.isPhotoCard = false;
        this.audioUploadUrl = this.activityContainer.blocation.merged.uploadedPhotoUrl;
        if (this.audioUploadUrl != null) {
          this.mediaCardLabel = "Uploaded";
          this.validateMedia();
        }
        break;
      case EActivityCodes.video:
        this.hasVideoUpload = true;
        this.isPhotoCard = false;
        this.videoUploadUrl = this.activityContainer.blocation.merged.uploadedPhotoUrl;
        if (this.videoUploadUrl != null) {
          this.mediaCardLabel = "Uploaded";
          this.validateMedia();
        }
        break;
      default:
        break;
    }
  }

  initPhotoCard() {
    if (OtherUtils.checkDefinedPhotoUrl(this.photoUploadUrl)) {
      this.photoCardSelected = EPhotoCardMode.uploaded;
      this.showPhotoUploadMode = true;
    } else if (OtherUtils.checkDefinedPhotoUrl(this.photoFinishUploadUrl)) {
      this.photoCardSelected = EPhotoCardMode.finishUploaded;
      this.showPhotoUploadMode = true;
    } else {
      this.photoCardSelected = EPhotoCardMode.reference;
      this.showPhotoUploadMode = false;
    }
  }

  checkPhotoUpload() {
    switch (this.photoCardSelected) {
      case EPhotoCardMode.uploaded:
        this.showPhotoUploadMode = true;
        break;
      case EPhotoCardMode.finishUploaded:
        this.showPhotoUploadMode = true;
        break;
      case EPhotoCardMode.reference:
        this.showPhotoUploadMode = false;
        break;
    }
  }

  setPhotoCard() {
    let showPhotoCard: boolean = OtherUtils.checkDefinedPhotoUrl(this.photoUploadUrl) || OtherUtils.checkDefinedPhotoUrl(this.photoFinishUploadUrl) || OtherUtils.checkDefinedPhotoUrl(this.photoRefUrl);

    // don't show photo uploads if challenge is in progress (e.g., retried)
    if (!ActivityUtils.isPhotoTypeActivity(this.loc) && this.inProgress) {
      console.log("show photo card: false / in progress: " + this.inProgress);
      showPhotoCard = false;
    } else {
      console.log("show photo card: true / in progress: " + this.inProgress);
    }

    if (!this.isPhotoCard) {
      showPhotoCard = false;
    }
    this.hasPhotoUpload = OtherUtils.checkDefinedPhotoUrl(this.photoUploadUrl) || OtherUtils.checkDefinedPhotoUrl(this.photoFinishUploadUrl);
    this.switchPhotoCardLabel = this.showPhotoUploadMode ? "<view reference>" : "<view uploaded>";
    this.photoCardLabel = this.showPhotoUploadMode ? "Uploaded" : "Reference";

    switch (this.photoCardSelected) {
      case EPhotoCardMode.uploaded:
        this.photoCardUrl = this.photoUploadUrl;
        break;
      case EPhotoCardMode.finishUploaded:
        this.photoCardUrl = this.photoFinishUploadUrl;
        break;
      case EPhotoCardMode.reference:
        this.photoCardUrl = this.photoRefUrl;
        break;
    }

    this.showPhotoCard = showPhotoCard;
    console.log("show photo card: ", this.showPhotoCard);
    console.log("show photo card available - photo upload: " + (this.photoUploadUrl != null) + ", photo finish upload: " + (this.photoFinishUploadUrl != null) + ", photo ref: " + (this.photoRefUrl != null));
    // console.log("show photo card selected: ", this.showPhotoCard, this.photoCardUrl);
    console.log("has photo upload: ", this.hasPhotoUpload);
  }

  viewPhotoCard() {
    if (!this.photoCardUrl) {
      return;
    }
    let title: string = this.showPhotoUploadMode ? "Uploaded photo" : "Reference photo";
    this.photoViewer.viewPhoto(this.photoCardUrl, title, {
      share: true,
      customModal: true,
      isDataUrl: false,
      changePhotoCallback: null
    });
  }

  validateButtonClick() {
    // if (this.validateState == EPhotoValidationStatus.pending) {
    //   this.checkValidateServer(null);
    // }
    this.checkValidateServer(null);
  }

  showOptions() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 0,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      }
      // photo: {
      //   name: "View photo",
      //   code: 2,
      //   icon: EAppIconsStandard.viewPhoto,
      //   enabled: true
      // }
    };

    if (this.debugMode) {
      let actions2: IPopoverActions = {
        expire: {
          name: "Expire*",
          code: 3,
          enabled: true
        },
        start: {
          name: "Start*",
          code: 4,
          enabled: true
        },
        stop: {
          name: "Stop*",
          code: 5,
          enabled: true
        },
        resetWatch: {
          name: "Reset watch*",
          code: 6,
          enabled: true
        },
        simulateMoveComplete: {
          name: "Sim move complete*",
          code: 7,
          enabled: true
        },
        simulateExploreComplete: {
          name: "Sim explore complete*",
          code: 8,
          enabled: true
        },
        testFinishedActivity: {
          name: "Sim activity finished*",
          code: 9,
          enabled: true
        }
      };
      Object.assign(actions, actions2);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 0:
          this.showActivityTutorial();
          break;
        case 2:
          this.viewPhoto();
          break;
        case 3:
          this.triggerExpireActivity();
          break;
        case 4:
          this.testInitActivity();
          break;
        case 5:
          this.testDeinitActivity();
          break;
        case 6:
          this.resetActivityWatch();
          break;
        case 7:
          this.activityProvider.setExploreMoveTransition(EExploreMoveStat.simulateMoveComplete);
          break;
        case 8:
          this.activityProvider.setExploreMoveTransition(EExploreMoveStat.simulateExploreComplete);
          break;
        case 9:
          this.testFinishedActivity();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  resetActivityWatch() {
    this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
    this.loadActivityProvider(!this.inProgress);
  }

  showActivityTutorial() {
    PromiseUtils.wrapNoAction(this.activityProvider.showActivityTutorialResolve(this.activity, EDroneMode.noDrone, false), false);
  }

  testInitActivity() {
    let activity: IActivity = this.activity;
    this.inProgress = true;
    this.loadActivityProvider(!this.inProgress);
    switch (activity.code) {
      case EActivityCodes.dance:
        let danceActivityParams: IDanceActivityDef = activity.params;
        this.activityProvider.initDanceActivity(danceActivityParams);
        break;
      case EActivityCodes.decibel:
        let decibelActivityParams: IDecibelActivityDef = activity.params;
        this.activityProvider.initDecibelActivity(decibelActivityParams);
        break;
    }
  }

  testDeinitActivity() {
    this.inProgress = false;
    this.loadActivityProvider(!this.inProgress);
    this.activityProvider.triggerActivityExit();
    // fallback
    this.activityProvider.deinitActivity(false);
    this.activityProvider.exitAll().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
  * debug test trigger expire activity timeout
  */
  triggerExpireActivity() {
    this.timeoutMonitor.triggerExpired();
  }

  updateAudioGuideStatus(event: number) {
    console.log("audio guide status update: ", event);
    if (event != null) {
      switch (event) {
        case EAudioGuideStatus.started:

          break;
        case EAudioGuideStatus.finished:
          if (this.autostart) {
            this.initAutostart();
          }
          break;
      }
    }
  }

  expandDescription(expand: boolean) {
    this.descriptionExpanded = !this.descriptionExpanded;
    let canExpand: boolean = false;

    if (expand != null) {
      this.descriptionExpanded = expand;
    }

    this.descriptionExpand = this.descriptionExpanded ? "<show less>" : "<show more>";

    if (!this.descriptionExpanded) {
      this.descriptionView = StringUtils.trimHtmlPar(this.description, EMessageTrim.descriptionPreview, EMessageTrimLines.descriptionPreview);
    } else {
      this.descriptionView = this.description;
    }

    this.descriptionPlain = StringUtils.textFromHTMLPlain(this.description);

    canExpand = (this.description !== this.descriptionView);
    return canExpand;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onUserScoll(null);
    }, 500);
  }

  onUserScoll(_ev) {
    if (ScrollUtils.checkScrollEnd(this.scrollContent)) {
      // console.log("scroll end");
      this.buttonActive = true;
    } else {
      // console.log("scroll");
      this.buttonActive = false;
    }
  }

  testFinishedActivity() {
    let shareParams: IShareMsgParams = {
      place: {
        name: this.locationName,
        place: this.locationData.loc
      },
      story: {
        worldMap: !this.story,
        id: this.story ? this.story.id : null,
        name: this.story ? this.story.name : "World Map",
        rewardCoins: this.story ? this.story.rewardCoins : 0,
        story: this.story
      },
      activityStats: {
        photoUrl: this.photoUrl,
        videoUrl: this.videoUrl,
        baseCode: ActivityUtils.checkSimilarActivity(this.activity),
        code: this.activity.code,
        name: this.activity.title,
        finishedDescription: this.activity.finishedDescription,
        progress: null,
        standardRewardCap: null,
        statsList: null,
        stats: null
      },
      activityFinish: this.activityFinish,
      customParams: this.activity.customParams,
      xp: this.bloc.rewardXp,
      scaled: (this.story != null) && ((this.story.xpScaleFactor != null) && (this.story.xpScaleFactor !== 1)),
      items: {
        collected: 0,
        collectedValue: 0,
        reward: 0,
        value: 0
      },
      placeItems: {
        availableItems: this.currentLocationItems
      },
      actionButtons: {
        watchAd: false,
        scanQR: false,
        share: false
      }
    };

    let navParams: INavParams = {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: true,
        frame: true
      },
      params: shareParams
    };

    PromiseUtils.wrapNoAction(this.uiext.showCustomModal(null, ActivityFinishedViewComponent, navParams), true);
  }
}

