
import { Injectable } from "@angular/core";
import { ResourceManager } from "src/app/classes/general/resource-manager";
import { PromiseUtils } from "../../utils/promise-utils";
import { GenericDataService } from "../data/generic";
import { AnalyticsTemplateService } from './analytics-template';
import { MQTTService } from "../../telemetry/mqtt.service";
export interface ILeplaceLoggedEvent {
    event: string,
    label: string,
    data: string,
    timestamp: Date
}

// event tracking on leplace backend
@Injectable({
    providedIn: 'root'
})
export class AnalyticsLeplaceService implements AnalyticsTemplateService {
    CACHE_SIZE: number = 100;
    CACHE_TIMEOUT: number = 5000;
    eventCache: ILeplaceLoggedEvent[] = [];
    cacheTimeout;

    streamToMQTT: boolean = false;

    constructor(
        public generic: GenericDataService,
        public mqtt: MQTTService
    ) {

    }

    init() {
        let promise = new Promise((resolve) => {
            resolve(true);
        });
        return promise;
    }

    /**
     * set user id from the db
     * @param userId 
     */
    setUserId(userId: number) {
        console.log("set user id: ", userId);
    }

    /**
     * track view (changes)
     * @param title 
     */
    trackView(title: string) {
        console.log("track view: " + title);
    }


    toggleStreamToMQTT(enable: boolean) {
        console.log("toggle stream to MQTT: ", enable);
        this.streamToMQTT = enable;
    }

    /**
     * send event to analytics service w/ cache
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendEvent(category: string, action: string, label: string, _value: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {

            if (this.streamToMQTT) {
                this.mqtt.publishLog(category, action, label);
                resolve(true);
                return;
            }

            let dumpCache = () => {
                PromiseUtils.wrapNoAction(this.sendEventsTrail(this.eventCache), true);
                this.eventCache = [];
            };
            let event: ILeplaceLoggedEvent = {
                event: category,
                label: label,
                data: action,
                timestamp: new Date()
            };
            this.eventCache.push(event);

            if (this.eventCache.length >= this.CACHE_SIZE) {
                this.cacheTimeout = ResourceManager.clearTimeout(this.cacheTimeout);
                dumpCache();
            } else {
                this.cacheTimeout = ResourceManager.clearTimeout(this.cacheTimeout);
                this.cacheTimeout = setTimeout(() => {
                    dumpCache();
                }, this.CACHE_TIMEOUT);
            }
            resolve(true);
        });
    }

    /**
     * send event to analytics service
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendEventCore(category: string, action: string, label: string, _value: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.generic.genericPostStandard("/logging/app/post-log", {
                event: category,
                label: label,
                data: action
            }).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    /**
     * send events trail to analytics service
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendEventsTrail(events: ILeplaceLoggedEvent[]): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!(events && events.length > 0)) {
                resolve(false);
                return;
            }
            this.generic.genericPostStandard("/logging/app/post-logs-multi", {
                events: events
            }).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }
}
