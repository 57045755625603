

import { Injectable } from '@angular/core';
import { ILocationPhotoResult, IGetPhotoOptions } from './location-utils-def';
import { ImageLoaderTSService } from '../media/image-loader-ts';
import { LocationUtilsPlus } from './location-utils-plus';
import { PlacesDataService } from '../data/places';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { LocationUtils } from './location-utils';
import { EPlaceUnifiedSource } from 'src/app/classes/def/places/provider';
import { IPlaceExtContainer } from 'src/app/classes/def/places/container';
import { BehaviorSubject } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';


export interface IPlacePhotoCacheContainer {
    [key: string]: string
}

export interface IPlacePhotoBroadcast {
    googleId: string;
    photoUrl: string;
    resolved: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LocationUtilsWizard {
    placePhotoCache: IPlacePhotoCacheContainer = {};
    // placePhotoCacheLocal: IPlacePhotoCacheContainer = {};
    placePhotoLock: { [key: string]: boolean } = {};
    watchLoadingObs: BehaviorSubject<IPlacePhotoBroadcast>;
    watchLoadingSub: { [key: string]: any } = {};
    watchIndex: number = 0;

    constructor(
        public locationUtilsPlus: LocationUtilsPlus,
        public imageLoaderTS: ImageLoaderTSService,
        public placeProvider: PlacesDataService
    ) {
        this.watchLoadingObs = new BehaviorSubject(null);
    }

    /**
     * workaround for loading google photos into HTML
     * @param photoUrl 
     */
    runThroughCache(photoUrl: string): Promise<string> {
        let promise: Promise<string> = new Promise((resolve) => {
            console.log("run through cache: ", photoUrl);
            this.locationUtilsPlus.extractToCacheReturnLocalPathResolve(photoUrl).then((photoLocal: string) => {
                resolve(photoLocal);
            });
        });
        return promise;
    }

    loadPlacePhotoWizard1(place: ILeplaceReg, options: IGetPhotoOptions): Promise<string> {
        return this.loadPlacePhotoWizard2(place.place, options);
    }

    private watchForBroadcastComplete(googleId: string) {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            this.watchIndex += 1;
            let watchId: string = googleId + "/" + this.watchIndex;
            this.watchLoadingSub[watchId] = this.watchLoadingObs.subscribe((event: IPlacePhotoBroadcast) => {
                // console.log("external input: ", code);
                if (event) {
                    if (event.googleId === googleId) {
                        if (event.resolved) {
                            this.watchLoadingSub[watchId] = ResourceManager.clearSub(this.watchLoadingSub[watchId]);
                            resolve(event.photoUrl);
                        } else {
                            this.watchLoadingSub[watchId] = ResourceManager.clearSub(this.watchLoadingSub[watchId]);
                            reject(new Error("place photo failed to fetch"));
                        }
                    }
                }
            }, (err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check cached photo url
     * load place photo via inner http request
     * add to cache
     * extract base64
     * resolve extracted or final url (platform spec)
     * @param place 
     * @param options 
     */
    loadPlacePhotoWizard2(place: IPlaceExtContainer, options: IGetPhotoOptions): Promise<string> {
        let promise: Promise<string> = new Promise((resolve) => {
            console.log("load place photo wizard");

            if (this.placePhotoLock[place.googleId]) {
                console.warn("place photo loading in progress lock");
                // todo: watch observable, returning when previous call returned, with photoUrl
                this.watchForBroadcastComplete(place.googleId).then((photoUrl: string) => {
                    resolve(photoUrl);
                }).catch((err) => {
                    console.error(err);
                    resolve(null);
                });
                return;
            }

            this.placePhotoLock[place.googleId] = true;

            let broadcastResult = (photoUrl: string) => {
                console.log("broadcast result: ", photoUrl != null);
                this.placePhotoLock[place.googleId] = false;
                let res: IPlacePhotoBroadcast = {
                    googleId: place.googleId,
                    photoUrl: photoUrl,
                    resolved: photoUrl != null
                };
                this.watchLoadingObs.next(res);
            }

            let checkRunThroughCache = (photoUrl: string, update: boolean) => {
                console.log("check run through cache");
                if (options.useGeneric) {
                    // don't run through cache if using generic photo
                    broadcastResult(photoUrl);
                    resolve(photoUrl);
                } else {
                    if (update) {
                        checkUpdateCache(photoUrl);
                    }
                    if (options.cacheDisk) {
                        this.runThroughCache(photoUrl).then((cachedPhotoUrl: string) => {
                            // checkUpdateCache(cachedPhotoUrl);
                            broadcastResult(cachedPhotoUrl);
                            resolve(cachedPhotoUrl);
                        });
                    } else {
                        // checkUpdateCache(photoUrl);
                        broadcastResult(photoUrl);
                        resolve(photoUrl);
                    }
                }
            }

            let checkCache = () => {
                // if (options.cache) {
                //     if (this.placePhotoCacheLocal[place.googleId] != null) {
                //         return this.placePhotoCacheLocal[place.googleId];
                //     }
                // } else {
                //     if (this.placePhotoCache[place.googleId] != null) {
                //         return this.placePhotoCache[place.googleId];
                //     }
                // }
                if (this.placePhotoCache[place.googleId] != null) {
                    return this.placePhotoCache[place.googleId];
                }
                return null;
            }

            let checkUpdateCache = (photoUrl: string) => {
                // if (options.cache) {
                //     this.placePhotoCacheLocal[place.googleId] = photoUrl;
                // } else {
                //     this.placePhotoCache[place.googleId] = photoUrl;
                // }
                this.placePhotoCache[place.googleId] = photoUrl;
            }

            // check if photo loading is required (check photo url existing in DB)
            let loadingRequired: boolean = LocationUtils.checkPhotoLoadingRequired(place);
            console.log("check photo loading required: ", loadingRequired);

            if (loadingRequired && !options.useGeneric) {
                // check cached redirect url (from previous calls)
                // only if not using generic
                let cacheUrl: string = checkCache();
                console.log("check cache url local RAM: ", cacheUrl);

                if (cacheUrl != null) {
                    // resolve existing/cached url
                    // broadcastResult(cacheUrl);
                    checkRunThroughCache(cacheUrl, false);
                    // resolve(cacheUrl);
                } else {
                    let providerCode: number = place.providerCode;
                    if (!providerCode) {
                        providerCode = EPlaceUnifiedSource.google;
                    }

                    if (!place.photoUrl || place.isGenericPhotoLoaded) {
                        console.log("require get temp photo url");
                        LocationUtils.getTempPhotoUrl(place, options);
                    }

                    // fetch real google url via places api (local or server-side)
                    this.locationUtilsPlus.getRedirectPhotoUrl(providerCode, place.photoUrl, place.googleId).then((res: ILocationPhotoResult) => {
                        if (res && res.photoLoadedFinal) {
                            // save both urls because of how isGenericPhotoLoaded is defined server-side
                            this.placeProvider.cachePlacePhotoUrl(place.googleId, res.photoUrl, res.photoUrl);
                            checkRunThroughCache(res.photoUrl, true);
                        } else {
                            broadcastResult(null);
                            resolve(null);
                        }
                    }).catch((err) => {
                        console.error(err);
                        resolve(null);
                    });
                }
            } else {
                checkRunThroughCache(place.photoUrl, true);
            }
        });
        return promise;
    }
}