import { GeneralCache } from "../../app/general-cache";
import { EOS } from "../app/app";

export enum EMapShapes {
    marker = 0,
    circle = 1,
    polyline = 2,
    polygon = 3
}

export enum EMarkerTypes {
    plain = 2,
    canvasFrame = 1,
    canvasPlain = 0,
    canvasPlainCenter = 100
}

export enum EMarkerScope {
    place = 0,
    auxPlace = 1,
    item = 2,
    auxItem = 3
}

/**
 * lower value is higher priority
 * UPDATE: Higher zIndex value overlays will be drawn on top of lower zIndex value tile layers and overlays.
 * android is reversed!
 */
export enum EMarkerPriority {
    circle = 1,
    directions = 2,

    coin = 3,
    waypoint = 4,

    // world map
    treasure = 5,
    story = 6,
    arena = 6,
    challenge = 6,
    otherTreasure = 6,

    auxPlace = 7,
    place = 8,

    otherUser = 9,
    user = 10,
    drone = 11
}

export class MarkerPriority {
    static getMarkerPriority(priority: number) {
        let maxp: number = 0;
        let reversed: boolean = GeneralCache.os === EOS.android;

        // reversed = true; // test
        reversed = false;

        for (let key of Object.keys(EMarkerPriority)) {
            if (EMarkerPriority[key] > maxp) {
                maxp = EMarkerPriority[key];
            }
        }
        if (!reversed) {
            return priority;
        }
        return maxp - priority + 1;
    }
}

export enum EMarkerRenderPriority {
    user = 1,
    drone = 1,
    otherUser = 7,
    otherUserDrone = 8,
    place = 9,
    auxPlace = 10,
    coin = 11,
    waypoint = 12,
    story = 13,
    challenge = 14,
    arena = 15,
    treasure = 16
}

export interface ISetThisMarkerOptions {
    zindex: number;
    size: number;
    color?: string;
    unlockLimiter?: boolean;
}
