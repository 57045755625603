<ion-content *ngIf="show" [class]="getWrapperClass()">

  <div class="stick-top">
    <progress-loading *ngIf="buttonOptions.progressLoading.isEnabled()" [small]="false"
      customClass="progress-bar-alternate-light">
    </progress-loading>
    <progress-loading *ngIf="buttonOptions.progress.isEnabled()" [small]="false"
      customClass="progress-bar-alternate-light" [manual]="true" [progress]="progress">
    </progress-loading>
  </div>

  <div>
    <div *ngIf="showPlate">
      <div [@showState]="showState">
        <div class="view-frame-no-border">
          <div class="bg-container bg-container-fill">
            <div class="content">
              <leplace-plate [plateSub]="plateSub"></leplace-plate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="!useNativeMap">

    <ion-fab vertical="top" horizontal="start" class="gmap-margin-top-fab">

      <blink-button theme="primary70" id="btnOptions" *ngIf="!activityStarted.ANY" [icon]="appIconsStandard.settings"
        [set]="buttonOptions.menu.blink" [fab]="true" (select)="showOptionsNoAction()" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" id="btnOptions" *ngIf="activityStarted.ANY" [icon]="appIconsStandard.settings"
        customBaseClass="icon-spin-animation" [set]="buttonOptions.menu.blink" [fab]="true"
        (select)="showOptionsNoAction()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.ar.isEnabled()" [icon]="appIcons.arView" [customIcon]="true"
        [setTimeout]="buttonOptions.ar.blink" [disabled]="buttonOptions.ar.isDisabled()" [fab]="true"
        (select)="openAR()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.scan.isEnabled()" icon="flash"
        [setTimeout]="buttonOptions.scan.blink" [disabled]="buttonOptions.scan.isDisabled()" [fab]="true"
        (select)="scanPlaces(true, null)" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" *ngIf="activityStarted.snapshot" icon="camera" [setTimeout]="user.canTakePhoto"
        [disabled]="!user.canTakePhoto" [fab]="true" (select)="checkPhotoFindActivityNow()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnDrone" *ngIf="buttonOptions.drone.isEnabled()" [icon]="appIcons.drone"
        [customIcon]="true" [set]="buttonOptions.drone.blink" [disabled]="buttonOptions.drone.isDisabled()" [fab]="true"
        (select)="enterDroneMode(false)" [noBorder]="true" [huge]="isDroneOnly">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.editPlace.isEnabled()" icon="build"
        [setTimeout]="buttonOptions.editPlace.blink" [disabled]="buttonOptions.editPlace.isDisabled()" [fab]="true"
        (select)="editTreasuresLoc(true)" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" *ngIf="buttonOptions.undoEditPlace.isEnabled()" icon="undo"
        [setTimeout]="buttonOptions.editPlace.blink" [disabled]="buttonOptions.undoEditPlace.isDisabled()" [fab]="true"
        (select)="undoEditTreasuresLoc()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.addPlace.isEnabled()" icon="create"
        [setTimeout]="buttonOptions.addPlace.blink" [disabled]="buttonOptions.addPlace.isDisabled()" [fab]="true"
        (select)="placeTreasureContent(false)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.quickAddPlace.isEnabled()" icon="pin"
        [setTimeout]="buttonOptions.quickAddPlace.blink" [disabled]="buttonOptions.quickAddPlace.isDisabled()"
        [fab]="true" (select)="placeTreasureContent(true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.group.isEnabled()" icon="people" [fab]="true"
        [setTimeoutOnChange]="buttonOptions.group.blink" (select)="openArenaContinue()" [huge]="true" [noBorder]="true"
        [name]="internalFlags.teamFabDisp">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.operatorChat.isEnabled()" icon="people" [fab]="true"
        [setTimeoutOnChange]="buttonOptions.operatorChat.blinkOnChange" (select)="openChatWithOperator()" [huge]="false"
        [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.playVideo.isEnabled()" [icon]="appIcons.film"
        [customIcon]="true" [set]="buttonOptions.playVideo.blink"
        [setTimeoutOnChange]="buttonOptions.playVideo.blinkOnChange" [fab]="true"
        (select)="checkVideoBefore(null, true)" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.magnet.isEnabled()" [icon]="buttonOptions.magnet.icon"
        [customIcon]="buttonOptions.magnet.customIcon" [setTimeout]="buttonOptions.magnet.blink" [fab]="true"
        (select)="collectNearbyObject()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.pickup.isEnabled()" [icon]="buttonOptions.pickup.icon"
        [customIcon]="buttonOptions.pickup.customIcon" [setTimeout]="buttonOptions.pickup.blink" [fab]="true"
        (select)="pickUpNearbyObject()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.dropoff.isEnabled()" [icon]="buttonOptions.dropoff.icon"
        [customIcon]="buttonOptions.dropoff.customIcon" [setTimeout]="buttonOptions.dropoff.blink" [fab]="true"
        (select)="dropoffObject()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.startLoc.isEnabled() && !activityStarted.ANYTEMP" icon="play"
        [set]="buttonOptions.startLoc.blink" [setTimeoutOnChange]="buttonOptions.startLoc.blinkOnChange" [fab]="true"
        (select)="getNearbyLocationDetails()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.exitFab.isEnabled()" [icon]="appIcons.exit"
        [customIcon]="true" [set]="buttonOptions.exitFab.blink"
        [setTimeoutOnChange]="buttonOptions.exitFab.blinkOnChange" [fab]="true" (select)="pause()" [huge]="true"
        [noBorder]="true"></blink-button>

      <ion-button ion-fab class="round-fab no-border-width round-fab-size button-color-primary-70"
        *ngIf="flags.mapDebugModeFab" (click)="setCurrentLocationSimulation()">
        <icon-wrapper slot="icon-only" [icon]="appIcons.personPlace" [custom]="true" class="text-color-primary">
        </icon-wrapper>
      </ion-button>
    </ion-fab>
    <ion-fab vertical="top" horizontal="end" class="gmap-margin-top-fab">

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.NONE" [icon]="appIcons.world"
        [customIcon]="true" [set]="true" [customIcon]="true" [fab]="true" (select)="setFollow(0, true)"
        [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.MOVE_MAP"
        [icon]="appIcons.gpsFixed" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="setFollow(0, true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.MOVE_MAP_HEADING_3D"
        [icon]="appIcons.navigation" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="setFollow(0, true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.MOVE_MAP_HEADING_2D"
        [icon]="appIcons.compass" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="setFollow(0, true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollowDrone" *ngIf="flags.follow === followModes.DRONE"
        [icon]="appIcons.drone" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="exitDroneModePrompt()" [huge]="false" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" [icon]="appIconsStandard.search" *ngIf="buttonOptions.zoom.isEnabled()"
        [setTimeoutOnChange]="buttonOptions.zoom.blinkOnChange" [setTimeout]="buttonOptions.zoom.blink" [fab]="true"
        (select)="onZoomClick()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.compass.isEnabled()" [icon]="appIconsStandard.compass"
        [setTimeoutOnChange]="buttonOptions.compass.blinkOnChange" [setTimeout]="buttonOptions.compass.blink"
        [fab]="true" (select)="switchCompass()" [noBorder]="true">
      </blink-button>

      <blink-button theme="alternate70" *ngIf="buttonOptions.resumeLoc.isEnabled()" [icon]="appIconsStandard.checkpoint"
        [set]="buttonOptions.resumeLoc.blink" [setTimeoutOnChange]="buttonOptions.resumeLoc.blinkOnChange" [fab]="true"
        (select)="getCurrentLocationCheckpointWrapper()" [huge]="false" [noBorder]="true"></blink-button>

      <blink-button [theme]="!internalFlags.droneUpgradeState ? 'primary70' : 'alternate70'"
        *ngIf="hasDroneUpgradePrepared()" [icon]="appIcons.turbo" [customIcon]="true" [fab]="true"
        (select)="applyDroneUpgrade(null)" [huge]="false" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.recalculateDirections.isEnabled()" [icon]="appIcons.route"
        [customIcon]="true" [disabled]="buttonOptions.recalculateDirections.isDisabled()"
        [setTimeoutOnChange]="buttonOptions.recalculateDirections.blinkOnChange"
        [setTimeout]="buttonOptions.recalculateDirections.blink" [fab]="true" (select)="recalculateDirections()"
        [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.toggleSpeaker.isEnabled()"
        [icon]="!internalFlags.ttsMute ? appIconsStandard.speakerOn : appIconsStandard.speakerOff" [fab]="true"
        (select)="toggleSpeaker()" [noBorder]="true">
      </blink-button>

    </ion-fab>
  </div>

  <div *ngIf="!useNativeMap" [class]="mapClass" #gmap id="gmap"></div>
  <capacitor-google-map *ngIf="useNativeMap" class="capacitorMapClass" [ngClass]="mapClass" #gmap
    id="gmap"></capacitor-google-map>

  <div *ngIf="useNativeMap">
    <ion-fab vertical="top" horizontal="start" class="gmap-margin-top-fab">

      <blink-button theme="primary70" id="btnOptions" *ngIf="!activityStarted.ANY" [icon]="appIconsStandard.settings"
        [set]="buttonOptions.menu.blink" [fab]="true" (select)="showOptionsNoAction()" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" id="btnOptions" *ngIf="activityStarted.ANY" [icon]="appIconsStandard.settings"
        customBaseClass="icon-spin-animation" [set]="buttonOptions.menu.blink" [fab]="true"
        (select)="showOptionsNoAction()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.ar.isEnabled()" [icon]="appIcons.arView" [customIcon]="true"
        [setTimeout]="buttonOptions.ar.blink" [disabled]="buttonOptions.ar.isDisabled()" [fab]="true"
        (select)="openAR()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.scan.isEnabled()" icon="flash"
        [setTimeout]="buttonOptions.scan.blink" [disabled]="buttonOptions.scan.isDisabled()" [fab]="true"
        (select)="scanPlaces(true, null)" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" *ngIf="activityStarted.snapshot" icon="camera" [setTimeout]="user.canTakePhoto"
        [disabled]="!user.canTakePhoto" [fab]="true" (select)="checkPhotoFindActivityNow()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnDrone" *ngIf="buttonOptions.drone.isEnabled()" [icon]="appIcons.drone"
        [customIcon]="true" [set]="buttonOptions.drone.blink" [disabled]="buttonOptions.drone.isDisabled()" [fab]="true"
        (select)="enterDroneMode(false)" [huge]="isDroneOnly" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.editPlace.isEnabled()" icon="build"
        [setTimeout]="buttonOptions.editPlace.blink" [disabled]="buttonOptions.editPlace.isDisabled()" [fab]="true"
        (select)="editTreasuresLoc(true)" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" *ngIf="buttonOptions.undoEditPlace.isEnabled()" icon="undo"
        [setTimeout]="buttonOptions.editPlace.blink" [disabled]="buttonOptions.undoEditPlace.isDisabled()" [fab]="true"
        (select)="undoEditTreasuresLoc()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.addPlace.isEnabled()" icon="create"
        [setTimeout]="buttonOptions.addPlace.blink" [disabled]="buttonOptions.addPlace.isDisabled()" [fab]="true"
        (select)="placeTreasureContent(false)" [noBorder]="true">
      </blink-button>
      <blink-button theme="primary70" *ngIf="buttonOptions.quickAddPlace.isEnabled()" icon="pin"
        [setTimeout]="buttonOptions.quickAddPlace.blink" [disabled]="buttonOptions.quickAddPlace.isDisabled()"
        [fab]="true" (select)="placeTreasureContent(true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.group.isEnabled()" icon="people" [fab]="true"
        [setTimeoutOnChange]="buttonOptions.group.blink" (select)="openArenaContinue()" [huge]="true" [noBorder]="true"
        [name]="internalFlags.teamFabDisp">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.operatorChat.isEnabled()" icon="people" [fab]="true"
        [setTimeoutOnChange]="buttonOptions.operatorChat.blinkOnChange" (select)="openChatWithOperator()" [huge]="false"
        [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.playVideo.isEnabled()" [icon]="appIcons.film"
        [customIcon]="true" [set]="buttonOptions.playVideo.blink"
        [setTimeoutOnChange]="buttonOptions.playVideo.blinkOnChange" [fab]="true"
        (select)="checkVideoBefore(null, true)" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.magnet.isEnabled()" [icon]="buttonOptions.magnet.icon"
        [customIcon]="buttonOptions.magnet.customIcon" [setTimeout]="buttonOptions.magnet.blink" [fab]="true"
        (select)="collectNearbyObject()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.pickup.isEnabled()" [icon]="buttonOptions.pickup.icon"
        [customIcon]="buttonOptions.pickup.customIcon" [setTimeout]="buttonOptions.pickup.blink" [fab]="true"
        (select)="pickUpNearbyObject()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.dropoff.isEnabled()" [icon]="buttonOptions.dropoff.icon"
        [customIcon]="buttonOptions.dropoff.customIcon" [setTimeout]="buttonOptions.dropoff.blink" [fab]="true"
        (select)="dropoffObject()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.startLoc.isEnabled() && !activityStarted.ANYTEMP" icon="play"
        [set]="buttonOptions.startLoc.blink" [setTimeoutOnChange]="buttonOptions.startLoc.blinkOnChange" [fab]="true"
        (select)="getNearbyLocationDetails()" [huge]="true" [noBorder]="true"></blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.exitFab.isEnabled()" [icon]="appIcons.exit"
        [customIcon]="true" [set]="buttonOptions.exitFab.blink"
        [setTimeoutOnChange]="buttonOptions.exitFab.blinkOnChange" [fab]="true" (select)="pause()" [huge]="true"
        [noBorder]="true"></blink-button>

      <ion-button ion-fab class="round-fab no-border-width round-fab-size button-color-primary-70"
        *ngIf="flags.mapDebugModeFab" (click)="setCurrentLocationSimulation()">
        <icon-wrapper slot="icon-only" [icon]="appIcons.personPlace" [custom]="true" class="text-color-primary">
        </icon-wrapper>
      </ion-button>
    </ion-fab>

    <ion-fab vertical="top" horizontal="end" class="gmap-margin-top-fab">

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.NONE" [icon]="appIcons.world"
        [customIcon]="true" [set]="true" [customIcon]="true" [fab]="true" (select)="setFollow(0, true)"
        [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.MOVE_MAP"
        [icon]="appIcons.gpsFixed" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="setFollow(0, true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.MOVE_MAP_HEADING_3D"
        [icon]="appIcons.navigation" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="setFollow(0, true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollow" *ngIf="flags.follow === followModes.MOVE_MAP_HEADING_2D"
        [icon]="appIcons.compass" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="setFollow(0, true)" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" id="btnFollowDrone" *ngIf="flags.follow === followModes.DRONE"
        [icon]="appIcons.drone" [customIcon]="true" [set]="buttonOptions.location.blink"
        [setTimeoutOnChange]="buttonOptions.location.blinkOnChange" [customIcon]="true" [fab]="true"
        (select)="exitDroneModePrompt()" [huge]="false" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" [icon]="appIconsStandard.search" *ngIf="buttonOptions.zoom.isEnabled()"
        [setTimeoutOnChange]="buttonOptions.zoom.blinkOnChange" [setTimeout]="buttonOptions.zoom.blink" [fab]="true"
        (select)="onZoomClick()" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.compass.isEnabled()" [icon]="appIconsStandard.compass"
        [setTimeoutOnChange]="buttonOptions.compass.blinkOnChange" [setTimeout]="buttonOptions.compass.blink"
        [fab]="true" (select)="switchCompass()" [noBorder]="true">
      </blink-button>

      <blink-button theme="alternate70" *ngIf="buttonOptions.resumeLoc.isEnabled()" [icon]="appIconsStandard.checkpoint"
        [set]="buttonOptions.resumeLoc.blink" [setTimeoutOnChange]="buttonOptions.resumeLoc.blinkOnChange" [fab]="true"
        (select)="getCurrentLocationCheckpointWrapper()" [huge]="false" [noBorder]="true"></blink-button>

      <blink-button [theme]="!internalFlags.droneUpgradeState ? 'primary70' : 'alternate70'"
        *ngIf="hasDroneUpgradePrepared()" [icon]="appIcons.turbo" [customIcon]="true" [fab]="true"
        (select)="applyDroneUpgrade(null)" [huge]="false" [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.recalculateDirections.isEnabled()" [icon]="appIcons.route"
        [customIcon]="true" [disabled]="buttonOptions.recalculateDirections.isDisabled()"
        [setTimeoutOnChange]="buttonOptions.recalculateDirections.blinkOnChange"
        [setTimeout]="buttonOptions.recalculateDirections.blink" [fab]="true" (select)="recalculateDirections()"
        [noBorder]="true">
      </blink-button>

      <blink-button theme="primary70" *ngIf="buttonOptions.toggleSpeaker.isEnabled()"
        [icon]="!internalFlags.ttsMute ? appIconsStandard.speakerOn : appIconsStandard.speakerOff" [fab]="true"
        (select)="toggleSpeaker()" [noBorder]="true">
      </blink-button>

    </ion-fab>
  </div>

  <div *ngIf="initialized">
    <div *ngIf="!useNativeMap" class="map-view" #auxmap id="map-aux"></div>
    <div *ngIf="useNativeMap" #auxmap id="map-aux"></div>
    <!-- <capacitor-google-map *ngIf="useNativeMap" #auxmap id="map-aux"></capacitor-google-map> -->
    <!-- <div *ngIf="useNativeMap" class="capacitor-google-map" #auxmap id="map-aux"></div> -->
  </div>

  <div>
    <div class="hud semi-transp-uniform" *ngIf="app.hud && flags.showHud && checkHudAnyShow()"
      [ngClass]="{'gmap-margin-top': !internalFlags.isWeb || internalFlags.isPWA}">
      <leplace-hud [data]="hudMsg" [expander]="false"></leplace-hud>
    </div>

    <div class="hud-left-m" *ngIf="navGauge.ngDict.left.showLevelGauge" id="navGaugeLeft">
      <leplace-vertical-gauge [value]="navGauge.ngDict.left.levelGauge" [levels]="navGauge.ngDict.left.levelGaugeSize"
        [theme]="navGauge.ngDict.left.theme" [inverted]="navGauge.ngDict.left.inverted" [options]="vgOptions"
        (action)="onGaugeSelect($event)" class="account-chart">
      </leplace-vertical-gauge>
      <icon-wrapper [icon]="navGauge.ngDict.left.icon" [custom]="navGauge.ngDict.left.customIcon" class="hud-left-icon"
        customClass="leplace-icon-s" (click)="navGaugeClick(navGauge.ngDict.left)"></icon-wrapper>
    </div>

    <div class="hud-right-m" *ngIf="navGauge.ngDict.right.showLevelGauge" id="navGaugeRight">
      <leplace-vertical-gauge [value]="navGauge.ngDict.right.levelGauge" [levels]="navGauge.ngDict.right.levelGaugeSize"
        [theme]="navGauge.ngDict.right.theme" [inverted]="navGauge.ngDict.right.inverted" [options]="vgOptions"
        (action)="onGaugeSelect($event)" class="account-chart" chartClass="ngx-charts-heatmap-right">
      </leplace-vertical-gauge>
      <icon-wrapper [icon]="navGauge.ngDict.right.icon" [custom]="navGauge.ngDict.right.customIcon"
        class="hud-right-icon" customClass="leplace-icon-s" (click)="navGaugeClick(navGauge.ngDict.right)">
      </icon-wrapper>
    </div>

    <!-- <content-drawer [options]="drawerOptions" class="main-menu-drawer" #contentDrawerHud>
    <div class="content">
      <leplace-hud [data]="hudMsg" [expander]="true"></leplace-hud>
    </div>
  </content-drawer> -->
    <!-- *ngIf="app.hudXP" -->

    <!-- <div [class]="hudClass" [ngClass]="hudTintClass" *ngIf="app.hudXP">
    <div class="text-center">
      <span class="info-text-s bold italic" [ngClass]="hudInnerClass">{{hudMsgXP}}</span>
    </div>
  </div> -->

    <div class="hud-joystick gmap-margin-bottom-2" *ngIf="flags.droneMode">
      <leplace-joystick-ngx *ngIf="useJoystick" id="controlJoystick" [alternate]="activityStarted.ANYTEMP"
        (action)="onJoystickAction($event);">
      </leplace-joystick-ngx>

      <ion-grid class="full-w" *ngIf="!useJoystick">
        <ion-row>
          <ion-col size="6">
            <ion-button ion-fab class="round-fab no-border-width round-fab-size button-color-primary-70"
              (mousedown)="onJoystickActionButton(1)" (mouseup)="onJoystickActionButton(0)"
              (touchstart)="onJoystickActionButton(1)" (touchend)="onJoystickActionButton(0)">
              <icon-wrapper slot="icon-only" [icon]="appIconsStandard.lt" [custom]="false" class="text-color-primary">
              </icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button ion-fab class="round-fab no-border-width round-fab-size button-color-primary-70"
              (mousedown)="onJoystickActionButton(2)" (mouseup)="onJoystickActionButton(0)"
              (touchstart)="onJoystickActionButton(2)" (touchend)="onJoystickActionButton(0)">
              <icon-wrapper slot="icon-only" [icon]="appIconsStandard.gt" [custom]="false" class="text-color-primary">
              </icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </div>

    <div class="hud-joystick gmap-margin-bottom-2" *ngIf="flags.showCompassAdjustControl">
      <leplace-joystick-ngx id="compassAdjustJoystick" [alternate]="true"
        (action)="onCompassAdjustJoystickAction($event);">
      </leplace-joystick-ngx>
    </div>

    <div class="hud-joystick gmap-margin-bottom-2"
      *ngIf="flags.showFindJoystick && !flags.droneMode && !isFreeMove() && getCompassAvailable()">
      <direction-arrow [destinationBearing]="findJoystickPosition.headingDeg"
        [compassHeading]="findJoystickPosition.headingComp"></direction-arrow>
    </div>
  </div>

  <leplace-hud-slide [data]="hudMsgXP" [show]="app.hudXP" [config]="hudConfig" [scrolling]="true"></leplace-hud-slide>

  <!-- [ngClass]="theme" -->
  <!-- <ion-footer *ngIf="app" [class]="footerClass"> -->
  <!-- [ngClass]="hudTintClass" -->
  <div *ngIf="app" class="leplace-footer-container" [ngClass]="footerClass">
    <div fxLayout="column" [ngClass]="footerContentClass" id="elementDashboard">
      <div *ngIf="storySelected" class="gmap-progress-height">
        <location-dots-ext class="icon-color-primary-low multistep-color-primary"
          [sliderIndex]="app.storyLocationsActiveSlide" [itemsPerSlide]="flags.itemsPerSlide"
          [data]="app.storyLocationsSlides"
          (select)="getLocationDetailsForIndexOnClick($event[0], false, false, false, null)">
        </location-dots-ext>
        <!-- <location-dots class="icon-color-primary-low multistep-color-primary"
          [data]="app.storyLocations" (select)="getLocationDetailsForIndexOnClick($event[0], false)"></location-dots> -->
      </div>
      <div fxFlex></div>
      <div *ngIf="buttonsLoaded">
        <ion-grid class="full-w">
          <ion-row>
            <ion-col size="3">
              <blink-button theme="accent" *ngIf="buttonOptions.places.isEnabled()" icon="bookmarks"
                defaultClass="button-rounded button-color-accent" highClass="button-rounded button-color-alternate"
                [disabled]="buttonOptions.places.isDisabled()" [setTimeout]="buttonOptions.places.blink"
                (select)="viewPlaces()" [noBorder]="true"></blink-button>
            </ion-col>
            <ion-col size="3">
              <blink-button theme="warn" *ngIf="buttonOptions.start.isEnabled()"
                defaultClass="button-rounded button-color-warn" highClass="button-rounded button-color-alternate"
                [disabled]="buttonOptions.start.isDisabled()" icon="play" [setTimeout]="buttonOptions.start.blink"
                (select)="start()" [noBorder]="true">
              </blink-button>
              <blink-button theme="alternate" *ngIf="buttonOptions.pause.isEnabled()"
                defaultClass="button-rounded button-color-alternate" highClass="button-rounded button-color-accent"
                [disabled]="buttonOptions.pause.isDisabled()" [icon]="appIconsStandard.pause" [customIcon]="false"
                [setTimeout]="buttonOptions.pause.blink" (select)="pause()" [noBorder]="true">
              </blink-button>
              <blink-button theme="alternate" *ngIf="buttonOptions.exit.isEnabled()"
                defaultClass="button-rounded button-color-alternate" highClass="button-rounded button-color-warn"
                [icon]="appIconsStandard.pause" [customIcon]="false" [set]="buttonOptions.exit.blink" (select)="pause()"
                [noBorder]="true">
              </blink-button>
              <blink-button theme="accent" *ngIf="buttonOptions.completeActivity.isEnabled()"
                defaultClass="button-rounded button-color-accent" highClass="button-rounded button-color-alternate"
                [icon]="appIcons.check" [customIcon]="true" [setTimeout]="buttonOptions.completeActivity.blink"
                (select)="finalizeActivity()" [noBorder]="true"></blink-button>
            </ion-col>
            <ion-col size="3">
              <blink-button theme="alternate" *ngIf="buttonOptions.skip.isEnabled()"
                defaultClass="button-rounded button-color-alternate" highClass="button-rounded button-color-accent"
                [icon]="appIconsStandard.skipForward" [set]="buttonOptions.skip.blink"
                [setTimeoutOnChange]="buttonOptions.skip.blinkOnChange" [disabled]="buttonOptions.skip.isDisabled()"
                (select)="skipPlaceOnClick(1, null)" [noBorder]="true">
              </blink-button>
              <blink-button theme="alternate" *ngIf="buttonOptions.startLoc.isEnabled()"
                defaultClass="button-rounded button-color-accent" highClass="button-rounded button-color-alternate"
                icon="play" [set]="buttonOptions.startLoc.blink"
                [setTimeoutOnChange]="buttonOptions.startLoc.blinkOnChange"
                [disabled]="buttonOptions.startLoc.isDisabled()" (select)="getNearbyLocationDetails()"
                [noBorder]="true">
              </blink-button>
              <blink-button theme="alternate" *ngIf="buttonOptions.startChallenge.isEnabled()"
                defaultClass="button-rounded button-color-accent" highClass="button-rounded button-color-alternate"
                icon="play" [set]="buttonOptions.startChallenge.blink"
                [setTimeoutOnChange]="buttonOptions.startChallenge.blinkOnChange"
                [disabled]="buttonOptions.startChallenge.isDisabled()" (select)="startNearbyChallenge()"
                [noBorder]="true">
              </blink-button>
            </ion-col>
            <ion-col size="3">
              <ion-button *ngIf="buttonOptions.inventory.isEnabled()" text-center
                class="action-button-fill button-rounded button-color-alternate no-border-width"
                (click)="goToInventoryNoAction()">
                <icon-wrapper slot="icon-only" [icon]="appIcons.backpack" [custom]="true"></icon-wrapper>
                <!-- <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.backpack"></icon-wrapper>
                <span>({{viewId}})</span> -->
              </ion-button>
              <ion-button *ngIf="buttonOptions.inventoryFlag.isEnabled()" text-center
                class="action-button-fill button-rounded button-color-alternate no-border-width"
                (click)="goToInventoryNoAction()">
                <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.backpack" [custom]="true">
                </icon-wrapper>
                <span class="button-font-size-s">({{app.activeItems}})</span>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div fxFlex></div>
    </div>
  </div>
</ion-content>