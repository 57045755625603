import { Injectable } from "@angular/core";
import { UiExtensionService } from "../general/ui/ui-extension";
import { ILeplaceTreasure } from "../../classes/def/places/leplace";
import { MarkerUtils } from "./marker-utils";
import { IMarkerDetailsOpenContext, IPlaceMarkerContent } from "../../classes/def/map/map-data";
import { ILeplaceObjectContainer } from "../../classes/def/core/objects";
import { IGroupMember } from "../../classes/def/mp/groups";
import { EMarkerTypes, EMarkerScope, EMarkerPriority, EMarkerRenderPriority, MarkerPriority } from "../../classes/def/map/markers";
import { ETreasureType } from "../../classes/def/items/treasures";
import { EAppIconsStandard, EMarkerIcons } from "../../classes/def/app/icons";
import { GameUtils } from "../../classes/utils/game-utils";
import { UserDataService } from "../data/user";
import { ESettingsCode } from "../../classes/def/app/settings";
import { AppConstants } from "src/app/classes/app/constants";
import { IDescriptionFrameParams, EDescriptionViewStyle, DescriptionFrameViewComponent } from "src/app/modals/generic/modals/description-frame/description-frame.component";
import { EAlertButtonCodes } from "src/app/classes/def/app/ui";
import { EExploreCoinAction } from "src/app/classes/def/activity/explore";


@Injectable({
    providedIn: 'root'
})
export class MarkerUtilsService {

    constructor(
        public uiext: UiExtensionService
    ) {
        console.log("marker utils service created");
    }

    /**
    * attach a marker to this specific item
    * @param treasure 
    * @param layer 
    * @param worldMapMode 
    * @param debug 
    */
    attachPlaceMarkerToTreasure(treasure: ILeplaceTreasure, layer: string, worldMapMode: boolean, debug: boolean,
        callback: (item: ILeplaceTreasure) => any, reversePriority: boolean): IPlaceMarkerContent {
        let crateMarkersContent1: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromTreasure(treasure, treasure.spec ? treasure.spec.markerType : EMarkerTypes.canvasFrame);
        let locked: boolean = crateMarkersContent1.locked;

        if (treasure.lockedForUser != null) {
            locked = treasure.lockedForUser;
        }

        if (treasure.owned) {
            crateMarkersContent1.drag = true;
            crateMarkersContent1.animate = true;
        }

        MarkerUtils.setTreasureMarkerZindex(crateMarkersContent1, treasure, reversePriority);

        crateMarkersContent1.layer = layer;
        crateMarkersContent1.icon = treasure.spec ? treasure.spec.photoUrl : null;

        if (treasure.photoMarker && treasure.photoUrl) {
            crateMarkersContent1.icon = treasure.photoUrl;
            MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, EMarkerScope.place);
            crateMarkersContent1.label = null;
        } else {
            MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, !locked ? EMarkerScope.item : EMarkerScope.auxItem);
        }

        crateMarkersContent1.uid = treasure.uid;

        if (treasure.place && treasure.place.registeredBusiness && treasure.place.place && !treasure.place.hideLabel) {
            switch (treasure.type) {
                case ETreasureType.treasure:
                    // show place names above treasures if the place is registered
                    crateMarkersContent1.label = treasure.place.place.name;
                    // crateMarkersContent1.label = "LP";
                    MarkerUtils.setColorRegistered(crateMarkersContent1);
                    break;
                default:
                    MarkerUtils.setColorRegistered(crateMarkersContent1);
                    break;
            }
        }

        if (worldMapMode != null) {
            treasure.isWorldMap = worldMapMode;
        }
        if (debug != null) {
            treasure.isDebug = debug;
        }
        if (!callback) {
            crateMarkersContent1.callback = () => {
                let crateOpenInfo = MarkerUtils.getCrateOpenInfo(treasure, treasure.isWorldMap, treasure.isDebug);
                this.uiext.showAlertNoAction(crateOpenInfo.title, crateOpenInfo.sub);
            };
        } else {
            crateMarkersContent1.callback = () => {
                callback(treasure);
            };
        }

        if (crateMarkersContent1.drag) {
            crateMarkersContent1.dragCallback = (lat: number, lng: number) => {
                treasure.placeMarker.location.lat = lat;
                treasure.placeMarker.location.lng = lng;
                // if (item.location && item.location.location) {
                //     item.location.location.merged.lat = lat;
                //     item.location.location.merged.lng = lng;
                // }
                treasure.lat = lat;
                treasure.lng = lng;
                console.log("drag event registered");
            };
        }

        if (treasure.isLocal) {
            crateMarkersContent1.lockSync = true;
        } else {
            crateMarkersContent1.lockSync = false;
        }

        crateMarkersContent1.treasure = Object.assign({}, treasure);

        // do not deep copy as it will break references when updating map markers (and they will get replaced)

        // crateMarkersContent1.treasure = JSON.parse(JSON.stringify(treasure));

        crateMarkersContent1.lockedForUser = treasure.lockedForUser;

        // console.log("treasure: " + treasure.id + " locked for user: " + treasure.lockedForUser);
        // console.log(treasure);

        // crateMarkersContent1.treasure = JSON.parse(JSON.stringify(treasure));
        // console.log("attach place marker: " + treasure.id);

        treasure.placeMarker = crateMarkersContent1;
        return crateMarkersContent1;
    }




    /**
    * attach a marker to this specific item
    * @param item 
    * @param layer 
    * @param worldMapMode 
    * @param debug 
    */
    getARObjectPlaceMarker(item: ILeplaceObjectContainer, layer: string): IPlaceMarkerContent {
        let crateMarkersContent1: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromARObject(item);
        MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, EMarkerScope.item);
        crateMarkersContent1.layer = layer;
        crateMarkersContent1.icon = item.object.marker;
        crateMarkersContent1.uid = GameUtils.getDefaultObjectUid(item.object.id, item.object.genericCode);
        return crateMarkersContent1;
    }


    /**
     * format mp user marker
     * check show user marker flag
     * @param member
     * @param layer
     */
    getGroupMemberPlaceMarker(member: IGroupMember, layer: string): IPlaceMarkerContent {
        if (!member) {
            return null;
        }
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromGroupMember(member);
        if (!gMarkerContent) {
            return null;
        }
        MarkerUtils.setMarkerDisplayOptions(gMarkerContent, EMarkerScope.item);
        gMarkerContent.layer = layer;
        if (member.user && UserDataService.checkFlag(member.user.settings, ESettingsCode.showUserMarkerMp) === 1) {
            gMarkerContent.icon = member.user.photoUrl;
        } else {
            gMarkerContent.icon = EMarkerIcons.mpUser;
        }
        gMarkerContent.uid = "/MP/USER/" + member.userId;
        return gMarkerContent;
    }

    /**
     * format mp user marker
     * check show user marker flag
     * @param member
     * @param layer
     */
    getGroupMemberDronePlaceMarker(member: IGroupMember, layer: string, droneIcon: string): IPlaceMarkerContent {
        if (!member) {
            return null;
        }
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromGroupMember(member);
        if (!gMarkerContent) {
            return null;
        }
        gMarkerContent.detailsOpenContext.title = "Drone";
        if (member && member.dynamic && member.dynamic.status) {
            gMarkerContent.location.lat = member.dynamic.status.droneLat;
            gMarkerContent.location.lng = member.dynamic.status.droneLng;
        }
        if (gMarkerContent.location.lat == null || gMarkerContent.location.lng == null) {
            return null;
        }
        this.setDroneMarkerSpecs(gMarkerContent, layer, droneIcon);
        gMarkerContent.uid = "/MP/USER-DRONE/" + member.userId;
        return gMarkerContent;
    }


    /**
     * format user marker
     * @param layer 
     * @param username 
     * @param userIcon 
     */
    getUserPlaceMarker(layer: string, username: string, userIcon: string): IPlaceMarkerContent {
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataForUser(username);
        MarkerUtils.setMarkerDisplayOptions(gMarkerContent, EMarkerScope.item);
        gMarkerContent.layer = layer;
        gMarkerContent.icon = userIcon;
        gMarkerContent.zindex = MarkerPriority.getMarkerPriority(EMarkerPriority.user);
        gMarkerContent.priority = EMarkerRenderPriority.user;
        gMarkerContent.uid = "/GENERAL/USER";
        return gMarkerContent;
    }

    getDronePlaceMarker(layer: string, username: string, droneIcon: string): IPlaceMarkerContent {
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataForUser(username);
        this.setDroneMarkerSpecs(gMarkerContent, layer, droneIcon);
        gMarkerContent.uid = "/GENERAL/USER-DRONE";
        return gMarkerContent;
    }

    getUserNavMarker(layer: string, username: string, navIcon: string): IPlaceMarkerContent {
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataForUser(username);
        gMarkerContent.icon = navIcon;
        gMarkerContent.layer = layer;
        gMarkerContent.radius = 20;
        gMarkerContent.locked = false;
        gMarkerContent.mode = EMarkerTypes.canvasPlain;
        gMarkerContent.uid = "/GENERAL/USER-NAV";
        return gMarkerContent;
    }

    setDroneMarkerSpecs(gMarkerContent: IPlaceMarkerContent, layer: string, droneIcon: string): IPlaceMarkerContent {
        gMarkerContent.mode = EMarkerTypes.canvasPlain;
        // gMarkerContent.mode = EMarkerTypes.plain;
        MarkerUtils.setMarkerDisplayOptions(gMarkerContent, EMarkerScope.item);
        gMarkerContent.radius = 80;
        gMarkerContent.layer = layer;
        gMarkerContent.icon = droneIcon;
        gMarkerContent.zindex = MarkerPriority.getMarkerPriority(EMarkerPriority.drone);
        gMarkerContent.priority = EMarkerRenderPriority.drone;
        return gMarkerContent;
    }


    showContextMarkerModal(mk: IPlaceMarkerContent, context: IMarkerDetailsOpenContext): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {

            console.log("show context marker modal: ", mk, context);
            let params: IDescriptionFrameParams = {
                title: context.title,
                heading: context.heading,
                description: context.description,
                descriptionHeader: context.contextDescription,
                mode: EDescriptionViewStyle.plain,
                // photoUrl: context.photoUrl,
                photoUrl: null,
                loaderCode: null
            };

            if (context.collectibleParams != null) {
                if (context.collectibleParams.mode != null) {
                    params.audioGuide = context.collectibleParams.mode;
                    params.language = context.collectibleParams.lang;
                    params.voice = context.collectibleParams.voice;
                }
            }

            if (mk.appContext) {
                if (mk.appContext.collected) {
                    if (!mk.appContext.disabled) {
                        params.mode = EDescriptionViewStyle.withOk;
                    }
                    params.mode = EDescriptionViewStyle.withOk;
                    let message: string = "<p>Collected</p>";
                    if (message != null) {
                        params.descriptionHeader = params.descriptionHeader != null ? params.descriptionHeader + message : message;
                    }
                } else {
                    params.mode = EDescriptionViewStyle.withCustomButton;
                    params.customButton = {
                        name: "Collect",
                        code: null,
                        icon: EAppIconsStandard.magnet,
                        customIcon: false,
                        class: null,
                        size: null,
                        disabled: !mk.appContext.inRange,
                        enabled: true,
                        highlight: null,
                        callback: null
                    };
                    let message: string = null;
                    let collectMessage: string = "<p>Collect the item</p>";
                    let collectNotInRangeMessage: string = "<p>You are too far away</p>";
                    if (mk.appContext.mode != null) {
                        switch (mk.appContext.mode) {
                            case EExploreCoinAction.overlap:
                                collectMessage = "<p>Escape the followers</p>";
                                collectNotInRangeMessage = "";
                                params.customButton.name = "Avoid";
                                params.customButton.icon = EAppIconsStandard.alert;
                                break;
                        }
                    }
                    if (!mk.appContext.inRange) {
                        message = collectMessage + collectNotInRangeMessage;
                    }
                    if (message != null) {
                        params.descriptionHeader = params.descriptionHeader != null ? params.descriptionHeader + message : message;
                    }
                }
            }

            let promiseModal: Promise<any>;
            if (!(context.photoUrl || context.useModal)) {
                let description: string = "";
                if (context.contextDescription) {
                    description += "<p>" + context.description + "</p><p>" + context.contextDescription + "</p>";
                } else {
                    description = context.description;
                }
                promiseModal = this.uiext.showAlert(context.title, context.description, 1, ["Ok"]);
            } else {
                promiseModal = this.uiext.showCustomModal(null, DescriptionFrameViewComponent, {
                    view: {
                        fullScreen: false,
                        transparent: AppConstants.transparentMenus,
                        large: context.large,
                        addToStack: true,
                        frame: false
                    },
                    params: params
                });
            }
            promiseModal.then((res: number) => {
                if (res === EAlertButtonCodes.ok) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
        return promise;
    }
}




