
import { ILeplaceTreasure } from "../places/leplace";
import { IMarkerDetailsOpenContext, IPlaceMarkerContent } from "../map/map-data";
import { ICustomParamForActivity, IFixedCoin } from "./custom-param";
import { ILatLng } from "../map/coords";

/**
 * the definition of the object for the AR Engine
 */
export interface IObjectStoreElement {
    index?: number;
    id: number;
    object: string;
    projection: string;
}



export interface ILeplaceObjectGenerator {
    operation: number;
    container: ILeplaceObjectContainer;
}

/**
 * a standard container for objects that are shown either on the map or on the AR view
 */
export interface ILeplaceObjectContainer {
    location: ILatLng;
    object: ILeplaceObject;
    treasure: ILeplaceTreasure;
    placeMarker?: IPlaceMarkerContent;
    placeMarkerCircle?: IPlaceMarkerContent;
    /**
     * the object generator, used for selective/partial sync
     */
    providerCode: number;
    dynamic: {
        distance: number
    };
    lock?: boolean;
    /** the object will be fixed on minimap (e.g. quest item) */
    minimapLocked?: boolean;
    collected?: boolean;
    pickedUp?: boolean;
    fixedCoin?: IFixedCoin;
}

export interface ILeplaceTreasureObjects {
    crate: ILeplaceObject;
    storyExplore: ILeplaceObject;
    storySport: ILeplaceObject;
    storyCasual: ILeplaceObject;
    arena: ILeplaceObject;
}

export interface IPrepareCoinSpecs {
    position: ILatLng;
    specIndex: number;
    customParamCode?: number,
    fixedCoinIndex?: number,
    minted?: boolean;
    scope?: number;
    circle?: number;
}

export enum EPrepareCoinCircle {
    none = 0,
    collect = 1,
    avoid = 2,
    follow = 3
}

export interface ILeplaceObjectCallbackData extends IMarkerDetailsOpenContext {
   
}

/**
 * this should not be a nested object
 * because it's used with object.assign in AR object creation
 */
export interface ILeplaceObject {
    /**
     * the local id (around a place)
     */
    id: number;
    /**
     * the code/type of the object e.g. coin
     */
    code: number;
    /**
     * the object type code e.g. collect item
     */
    genericCode: number;
    /**
     * the unique identified: place_id/code/id
     * used for AR and minimap unique object identifier
     */
    uid: string;
    // treasureSpec: ITreasureSpec
    /**
     * a user friendly name for the object
     */
    name: string;
    /**
     * the mode of display e.g. full spec/just color object
     */
    mode: number;
    /**
     * the link to the .obj file
     */
    obj: string;
    /**
     * the link to the .mtl file
     */
    mtl: string;
    /**
     * the link to the texture file
     */
    photo: string;
    /**
     * the link to the marker icon
     */
    marker: string;
    /**
     * the color of the object e.g. for just color object mode
     */
    color: string;
    /**
     * continuous animation
     */
    animate: number;

    /**
     * show/hide object from AR
     */
    show: boolean;

    /**
    * show/hide object from AR w/o distance check
    */
    available: boolean;

    /**
     * completely hide object from AR and minimap
     */
    showMinimap: boolean;

    /**
     * e.g. explore coin specs callback
     */
    callback?: (data: IPlaceMarkerContent) => any;
    callbackData?: ILeplaceObjectCallbackData,
    /**
     * fixed object (derived from treasure)
     */
    locked: number;
    /**
     * object altitude, from user level, centimeters
     */
    altitude: number;
    /**
     * keep reference for matching with fixed coins
     */
    customParamCode?: number;
    /**
     * keep reference for matching with fixed coins
     */
    customParamValue?: number
    /**
     * keep reference for items collected checkout modal
     */
    customParamReference?: ICustomParamForActivity;
    /**
     * view distance scale (AR)
     */
    viewDistanceScale: number;
}

export interface IARObject {
    uid: string;
    x: number;
    y: number;
    lat?: number;
    lng?: number;
    data: ILeplaceObject;
}



/**
 * the params for adding an AR object relative to the user position
 */
export interface IARObjectCreateRelativeSpecs {
    /**
     * the id/uid is a combination of the object type and the actual id
     */
    uid: string;
    mode: number;
    angle: number;
    distance: number;
    specs: ILeplaceObject;
}

/**
 * the params for adding an AR object with absolute coords
 */
export interface IARObjectCreateAbsoluteSpecs {
    /**
     * the id/uid is a combination of the object type and the actual id
     */
    uid: string;
    location: ILatLng;
    specs: ILeplaceObject;
}

/**
 * the params for adding an AR object with absolute coords
 */
export interface IARObjectRemoveSpecs {
    /**
     * the id/uid is a combination of the object type and the actual id
     */
    uid: string;
    /**
     * animate before removing object from AR
     */
    animate: boolean;
    /**
     * check if object was collected or just removed
     */
    collected: boolean;
    name: string;
}


export enum EObjectModes {
    /**
     * the obj, mtl and (optional) texture files/color are specified
     */
    fullSpec = 1,
    /**
     * the obj and color is specified
     */
    objectWithColor = 2
}

// /**
//  * the types of objects that are available as map markers AND AR objects
//  */
// export enum EObjectCodes {
//     coin = 301,
//     crate = 0,
//     storyExplore = 101,
//     storySport = 102,
//     storyCasual = 103,
//     arena = 200
// }


export enum ECRUD {
    add = 1,
    remove = 2,
    update = 3
}

export interface IActionLayers {
    [key: string]: IActionLayer;
}

export interface IActionLayer {
    name: string;
    code: number;
    enabled: boolean;
    photoUrl?: string;
    type: number;
}

export enum EActionLayers {
    crates = "crates",
    stories = "stories",
    arenas = "arenas"
}

