import { Component, Input, OnInit, OnDestroy, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IStoryExtended, IStory, IStoryResponse, IMasterLockResponse, EStoryMode, EStoryReleaseFlag, EGameplayMode, IStoryUnlockCode, IDBModelLanguage } from 'src/app/classes/def/core/story';
import { IStoryCategory, ECategoryCodes } from 'src/app/classes/def/core/category';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IStoryListNavParams, IStoryRatingNavParams, EStoryProvider } from 'src/app/classes/def/nav-params/story';

import { IAppLocation, ELocationFlag } from 'src/app/classes/def/places/app-location';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { EStandardCode, EStandardStoryCode } from 'src/app/classes/def/core/activity';
import { MessageUtils } from 'src/app/classes/utils/message-utils';
import { StoryUtils, IStoryProgress, ICheckStoryProviders } from 'src/app/classes/utils/story-utils';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { ILocationContainer, IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IDescriptionFrameParams, EDescriptionViewStyle } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IGmapEntryNavParams, EGmapMode, IGmapNavParams } from 'src/app/classes/def/nav-params/gmap';
import { IModalEvent, EModalEmitter } from 'src/app/classes/def/app/modal-events';
import { GmapDetailPage } from '../../gmap-detail/gmap-detail.page';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { RatingUtils, IRatingView } from 'src/app/classes/def/views/rating';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { StoryDataService } from 'src/app/services/data/story';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { ModularViewsService } from 'src/app/services/utils/modular-views';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { BusinessDataService } from 'src/app/services/data/business';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModularInteractionService } from 'src/app/services/utils/modular-interaction';
import { LocalDataService } from 'src/app/services/data/local';
import { MiscDataService } from 'src/app/services/data/misc';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { StoryPrologueViewComponent } from 'src/app/modals/app/modals/story-prologue/story-prologue.component';
import { ERouteDef } from 'src/app/app-utils';
import { RatingFrameViewComponent } from 'src/app/modals/generic/modals/rating-frame/rating-frame.component';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { GameStatsService } from 'src/app/services/app/utils/game-stats';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { TextToSpeechService } from 'src/app/services/general/apis/tts';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlacesDataService } from 'src/app/services/data/places';
import { ILeaderboardExtNavParams } from 'src/app/classes/def/nav-params/leaderboard';
import { LeaderboardExtPage } from '../../leaderboard-ext/leaderboard-ext.page';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { LocationDispUtils } from 'src/app/services/location/location-disp-utils';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { WorldDataService } from 'src/app/services/data/world';
import { IWeatherData } from 'src/app/classes/def/world/weather';
import { EFAQCategories, SupportDataService } from 'src/app/services/data/support';
import { IFaqCategory } from 'src/app/classes/def/support/support';
import { SupportModalsService } from 'src/app/services/app/modules/minor/support-modals';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { EGroupContext, IGroup } from 'src/app/classes/def/mp/groups';
import { EArenaReturnCodes, IArenaReturnToMapData } from 'src/app/classes/def/nav-params/arena';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { MPGameInterfaceService } from 'src/app/services/app/mp/mp-game-interface';
import { LinksDataService } from 'src/app/services/data/links';
import { IMPGameSession, IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { MPGroupsHomePage } from '../../mp/mp-groups-home/mp-groups-home.page';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { GameStatsUtils } from 'src/app/services/app/utils/game-stats-utils';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { MPUtils } from 'src/app/services/app/mp/mp-utils';
import { MPGroupsListPage } from '../../mp/mp-groups-list/mp-groups-list.page';
import { IMPMessageDB } from 'src/app/classes/def/mp/message';
import { EMPEventSource, EMPMessageCodes, EMPUserInputCodes } from 'src/app/classes/def/mp/protocol';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { EOS, EServiceUrlCodes } from 'src/app/classes/def/app/app';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';
import { BackgroundModeService } from 'src/app/services/general/apis/background-mode';
import { LocalNotificationsService } from 'src/app/services/general/apis/local-notifications';
import { SoundUtils } from 'src/app/services/general/apis/sound-utils';
import { AppDiagnosticService } from 'src/app/services/general/app-diagnostic.service';
import { IDiagnosticStatus } from 'src/app/services/general/app-diagnostic.utils';
import { IGameContextStatus, EMQTTStatusKeys } from 'src/app/services/telemetry/def';
import { MQTTService } from 'src/app/services/telemetry/mqtt.service';
import { EDroneMode } from 'src/app/services/data/activities';
import { Util } from 'src/app/classes/general/util';
import { EAudioGuide } from 'src/app/classes/def/core/interactive-features';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-story-home',
  templateUrl: './story-home.page.html',
  styleUrls: ['./story-home.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class StoryHomePage implements OnInit, OnDestroy {
  @ViewChild('startButtonBar', { read: ElementRef, static: false }) startButtonBar: ElementRef<Element>;

  storyExt: IStoryExtended;
  story: IStory;
  eventGroupLinkData: IEventStoryGroupLinkData;

  storyId: number = 0;
  title: string = "Storyline";
  storyProgressRaw: number = 0;
  storyProgress: number = 0;
  storyLength: number = 0;
  inProgress: boolean = false;
  savedPlaces: number = 0;
  fixedPlaces: number = 0;
  randomPlaces: number = 0;
  finished: boolean = false;
  loaded: boolean = false;
  saved: boolean = false;
  dynamicStory: boolean = false;
  category: IStoryCategory;
  categoryCode: number = null;

  storyLoaded: boolean = false;
  startLocked: boolean = false;

  // reload ext request / internal flag
  reload: boolean = true;
  // reload on progress updated
  reloadProgress: boolean = false;

  entryPlace: ILeplaceReg = null;
  locationURL: string = null;

  useDefaultLocationPhotos: boolean = true;

  params: IStoryListNavParams;
  coords: ILatLng;

  startButtonText: string = "Start";

  showState: string = "inactive";
  timeout = {
    openMap: null,
    autoReload: null,
    notificationTimeout: null
  };

  theme: string = "theme-light theme-light-bg";

  mapStyleName: string = null;
  mapId: string = null;

  appLocations: IAppLocation[] = [];

  photoUrl: string = "";
  photoLoaded: boolean = false;
  animate: boolean = true;
  videoUrl: string = "";

  categoryName: string = "";

  /**
   * the story is locked
   */
  lockedState: boolean = true;

  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = false;

  levelDisp: string = "";
  rewardDisp: string = "";

  unlockInProgress: boolean = false;
  teamUnlockCode: IStoryUnlockCode = null;
  unlockCodeShortLength: number = 5;
  teamUnlockCodeLoaded: boolean = false;
  unlockCodeStatsTable: IKVStat[] = null;

  storyProviders: IBackendLocation[] = [];
  hasProviders: boolean = false;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  isBeta: boolean = false;
  isDev: boolean = false;

  allFixedLocations: boolean = false;

  descriptionFooter: string = "<scroll down to start>";
  descriptionSwitchLanguage: string = "<switch language>";
  isMultiLanguage: boolean = false;
  langs: IDBModelLanguage[] = null;

  hasWeather: boolean = false;
  wd: IWeatherData;
  extDataLoaded: boolean = false;
  faqCategories: IFaqCategory[];

  weatherUnitsCelsius: boolean = true;
  refreshWeather: boolean = false;

  withTeams: boolean = false;
  withTeamsSelected: boolean = false;
  gameplayMode: number = EGameplayMode.single;
  gameplayModeDispInit: boolean = false;

  selectedIndex: number = EGameplayMode.single;
  categoryTabsGroupCount: number = 2;

  navBarItems: INavBarItem[] = [{
    name: "Single",
    value: EGameplayMode.single
  }, {
    name: "Teams",
    value: EGameplayMode.teams
  }];

  gameModeDescription: string = "";

  connectedGroup: IGroup = null;
  quickJoinCode: string = "";
  withQuickJoin: boolean = false;

  lockPreview: boolean = false;
  groupIdCache: number = null;

  subscription = {
    groupStatus: null,
    groupMessage: null
  };

  autoReload: boolean = false;
  autoReloadPrev: boolean = false;
  loadingTimeout: number = 20;
  resetLoadingTimeout: boolean = false;
  showProgressReload: boolean = true;

  isCheckpointPreviewOpen: boolean = false;
  isLobbyOpen: boolean = false; // skip showing story preview warning while in lobby (or leaderboard)

  inputFocused: boolean = false;

  loggedInMessage: string = "";

  enterMap: boolean = false;
  fromMapOpened: boolean = false;
  withAudioGuide: boolean = false;
  audioGuideAutoplay: boolean = false;

  qparams: Params;

  connectLink: string = null;
  withConnect: boolean = false;
  withConnectLabel: string = "<p>You are using the web client (which is good for convenience).</p><p>You can download the mobile app for a smoother and uninterrupted experience.</p>";
  withConnectButtonLabel: string = "<view more>";

  languageDisp: IDBModelLanguage = null;
  selectedTranslationId: number = null;

  descriptionPlain: string = null;

  @Input()
  public showChild: boolean = true;

  constructor(
    public router: Router,
    public storyDataProvider: StoryDataService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public analytics: AnalyticsService,
    public locationManager: LocationManagerService,
    public popoverCtrl: PopoverController,
    public backButton: BackButtonService,
    public modularViews: ModularViewsService,
    public resourcesProvider: ResourcesCoreDataService,
    public businessDataProvider: BusinessDataService,
    public settingsProvider: SettingsManagerService,
    public modularInteraction: ModularInteractionService,
    public localDataProvider: LocalDataService,
    public miscProvider: MiscDataService,
    public modalCtrl: ModalController,
    public events: Events,
    public popupFeatures: PopupFeaturesService,
    public supportModals: SupportModalsService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public gameStats: GameStatsService,
    public tts: TextToSpeechService,
    public soundManager: SoundManagerService,
    public placesData: PlacesDataService,
    public worldService: WorldDataService,
    public support: SupportDataService,
    public mpManager: MPManagerService,
    public mpGameInterface: MPGameInterfaceService,
    public links: LinksDataService,
    public mpDataService: MPDataService,
    public backgroundModeProvider: BackgroundModeService,
    public localNotifications: LocalNotificationsService,
    public appDiagnostic: AppDiagnosticService,
    public mqttService: MQTTService,
    public route: ActivatedRoute
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {
    this.analytics.trackView("story-home");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.storyHome, null, this.np);

      this.qparams = this.route.snapshot.queryParams;
      console.log("activated route: ", this.qparams);

      console.log("nav params: ", npInfo.params);
      let npar = npInfo.params;
      this.unlockCodeShortLength = AppConstants.gameConfig.unlockCodeLength;

      let hasParams: boolean = npInfo.hasParams;
      if (hasParams) {
        console.log(npar);
        let np: INavParams = npar;
        if (np && np.params) {
          this.params = np.params;
        }
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;

        this.storyId = this.params.storyId;
        this.dynamicStory = this.params.dynamic;
        this.category = this.params.category;
        this.categoryCode = this.params.categoryCode;
        this.reload = this.params.reload;
        this.lockPreview = (this.params.lockPreview != null) ? this.params.lockPreview : false;
        this.fromMapOpened = this.params.fromMapOpened;

        if (this.params.reloadProgress != null) {
          this.reloadProgress = this.params.reloadProgress;
        }
        this.entryPlace = this.params.entryPlace;
        this.eventGroupLinkData = this.params.links;

        if (this.category != null) {
          this.categoryName = this.category.name.toUpperCase();
        }

        if (!this.reload || this.isModal) {
          // this.animate = false;
          // this.showState = 'active';
        }

        if (this.params.storyOverview) {
          this.photoUrl = this.params.storyOverview.photoUrl;
          this.videoUrl = this.params.storyOverview.videoUrl;
          this.story = this.params.storyOverview;
          this.groupIdCache = this.story.localCache != null ? this.story.localCache.connectedGroupId : null;
          console.log("group id cache: ", this.groupIdCache);
          if (this.groupIdCache != null) {
            PromiseUtils.wrapNoAction(this.reloadGroupSpec(this.groupIdCache, false), true);
          }
        }
      } else {
        this.storyId = 0;
        this.dynamicStory = false;
        this.category = null;
      }

      if (this.qparams && (Object.keys(this.qparams).length > 0)) {
        try {
          this.storyId = Number.parseInt(this.qparams.storyId);
          this.dynamicStory = !(this.qparams.dynamic === 'true');
          this.categoryCode = Number.parseInt(this.qparams.categoryCode);
        } catch (err) {
          console.error(err);
        }
      }

      let gcStatus: IGameContextStatus = {
        storyId: this.storyId,
        worldMap: false,
        previewMode: true
      };
      this.mqttService.updateStatus(EMQTTStatusKeys.gameContext, gcStatus);

      this.setGameModeContext(this.gameplayMode, false, false);
      this.updateLoggedInMessage();

      // can be opened either as a modal or a root page
      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back(true);
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then(async (res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          // load story data

          try {
            this.coords = await this.locationManager.getCurrentLocationWrapper(true, true, true);
          } catch (err) {
            console.error(err);
            let res = await this.supportModals.showLocationErrorModal(err);
            if (res) {
              this.coords = await this.locationManager.getCurrentLocationWrapper(true, true, true);
            }
          }

          if (this.fromMapOpened) {
            // opened from gmap (already started)
            this.soundManager.ttsCodeWrapper(ETutorialEntries.ttsStory, true);
          }

          if (!this.fromMapOpened) {
            // opened from storyline (not started yet, preview mode)
            if (SettingsManagerService.settings.app.settings.backgroundMode.value) {
              this.backgroundModeProvider.enable();
            }
          }

          this.startNotificationTimeout();
          this.loadStoryWrapper(false).then(() => {
            PromiseUtils.wrapNoAction(this.loadExtData(), true);
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  startNotificationTimeout() {
    this.timeout.notificationTimeout = ResourceManager.clearTimeout(this.timeout.notificationTimeout);
    this.timeout.notificationTimeout = setTimeout(() => {
      if (this.isLobbyOpen) {
        this.startNotificationTimeout(); // reset timeout
        return;
      }
      this.localNotifications.notify(Messages.notification.previewMode.after.msg, Messages.notification.previewMode.after.sub, false, null);
      this.soundManager.vibrateContext(false);
      this.soundManager.ttsWrapper(Messages.tts.previewMode, true, SoundUtils.soundBank.worldMapIntro.id);
      this.uiext.showAlert(Messages.msg.storyPreviewNotification.after.msg, Messages.msg.storyPreviewNotification.after.sub, 1, null, true).then(async (res: number) => {
        if (res === EAlertButtonCodes.ok) {
          if (this.isCheckpointPreviewOpen) {
            this.events.publish(EModalEvents.dismissCheckpointPreviewMode, null);
            await SleepUtils.sleep(500);
          }
          this.scrollToStart();
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }, (GeneralCache.os !== EOS.browser) ? 180 * 1000 : 180 * 1000);
  }

  stopNotificationTimer() {
    this.timeout.notificationTimeout = ResourceManager.clearTimeout(this.timeout.notificationTimeout);
  }

  setGameModeContext(gm: number, apply: boolean, autoScroll: boolean) {
    console.log("set game mode context");
    if ((gm != this.gameplayMode) || !this.gameplayModeDispInit) {
      console.log("set game mode context apply");
      this.gameplayMode = gm;
      this.gameplayModeDispInit = true;
      this.updateLoggedInMessage();
      switch (this.gameplayMode) {
        case EGameplayMode.single:
          this.gameModeDescription = `<p>Play the interactive story in single player mode.</p><p>> You can play right away.</p><p>> Your score will be registered on your player account.</p><p>> Suitable for single players or small groups.</p>`;
          this.resetGroupMode();
          if (autoScroll) {
            this.scrollToStart();
          }
          if (apply) {
            this.quitMpSession();
          }
          break;
        case EGameplayMode.teams:
          this.gameModeDescription = `<p>Play the interactive story in multiplayer mode.</p><p>> You will have to create a team or join an existing one.</p><p>> Your score will be registered on your team account.</p><p>> Suitable for team buildings or large groups.</p>`;
          this.withTeamsSelected = true;
          if (autoScroll) {
            this.scrollToStart();
          }
          break;
        default:
          this.resetGroupMode();
          if (autoScroll) {
            this.scrollToStart();
          }
          break;
      }
    }
  }

  resetGroupMode() {
    this.toggleAutoReload(false);
    this.withTeamsSelected = false;
    this.quickJoinCode = null;
    this.updateQuickJoin();
  }

  prepareGroupStoryProgress(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      console.log("request load story progress");
      console.log("connected group: ", this.connectedGroup);
      if (!this.connectedGroup) {
        resolve(false);
      }
      this.links.setStoryGroupLinkDataWrapper(this.connectedGroup.id, this.storyId, true);
      await PromiseUtils.wrapResolve(this.loadStoryProgressOnly(), true);
      resolve(true);
    });
    return promise;
  }

  /**
  * check story progress from server (lighter than loading story w/ full detail)
  */
  loadStoryProgressOnly(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      console.log("request load story progress");
      // console.log("initial progress: ", JSON.parse(JSON.stringify(this.story)));
      this.storyDataProvider.loadStoryProgressOnly(this.storyId, null, null).then((data: IStoryResponse) => {
        let formattedStory: IStory = LocationUtils.formatStoryLocMulti(data.story);
        // console.log("formatted story: ", JSON.parse(JSON.stringify(formattedStory)));
        this.story = LocationUtils.mergeProgress(this.story, formattedStory);
        // console.log("merge progress: ", JSON.parse(JSON.stringify(this.story)));
        this.storyExt = null;
        this.storyLoaded = false;
        this.loadStoryCore(this.story);
        this.storyLoaded = true;
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  ngOnDestroy() {
    this.clearTimers();
    this.clearSubscriptions();
    this.backButton.checkPop(this.vs);
    if (this.params != null && !this.params.fromMapOpened && !this.enterMap) {
      if (SettingsManagerService.settings.app.settings.autoBgMode.value) {
        this.backgroundModeProvider.disable();
      }
    }
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  async loadExtData() {
    try {
      let isVirtual: boolean = this.story.droneOnly === EDroneMode.onlyDrone;
      let category: number = EFAQCategories.stories;
      if (isVirtual) {
        category = EFAQCategories.storiesVirtual;
      }
      let cats: IFaqCategory[] = await this.support.getFaqV2([category]);
      this.faqCategories = cats;
      if (!isVirtual) {
        this.hasWeather = true;
        await this.loadWeatherData();
      }
      this.extDataLoaded = true;
    } catch (err) {
      this.analytics.dispatchError(err, "story-home");
      console.error(err);
    }
  }

  switchWeatherUnits(celsius: boolean) {
    console.log("switch weather units: ", celsius);
    this.weatherUnitsCelsius = celsius;
    PromiseUtils.wrapNoAction(this.loadWeatherData(), true);
  }

  async loadWeatherData() {
    let wd: IWeatherData = await this.worldService.getWeatherData(this.coords ? this.coords.lat : null, this.coords ? this.coords.lng : null, this.weatherUnitsCelsius);
    if (wd) {
      this.wd = wd;
    }
    this.refreshWeather = !this.refreshWeather;
  }

  loadStoryWrapper(unlock: boolean) {
    let promise = new Promise((resolve) => {
      if (unlock && this.unlockInProgress) {
        console.warn("unlock already in progress");
        resolve(null);
        return;
      }
      this.unlockInProgress = true;
      this.loadStoryFromSource(this.storyId, this.reload, 1, unlock).then(() => {
        this.unlockInProgress = false;
        if (this.animate) {
          setTimeout(() => {
            this.showState = 'active';
          }, 100);
        }
        resolve(true);
      }).catch((err: Error) => {
        this.unlockInProgress = false;
        this.analytics.dispatchError(err, "story-home");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        resolve(null);
      });
    });
    return promise;
  }

  loadStoryFromSourceHandleTranslation(storyId: number, reload: boolean, retryCount: number, unlock: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.loadStoryFromSource(storyId, reload, retryCount, unlock);
        if (this.selectedTranslationId != null) {
          await this.loadTranslation(this.selectedTranslationId);
        }
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  }
  /**
   * load story from the backend or local storage
   * @param storyId 
   */
  loadStoryFromSource(storyId: number, reload: boolean, retryCount: number, unlock: boolean) {
    console.log("load story, " + storyId + ", reload: ", reload);
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;

      if (retryCount < 0) {
        reject(new Error("Story content not available"));
        return;
      }
      let afterUnlocked = (story: IStory) => {
        // release the lock state
        this.lockedState = false;
        if (!story.locations) {
          // retry load story from server, try to unlock
          return this.loadStoryFromSource(storyId, reload, retryCount - 1, false);
        }
        this.loadStoryCore(story);
        this.mapStyleName = "standard";
        if (story.mapStyle) {
          if (story.mapStyle.name) {
            this.mapStyleName = story.mapStyle.name;
          }
          this.mapId = story.mapStyle.mapId;
        }
        if (reload) {
          this.loadMapStyle(this.mapStyleName);
        }
      };
      if (this.params && (this.params.useLoadedStory && this.params.storyOverview != null)) {
        this.story = this.params.storyOverview;
        afterUnlocked(this.story);
        // resolve anyway
        this.loaded = true;
        resolve(true);
      } else {
        // get location only if the story is dynamic, requiring the coordinates
        // of the user to compute the dynamic locations
        this.storyDataProvider.getCurrentOpenStory(storyId, this.coords, reload).then(async (data: IStoryResponse) => {
          if (!data.locked) {
            afterUnlocked(data.story);
          } else {
            this.lockedState = true;

            // this.getStandardPhoto();
            // check the master lock
            // proceed to unlock the story (with coins)
            if (unlock) {
              console.log("check master lock");
              this.popupFeatures.openStoryUnlock(data.story, data.masterLock, false, this.coords, null).then((resp: IMasterLockResponse) => {
                if (resp && resp.unlocked) {
                  afterUnlocked(data.story);
                }
              }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "story-home");
                this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
              });
            }
          }
          // resolve anyway

          this.withTeams = this.story.teams === 1;
          this.connectLink = this.story.connectLink;
          if (GeneralCache.isWeb) {
            if (this.connectLink != null) {
              this.withConnect = true;
            }
          }

          if (this.story.teamPurchase === 1) {
            await this.loadTeamUnlockCodes(reload);
          }
          this.loaded = true;
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          this.loaded = true;
          reject(err);
        });
      }
    });
    return promise;
  }

  loadTeamUnlockCodes(reload: boolean) {
    return new Promise((resolve) => {
      if (this.withTeams && (reload || !this.teamUnlockCodeLoaded)) {
        // check team unlock code
        this.storyDataProvider.getStoryUnlockCodes(this.storyId).then((storyUnlock: IStoryUnlockCode[]) => {
          if (storyUnlock && (storyUnlock.length > 0)) {
            this.teamUnlockCode = storyUnlock[0];
            this.unlockCodeStatsTable = [];
            this.unlockCodeStatsTable.push({
              name: "Status",
              value: this.teamUnlockCode.enabled,
              valueString: this.teamUnlockCode.enabled ? "Active" : "Expired"
            });
            this.unlockCodeStatsTable.push({
              name: "Unlocks",
              value: this.teamUnlockCode.credits,
              valueString: "" + this.teamUnlockCode.credits
            });
            this.unlockCodeStatsTable.push({
              name: "Available",
              value: this.teamUnlockCode.creditsLeft,
              valueString: "" + this.teamUnlockCode.creditsLeft
            });
          } else {
            this.teamUnlockCode = null;
            this.unlockCodeStatsTable = [];
          }
          this.teamUnlockCodeLoaded = true;
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "story-home");
          resolve(false);
        });
      } else {
        resolve(false);
      }
    });
  }

  getStandardPhoto() {
    this.resourcesProvider.getStandardPhotoByCode(EStandardCode.story + EStandardStoryCode.locked).then((photoUrl: string) => {
      this.photoUrl = photoUrl;
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  loadStoryCore(story: IStory) {
    if (story !== null) {

      if (this.isModal && !this.params.loadStory) {
        this.startButtonText = "Map";
      } else {
        if (story.mode === EStoryMode.mp) {
          this.startButtonText = "Unlock";
        } else {
          this.startButtonText = MessageUtils.getStartButtonText(StoryUtils.checkResumeStory(story));
        }
      }

      let levels: boolean[] = [];
      if (!story.islocal) {
        for (let i = 1; i <= 5; i++) {
          if (i <= story.level) {
            levels.push(true);
          } else {
            levels.push(false);
          }
        }
      }

      this.storyExt = {
        story: story,
        storyProgress: this.getStoryProgress(story),
        // photoUrl: this.getStoryPhotoUrl(story)
        photoUrl: null,
        color: null,
        hide: false,
        show: true,
        levels: levels,
        large: false,
        modeIcon: null,
        modeText: null
      };

      this.storyExt.modeIcon = StoryUtils.getModeIcon(story);
      // this.storyExt.modeText = StoryUtils.getModeText(story);

      let useDefaultPhoto: boolean = SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value;

      // add index
      this.storyExt.story = LocationUtils.formatStoryLocMulti(this.storyExt.story);
      this.appLocations = LocationUtils.getAppLocationsFromStory(this.storyExt.story, useDefaultPhoto);

      console.log("story home app locations: ", this.appLocations);

      this.storyExt.story = this.checkPlaces(this.storyExt.story);

      // show actual category (might open from global category)
      if (this.storyExt && this.storyExt.story && this.storyExt.story.categoryName) {
        this.categoryName = this.storyExt.story.categoryName.toUpperCase();
      }

      if (this.useDefaultLocationPhotos) {
        // override settings, use only default (challenge) photos here
        useDefaultPhoto = true;
      }

      this.getStoryProgress(this.storyExt.story);
      this.getStoryLocationTypes(this.storyExt.story);

      this.story = this.storyExt.story;
      this.descriptionPlain = StringUtils.textFromHTMLPlain(this.story.description);

      this.levelDisp = "Level " + this.story.level + " " + this.categoryName;
      this.rewardDisp = GameStatsUtils.getStoryRewardDisp(this.story);

      this.photoUrl = story.photoUrl;
      this.videoUrl = story.videoUrl;
      this.storyLoaded = true;

      this.isBeta = story.devOnly === EStoryReleaseFlag.unlisted;
      this.isDev = story.devOnly === EStoryReleaseFlag.devOnly;

      this.withAudioGuide = story.audioGuide != null && story.audioGuide !== 0;
      this.audioGuideAutoplay = story.audioGuide === EAudioGuide.ttsAuto;
      this.tts.setTTSVoiceOptionsContent(this.story.language, null);

      if (this.story.language != null) {
        let lang: IDBModelLanguage = this.story.language;
        if (!lang.nameLocale) {
          lang.nameLocale = lang.name;
        }
        this.languageDisp = lang;
        this.checkLanguages();
      }

      this.allFixedLocations = true;
      for (let loc of this.appLocations) {
        if (loc.flag !== ELocationFlag.FIXED) {
          this.allFixedLocations = false;
        }
      }

      let res: ICheckStoryProviders = StoryUtils.checkLoadProvidersWizard(this.story, () => { return this.loadProviders(); });
      this.hasProviders = res.hasProviders;
      this.storyProviders = res.providers;
    }
  }

  async checkLanguages() {
    try {
      this.langs = await this.storyDataProvider.getAvailableTranslations(this.storyId);
      if (this.langs && this.langs.length > 0) {
        this.isMultiLanguage = true;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async switchLanguage() {
    try {
      console.log("switch language");
      if (!this.langs) {
        this.langs = await this.storyDataProvider.getAvailableTranslations(this.storyId);
      }
      let actions: IPopoverActions = {
        "1": {
          name: "English (US)",
          code: 1,
          icon: "https://leplace-storage.s3.eu-central-1.amazonaws.com/icons/flags/us.svg",
          customIcon: true,
          enabled: true
        }
      };
      for (let lang of this.langs) {
        actions["" + lang.id] = {
          name: lang.nameLocale,
          code: lang.id,
          icon: lang.icon,
          customIcon: true,
          enabled: true
        }
      }
      let result = await this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
        view: {
          fullScreen: false,
          transparent: AppConstants.transparentMenus,
          large: false,
          addToStack: true,
          frame: false
        },
        params: { actions: actions }
      });
      console.log("selected language: ", result);
      if (result != null) {
        await this.loadTranslation(result as number);
      }
    } catch (err) {
      console.error(err);
      this.loaded = true;
    }
  }

  async loadTranslation(id: number) {
    try {
      this.loaded = false;
      // this.storyLoaded = false;
      this.selectedTranslationId = id;
      let translation: IStory = await this.storyDataProvider.loadStoryTranslation(this.storyId, this.selectedTranslationId);
      StoryUtils.mergeTranslation(this.story, translation);
      this.withAudioGuide = false; // reset input
      await SleepUtils.sleep(10);
      this.loadStoryCore(this.story);
      // this.storyLoaded = true;
      this.loaded = true;
    } catch (err) {
      console.error(err);
      this.loaded = true;
    }
  }

  loadProviders() {
    this.storyProviders = [];
    this.placesData.viewStoryProviders(this.story.id).then((places: ILocationContainer[]) => {
      if (places && places.length) {
        for (let i = 0; i < places.length; i++) {
          this.storyProviders.push(places[i].db);
        }
        this.hasProviders = true;
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  getPlaceDetails(index) {
    console.log(index);
  }


  /**
   * load map style into local storage (download from server)
   * @param mapStyleName 
   */
  loadMapStyle(mapStyleName: string) {
    this.resourcesProvider.getMapStyle(mapStyleName).then((_mapStyle) => {
      // console.log(mapStyle);

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkPlaces(story: IStory) {
    this.saved = false;
    for (let i = 0; i < story.locations.length; i++) {
      story.locs[i].merged.hidden = LocationDispUtils.checkHiddenPlace(story.locs[i], false);
      if (story.locs[i].merged.flag === ELocationFlag.SAVED) {
        this.saved = true;
      }
    }
    return story;
  }


  getStoryProgress(story: IStory): number {
    if (!story.locations) {
      return null;
    }
    let sp: IStoryProgress = StoryUtils.getStoryProgress(story);
    this.storyLength = sp.storyLength;
    this.storyProgress = sp.progress;
    this.storyProgressRaw = sp.progressRaw;
    this.inProgress = sp.inProgress;
    this.finished = sp.finished;
    return this.storyProgress;
  }

  getStoryLocationTypes(story: IStory) {
    this.randomPlaces = 0;
    this.fixedPlaces = 0;
    this.savedPlaces = 0;
    for (let i = 0; i < story.locations.length; i++) {
      let type = LocationUtils.getLocationType(story.locs[i]);
      switch (type) {
        case ELocationFlag.RANDOM:
          this.randomPlaces++;
          break;
        case ELocationFlag.FIXED:
          this.fixedPlaces++;
          break;
        case ELocationFlag.SAVED:
        case ELocationFlag.SAVED_LOCAL:
          this.savedPlaces++;
          break;
      }
    }
  }

  clearTimers() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }

  clearSubscriptions() {
    this.subscription = ResourceManager.clearSubObj(this.subscription);
  }

  clearProgressCore() {
    this.reloadProgress = true;
    console.log("reload progress set: ", this.reloadProgress);
    this.mpGameInterface.dispatchClearStoryProgressToMP();
  }

  clearProgressRefresh(prompt: boolean): Promise<boolean> {
    return this.clearDataWrapperRefresh(() => this.modularInteraction.clearProgressPromise(this.storyExt.story, prompt));
  }

  clearPlacesRefresh(prompt: boolean): Promise<boolean> {
    return this.clearDataWrapperRefresh(() => this.modularInteraction.clearPlacesPromise(this.storyExt.story, prompt));
  }

  clearDataWrapperRefresh(action: () => Promise<any>): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.connectedGroup != null) {
          let res: number = await this.uiext.showAlert(Messages.msg.applyForGroup.before.msg, Messages.msg.applyForGroup.before.sub, 2, null);
          if (res !== EAlertButtonCodes.ok) {
            resolve(false);
            return;
          }
        }
        await action();
        this.clearProgressCore();
        await PromiseUtils.wrapResolve(this.loadStoryFromSourceHandleTranslation(this.storyId, true, 1, false), true);
        resolve(true);
      } catch (err) {
        this.analytics.dispatchError(err, "story-home");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        reject(err);
      }
    });
  }

  /**
   * open map with option to clear saved places or continue
   */
  openMapOptions() {
    let params: IDescriptionFrameParams = {
      title: "Prologue",
      description: "<p>Begin the story</p><p>Continue the story or reset from the beginning</p>",
      mode: null,
      // loaderCode: ETutorialEntries.storyTutorial,
      loaderCode: null,
      photoUrl: this.photoUrl,
      buttons: [{
        name: "Reset",
        icon: null,
        class: null,
        enabled: true,
        size: 1,
        callback: null,
        highlight: false,
        disabled: false,
        code: EAlertButtonCodes.aux1
      }, {
        name: "Continue",
        icon: null,
        class: null,
        enabled: true,
        size: 1,
        callback: null,
        highlight: false,
        disabled: false,
        code: EAlertButtonCodes.ok
      }]
    };

    if (this.connectedGroup != null) {
      params.description += "<p>Note: Reset progress not available in Teams mode / only available from the settings menu.</p>";
      params.buttons[0].disabled = true;
    }

    this.uiext.showCustomModal(null, StoryPrologueViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((res: number) => {
      console.log(res);
      if (res == null) {
        return;
      }
      switch (res) {
        case EAlertButtonCodes.ok:
          // continue (right)
          this.openMap();
          break;
        case EAlertButtonCodes.aux1:
          // reset (left)
          this.clearPlacesRefresh(false).then((proceed: boolean) => {
            if (proceed) {
              this.openMap();
            }
          }).catch((err: Error) => {
            console.error(err);
          });
          break;
        case EAlertButtonCodes.cancel:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  getOpenMapParams() {
    if (this.story && this.params) {
      this.params.storyOverview = this.story;
    }
    let params: IStoryListNavParams = {
      storyId: this.storyId,
      dynamic: this.dynamicStory,
      reload: false,
      category: this.category,
      localStories: this.params ? this.params.localStories : false,
      includeGlobal: this.params ? this.params.includeGlobal : true,
      categoryCode: this.params ? this.params.categoryCode : null,
      mapStyle: this.mapStyleName,
      mapId: this.mapId,
      selectedCityId: this.params ? this.params.selectedCityId : null,
      loadStory: this.params ? this.params.loadStory : true,
      links: this.eventGroupLinkData,
      storyOverview: this.params ? this.params.storyOverview : null
    };
    return params;
  }

  /**
   * open map and tell it to load the story
   */
  openMap() {
    // increment story count only if not already in progress 
    if (this.animate) {
      this.showState = "inactive";
    }
    this.timeout.openMap = setTimeout(() => {
      if (this.isModal) {
        this.dismissModal(false, true);
      } else {

        let sparams: IStoryListNavParams = this.getOpenMapParams();
        let params: IGmapEntryNavParams = {
          mode: EGmapMode.storyline,
          storyNavParams: sparams
        };

        let navParams: INavParams = {
          view: null,
          params: params
        };

        let queryParams: Params = { mode: EGmapMode.storyline, storyId: "" + this.storyId };
        this.nps.set(ENavParamsResources.gmap, navParams);
        let connectedGroupId: number = this.connectedGroup != null ? this.connectedGroup.id : null;
        this.storyDataProvider.cacheResumeStory(connectedGroupId);
        this.enterMap = true;
        this.router.navigate([ERouteDef.gmap], { replaceUrl: true, queryParams: queryParams }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }, this.animate ? AppConstants.animationTime : 0);
  }



  /**
   * open map with option to clear saved places or continue
   */
  restartAndOpenMap() {
    let params: IDescriptionFrameParams = {
      title: "Prologue",
      description: this.allFixedLocations ? "<p>Begin the story</p><p>Reset from the beginning</p>" : "<p>Begin the story</p><p>Replay the story with the same locations or reset from the beginning</p>",
      mode: null,
      // loaderCode: ETutorialEntries.storyTutorial,
      loaderCode: null,
      photoUrl: this.photoUrl,
      buttons: [{
        name: "Reset",
        icon: null,
        class: null,
        enabled: true,
        size: 1,
        callback: null,
        highlight: false,
        disabled: false,
        code: EAlertButtonCodes.aux1
      }]
    };

    if (!this.allFixedLocations) {
      params.buttons.push({
        name: "Replay",
        icon: null,
        class: null,
        enabled: true,
        size: 1,
        callback: null,
        highlight: false,
        disabled: false,
        code: EAlertButtonCodes.ok
      });
    }

    let promise: Promise<boolean>;

    this.uiext.showCustomModal(null, StoryPrologueViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((res: number) => {
      console.log(res);
      if (res == null) {
        return;
      }
      switch (res) {
        case EAlertButtonCodes.ok:
          // replay (right)
          promise = this.clearProgressRefresh(false);
          break;
        case EAlertButtonCodes.aux1:
          // reset (left)
          promise = this.clearPlacesRefresh(false);
          break;
        case EAlertButtonCodes.cancel:
        default:
          promise = Promise.resolve(false);
          break;
      }
      promise.then((proceed: boolean) => {
        if (proceed) {
          this.openMap();
        }
      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "story-home");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * back to map, no action
   * @param animate 
   */
  back(animate: boolean) {
    let params: IStoryListNavParams = {
      storyId: this.storyId,
      dynamic: this.dynamicStory,
      reload: false,
      category: this.category,
      categoryCode: this.params ? this.params.categoryCode : null,
      loadStory: false,
      selectedCityId: this.params ? this.params.selectedCityId : null,
      localStories: this.params ? this.params.localStories : false,
      includeGlobal: this.params ? this.params.includeGlobal : true,
      links: this.eventGroupLinkData
    };

    // this.showState = "inactive";
    // this.timeout = setTimeout(() => {
    //   this.navCtrl.setRoot(StoryListPage, params).then(() => {
    //   }).catch((err: Error) => {
    //     console.error(err);
    //   });
    // }, Constants.ANIMATION_TIME);
    // console.log(params);
    if (this.isModal) {
      // if (!params.parentView) {
      //   params.parentView = StoryListPage;
      // }
      let modalEvent: IModalEvent = {
        emitter: EModalEmitter.storyDetailBack
      };
      this.events.publish(EModalEvents.dismiss, modalEvent);
      this.dismissModal(animate, false);
    } else {
      params.reload = this.reloadProgress;
      console.log("reload progress: ", this.reloadProgress);
      let navParams: INavParams = {
        view: null,
        params: params
      };
      this.quitMpSession();
      this.nps.set(ENavParamsResources.storyList, navParams);
      let queryParams: Params = { categoryCode: "" + params.categoryCode, city: params.selectedCityId };
      this.router.navigate([ERouteDef.storyList], { replaceUrl: true, queryParams: queryParams }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * return from modal
   * load story flag shows if the map should load the current story
   * @param animate 
   * @param loadStory 
   */
  dismissModal(_animate: boolean, loadStory: boolean) {
    let params: IStoryListNavParams = {
      storyId: this.storyId,
      dynamic: this.dynamicStory,
      reload: false,
      category: this.category,
      categoryCode: this.params ? this.params.categoryCode : null,
      loadStory: loadStory,
      selectedCityId: this.params ? this.params.selectedCityId : null,
      localStories: this.params ? this.params.localStories : false,
      includeGlobal: this.params ? this.params.includeGlobal : true,
      links: this.eventGroupLinkData
    };
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  getLocationDetailsForIndexOnClick(index: number) {
    this.getLocationDetailsViewForIndex(index, false);
  }

  getLocationDetailsViewForIndex(index: number, _block: boolean = false) {
    if (this.lockPreview) {
      this.uiext.showAlertNoAction(Messages.msg.storyPreviewMode.after.msg, Messages.msg.storyPreviewMode.after.sub);
      return;
    }
    let appLocation: IAppLocation = Object.assign({}, this.appLocations[index]);
    let params: IGmapNavParams = {
      location: appLocation,
      story: this.storyExt.story,
      photoValidated: false,
      treasure: null,
      fromGmap: false,
      interactions: null
    };
    this.isCheckpointPreviewOpen = true;
    this.uiext.showCustomModal(null, GmapDetailPage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    }).then(() => {
      this.isCheckpointPreviewOpen = false;
      this.backButton.pushOrReplace(() => {
        this.back(true);
      }, this.vs);
    }).catch((err: Error) => {
      console.error(err);
      this.isCheckpointPreviewOpen = false;
    });
  }

  openConnectPage() {
    Util.openURLExt(this.story.connectLink);
  }

  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      showDetails: {
        name: "Tutorial",
        code: 3,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      clearProgress: {
        name: "Clear progress",
        code: 0,
        icon: EAppIconsStandard.repeat,
        enabled: true
      },
      clearPlaces: {
        name: "Clear places",
        code: 1,
        icon: EAppIconsStandard.clearSavedPlaces,
        enabled: true
      },
      rate: {
        name: "Review",
        code: 4,
        icon: EAppIconsStandard.rate,
        enabled: true
      },
      reload: {
        name: "Reload",
        code: 2,
        icon: EAppIconsStandard.refresh,
        enabled: !this.connectedGroup
      }
    };

    if (GeneralCache.canBeTester) {
      let actionsTester: IPopoverActions = {
        removeUserStoryLink: {
          name: "Relock*",
          code: 11,
          icon: EAppIconsStandard.remove,
          enabled: true
        }
      };
      actions = Object.assign(actions, actionsTester);
    }

    if (this.categoryCode === ECategoryCodes.private) {
      actions.unsubscribe = {
        name: "Unsubscribe",
        code: 5,
        icon: EAppIconsStandard.exit,
        enabled: this.categoryCode === ECategoryCodes.private
      };
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 0:
          PromiseUtils.wrapNoAction(this.clearProgressRefresh(true), true);
          break;
        case 1:
          PromiseUtils.wrapNoAction(this.clearPlacesRefresh(true), true);
          break;
        case 2:
          this.reload = true;
          this.loadStoryWrapper(false).then(() => {
            PromiseUtils.wrapNoAction(this.loadExtData(), true);
          });
          break;
        case 3:
          this.onHelpClick();
          break;
        case 4:
          let params: IStoryRatingNavParams = RatingUtils.getStoryRatingParams(this.storyExt.story);
          console.log(params);
          this.uiext.showCustomModal(null, RatingFrameViewComponent, {
            view: {
              fullScreen: true,
              transparent: false,
              large: true,
              addToStack: true,
              frame: false,
              backdropDismiss: false
            },
            params: params
          }).then((rating: IRatingView) => {
            if (rating != null) {
              this.miscProvider.rateStory(this.storyId, null, rating.rating, rating.review, false).then((_response: IGenericResponse) => {
                this.uiext.showRewardPopupQueue(Messages.msg.thanksForRating.after.msg, Messages.msg.thanksForRating.after.sub, null, false, 2000).then(() => {
                  this.reload = true;
                  this.loadStoryWrapper(false);
                });
              }).catch((err: Error) => {
                this.analytics.dispatchError(err, "story-home");
                this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
              });
            }
          }).catch((err: Error) => {
            console.error(err);
          });
          break;
        case 5:
          let providerId: number = this.storyExt.story.providerId;
          // console.log(providerId);
          if (providerId == null) {
            // no provider available for the story
          } else {
            if (providerId === EStoryProvider.leplace) {
              // manage personalized stories from leplace
              this.uiext.showAlert(Messages.msg.removePrivateStory.before.msg, Messages.msg.removePrivateStory.before.sub, 2, null).then((res: number) => {
                if (res === EAlertButtonCodes.ok) {
                  this.businessDataProvider.removePrivateStory(this.storyExt.story.id).then(() => {
                    this.back(true);
                  }).catch((err: Error) => {
                    this.analytics.dispatchError(err, "story-home");
                    this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                  });
                }
              }).catch((err: Error) => {
                console.error(err);
              });
            } else {
              // unsubscribe from 3rd party story provider
              this.uiext.showAlert(Messages.msg.unsubscribeFromStoryProvider.before.msg, Messages.msg.unsubscribeFromStoryProvider.before.sub, 2, null).then((res: number) => {
                if (res === EAlertButtonCodes.ok) {
                  this.businessDataProvider.unsubscribeFromPrivateStoryProvider(this.storyExt.story.providerId).then(() => {
                    this.back(true);
                  }).catch((err: Error) => {
                    this.analytics.dispatchError(err, "story-home");
                    this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                  });
                }
              }).catch((err: Error) => {
                console.error(err);
              });
            }
          }
          break;
        case 11:
          this.storyDataProvider.removeUserStoryLink(this.storyId).then(() => {
            this.back(true);
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "story-home");
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          });
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }



  onHelpClick() {
    let videoString: string = null;
    if (!this.category) {
      return;
    }
    let videoCode: number = (this.category.video && this.category.videoCode != null) ? this.category.videoCode : null;
    let params: IDescriptionFrameParams = {
      title: "Story Tutorial",
      description: null,
      mode: EDescriptionViewStyle.plain,
      photoUrl: null,
      loaderCode: ETutorialEntries.storyTutorial
    };
    videoCode = null;
    params = this.tutorials.checkVideoCode(params, videoCode, videoString);
    this.tutorials.showTutorialNoAction(null, null, null, params, true);
  }

  reloadGroupSpec(groupId: number, reload: boolean): Promise<IGroup> {
    return new Promise((resolve, reject) => {
      this.mpDataService.viewGroup(groupId, reload).then((group: IGroup) => {
        this.connectedGroup = group;
        this.setGameModeContext(EGameplayMode.teams, true, true);
        this.selectedIndex = EGameplayMode.teams;
        this.syncGroupProgress();
        MPUtils.formatGroupMembers(group, GeneralCache.userId);
        this.mpManager.setGroupScope(this.connectedGroup);
        console.log("reload group spec: ", this.connectedGroup);
        this.updateLoggedInMessage();
        resolve(this.connectedGroup);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  updateLoggedInMessage() {
    this.loggedInMessage = "<p>You are logged in as: " + GeneralCache.resourceCache.user.general.content.name + " (@" + GeneralCache.resourceCache.user.general.content.alias + ")</p>";
    if (this.connectedGroup != null) {
      this.loggedInMessage += "<p>Team: " + this.connectedGroup.name + " (@" + this.connectedGroup.alias + ")</p>";
    }
  }

  /**
   * handle start button
   */
  async startButton() {
    try {
      if (this.isModal && !this.params.loadStory) {
        // this.returnToMap(true);
        this.dismissModal(false, false);
        return;
      }

      if (this.withTeams && (this.gameplayMode === EGameplayMode.teams)) {
        if (!this.connectedGroup) {
          let res: number = await this.uiext.showAlert(Messages.msg.groupNotSelectedStory.after.msg, Messages.msg.groupNotSelectedStory.after.sub, 2, null);
          if (res === EAlertButtonCodes.ok) {
            PromiseUtils.wrapNoAction(this.openLobby(), true);
            return;
          } else {
            return;
          }
        }
        let groupScope: IGroup = this.mpManager.getGroupScope();
        console.log("start group scope: ", groupScope);
        console.log("session started: ", this.mpManager.isSessionStarted());
        if (!(this.mpManager.isSessionStarted() && groupScope != null && this.mpManager.isGroupOnline())) {
          let res: number = await this.uiext.showAlert(Messages.msg.groupNotStartedStory.after.msg, Messages.msg.groupNotStartedStory.after.sub, 2, null);
          if (res === EAlertButtonCodes.ok) {
            PromiseUtils.wrapNoAction(this.openGroupLobby(), true);
            return;
          } else {
            return;
          }
        }
      }

      let diag: IDiagnosticStatus = this.appDiagnostic.getDiagnosticsCache();
      if (diag && diag.batteryLevel != null && diag.batteryLevel < 33) {
        let res: number = await this.uiext.showAlert(Messages.msg.lowBatteryWarning.after.msg, Messages.msg.lowBatteryWarning.after.sub, 2, null);
        if (res !== EAlertButtonCodes.ok) {
          return;
        }
      }

      switch (this.story.mode) {
        case EStoryMode.linear:
        case EStoryMode.preload:
        case null:
          if (!this.inProgress && !this.finished && !this.saved) {
            this.openMap();
            return;
          }

          if (!this.inProgress && !this.finished && this.saved) {
            this.openMapOptions();
            return;
          }

          if (this.finished) {
            this.restartAndOpenMap();
            return;
          }

          if (this.inProgress && !this.finished) {
            this.openMapOptions();
            return;
          }
          break;
        case EStoryMode.mp:
          // unlock story location treasures
          this.openMap();
          break;
      }
    } catch (err) {
      console.error(err);
      this.analytics.dispatchError(err, "story-home");
    }
  }

  showPopup() {
    let description: string = "";
    if (this.storyExt) {
      description = this.storyExt.story.description;
    }
    this.uiextStandard.showStandardModal(null, EModalTypes.description, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { description: description }
    }).then(() => {

    }).catch((err: Error) => {
      console.error(err);

    });
  }

  viewLeaderboard() {
    let params: ILeaderboardExtNavParams = {
      reload: false,
      storyId: this.storyId,
      xpScaleFactor: this.story != null ? this.story.xpScaleFactor : null
    };
    this.isLobbyOpen = true;
    this.uiext.showCustomModal(null, LeaderboardExtPage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    }).then(() => {
      this.isLobbyOpen = false;
    }).catch((err: Error) => {
      this.isLobbyOpen = false;
      console.error(err);
    });
  }

  openLobby() {
    return new Promise<IArenaReturnToMapData>((resolve, reject) => {

      let params: IArenaNavParams = {
        place: null,
        meetingPlace: null,
        story: DeepCopy.deepcopy(this.story),
        group: null,
        testing: false,
        groupRole: null,
        groupId: null,
        chatOnly: false,
        playerId: null,
        inRange: true,
        canExit: true,
        fromMapOpened: false,
        enableGroups: true,
        isStoryline: this.storyId != null,
        isLobbyContext: true,
        context: EGroupContext.story,
        contextId: this.storyId,
        synchronized: false
      };

      // params.fromMpHome = true; // disable chat lobby
      params.fromMpHome = false;

      let navParams: INavParams = {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: false,
          frame: true
        },
        params: params
      };

      this.isLobbyOpen = true;

      // MPGroupsListPage
      // MPHomePage
      this.uiext.showCustomModal(null, MPGroupsListPage, navParams).then((response: IArenaReturnToMapData) => {
        console.log("return to story home, data received: ", response);
        this.isLobbyOpen = false;
        if (response != null) {
          this.connectedGroup = response.group;
          if (this.connectedGroup != null) {
            console.log("group selected: ", this.connectedGroup);
            this.onGroupSelected();
          } else {
            console.log("no group selected");
            this.setGameModeContext(EGameplayMode.single, true, true);
          }
        }
        resolve(response);
      }).catch((err: Error) => {
        this.isLobbyOpen = false;
        console.error(err);
        reject(err);
      });
    });
  }

  async onGroupSelected() {
    await this.syncGroupProgress();
    this.watchGroupStatus();
    this.watchGroupMessage();
    this.scrollToStart();
    this.toggleAutoReload(true);
    this.updateLoggedInMessage();
    await SleepUtils.sleep(100);
  }

  updateQuickJoin() {
    setTimeout(() => {
      if ((this.quickJoinCode != null) && (this.quickJoinCode.length === this.unlockCodeShortLength)) {
        this.withQuickJoin = true;
      } else {
        this.withQuickJoin = false;
      }
    }, 1);
  }

  async quickConnect(group: IGroup) {
    await this.uiext.showLoadingV2Queue("Connecting..");
    let available: boolean = await this.popupFeatures.checkServiceAvailable(EServiceUrlCodes.mp);
    if (available) {
      this.mpManager.changeServerUrl(GeneralCache.resourceCache.general.appServices.content.object.mp.url);
    } else {
      await this.uiext.dismissLoadingV2();
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      return;
    }
    // if (group.role === EGroupRole.lobby) {
    //   group.role = EGroupRole.member;
    // }   
    this.mpGameInterface.setGameContainerUpdateGroupScope(group, null, false);
    this.mpGameInterface.setGameContainerGroupConnect(group, group.role);
    await this.connectGroupMode(group, group.role);
    await this.uiext.dismissLoadingV2();
  }

  async quickJoin() {
    try {
      let group: IGroup = await this.mpDataService.searchGroup(null, this.quickJoinCode);
      this.startLocked = true;
      if (group != null) {
        if (group.members.find(m => m.userId === GeneralCache.userId) == null) {
          // also join new group
          console.log("joining group required");
          await this.uiext.showLoadingV2Queue("Joining team..");
          await this.mpDataService.joinGroup(null, group.id);
          group = await this.reloadGroupSpec(group.id, true);
          await this.uiext.dismissLoadingV2();
        } else {
          // search mode (existing group) 
          group = await this.reloadGroupSpec(group.id, true);
        }
        await SleepUtils.sleep(500);
        await this.quickConnect(group);
        await this.onGroupSelected();
        this.startLocked = false;
        this.quickJoinCode = "";
      } else {
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Team not found");
        this.startLocked = false;
        this.quickJoinCode = "";
      }
    } catch (err) {
      this.analytics.dispatchError(err, "story-home");
      await this.uiext.dismissLoadingV2();
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      this.startLocked = false;
      this.quickJoinCode = "";
    }
  }

  async connectGroupMode(group: IGroup, role: number) {
    await this.mpManager.connectGroupModeWizard(group, role, false);
    await SleepUtils.sleep(1000);
    this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.tapReady, null);
    await SleepUtils.sleep(1000);
    this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.tapStart, null);
  }

  /**
   * set or toggle auto reload
   * @param enable 
   */
  toggleAutoReload(enable: boolean) {
    console.log("toggle auto reload: ", enable);
    this.resetLoadingTimeout = !this.resetLoadingTimeout;
    if (enable && !this.autoReload) {
      this.autoReload = true;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
      this.doAutoReload();
    }
    if (!enable && this.autoReload) {
      this.autoReload = false;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
    }
  }

  /**
   * auto reload every 20 sec
   */
  doAutoReload() {
    this.timeout.autoReload = setTimeout(() => {
      if (!this.connectedGroup) {
        return;
      }
      this.reloadGroupSpec(this.connectedGroup.id, true).then(() => {
        console.log("auto reload complete");
        this.resetLoadingTimeout = !this.resetLoadingTimeout;
        this.doAutoReload();
      }).catch((err: Error) => {
        console.error(err);
        this.doAutoReload();
      });
    }, this.loadingTimeout * 1000);
  }

  /**
  * subscribe to group status events
  * for display purpose
  * the group sync with dynamic data is done internally by the mp manager
  */
  watchGroupStatus() {
    if (!this.subscription.groupStatus) {
      let obs = this.mpManager.getGroupStatusObservable();
      if (!obs) {
        return;
      }
      this.subscription.groupStatus = obs.subscribe((data: IMPGenericGroupStat) => {
        if (data && data.group) {
          console.log("group status picked up: ", data.group);
          this.connectedGroup = data.group;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
 * subscribe to group message events
 * for clear progress events
 */
  watchGroupMessage() {
    if (!this.subscription.groupMessage) {
      this.subscription.groupMessage = this.mpManager.watchMessageRx().subscribe(async (message: IMPMessageDB) => {
        if (message != null) {
          switch (message.type) {
            case EMPMessageCodes.clearStoryProgress:
              this.syncGroupProgress();
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * open arena while the mp is running
   */
  async openGroupLobby() {
    return new Promise((resolve) => {
      let mp: IMPGameSession = this.mpGameInterface.getGameContainer();
      let currentGroup: IGroup = this.connectedGroup;
      let currentGroupRole: number = this.connectedGroup != null ? this.connectedGroup.role : null;

      console.log("open arena continue: ", mp);

      if (!mp) {
        resolve(false);
        return;
      }

      if (!mp.currentGroup) {
        console.warn("arena is not available");
      } else {
        currentGroup = mp.currentGroup
        currentGroupRole = mp.currentGroupRole;
      }

      if (mp.arenaIsOpen) {
        console.warn("arena is already open");
        resolve(false);
        return;
      }

      // let currentGroup = mp.currentGroup;
      // if (this.connectedGroup != null) {
      //   currentGroup = currentGroup;
      // }

      let params: IArenaNavParams = {
        place: null,
        meetingPlace: null,
        group: currentGroup,
        groupId: null,
        testing: false,
        chatOnly: false,
        groupRole: currentGroupRole,
        playerId: mp.playerId != null ? mp.playerId : GeneralCache.userId,
        inRange: true,
        canExit: true,
        fromMapOpened: false,
        enableGroups: true,
        isStoryline: true,
        isLobbyContext: false,
        context: EGroupContext.story,
        contextId: this.storyId,
        synchronized: false
      };

      let navParams: INavParams = {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: true,
          frame: true
        },
        params: params
      };

      this.toggleAutoReload(false);
      this.isLobbyOpen = true;
      this.uiext.showCustomModal(null, MPGroupsHomePage, navParams).then(async (response: IArenaReturnToMapData) => {
        console.log("return to story home, data received: ", response);
        this.isLobbyOpen = false;
        if (response) {
          switch (response.code) {
            case EArenaReturnCodes.resume:
              // this.syncGroupProgress();
              this.connectedGroup = response.group;
              this.toggleAutoReload(true);
              resolve(true);
              break;
            case EArenaReturnCodes.leave:
              this.subscription.groupStatus = ResourceManager.clearSub(this.subscription.groupStatus);
              this.subscription.groupMessage = ResourceManager.clearSub(this.subscription.groupMessage);
              this.quitMpSession();
              this.connectedGroup = null;
              // this.setGameModeContext(EGameplayMode.single, true);
              this.updateLoggedInMessage();
              // reload player progress
              this.reload = true;
              this.loadStoryWrapper(false);
              resolve(true);
              break;
            default:
              resolve(false);
              break;
          }
        } else {
          resolve(false);
        }
      }).catch((err: Error) => {
        this.isLobbyOpen = false;
        console.error(err);
        resolve(false);
      });
    });
  }

  async syncGroupProgress() {
    try {
      await this.uiext.showLoadingV2Queue("Loading team progress..");
      await this.prepareGroupStoryProgress();
      await this.uiext.dismissLoadingV2();
    } catch (err) {
      console.error(err);
      await this.uiext.dismissLoadingV2();
    }
  }

  quitMpSession() {
    let sessionStarted: boolean = this.mpManager.isSessionStarted();
    console.log("quit mp session, started: ", sessionStarted);
    if (sessionStarted) {
      this.mpManager.quitSession();
      this.mpGameInterface.disconnect();
      this.mpGameInterface.dispose();
    }
    this.connectedGroup = null;
  }

  goToGroup(group: IGroup) {
    console.log("go to group: ", group);
  }

  selectIndexFromNavItem(item: INavBarItem) {
    this.setGameModeContext(item.value, true, true);
  }

  scrollToStart() {
    console.log("scroll to start");
    ScrollUtils.scrollIntoView(null, this.startButtonBar.nativeElement, null, 30);
  }

  textAreaFocused(focused: boolean) {
    console.log("focus: ", focused);
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }
}
